import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Invoice from '../domain/invoice';
import { useTranslation } from 'react-i18next';

const InvoiceRow = (props) => {
    const { row, vatCodes, salesInvoiceIntegration } = props;
    const { t } = useTranslation();

    const getVatCodeId = (vatHandling) => {
        const vatCode = vatCodes.find((c) => c.vatCode === vatHandling);
        if (!vatCode) {
            throw new Error('invalid vatHandling in invoice row');
        }
        return vatCode.vatId;
    };

    if (row.rowType === 0) {
        return (
            <TableRow key={row.storageId}>
                <TableCell>{row.productNumber}</TableCell>
                <TableCell>
                    {row.productName}
                    <br />
                    <em style={{ fontSize: '0.8em' }}>{row.productInfo}</em>
                </TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>{row.pricePerUnit}</TableCell>
                <TableCell>
                    {row.vatRate}
                    <br />
                    {salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                        <em style={{ fontSize: '0.8em' }}>{row.salesAccountDescription()}</em>
                    )}
                    {salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor() && (
                        <em style={{ fontSize: '0.8em' }}>
                            {t('invoice.vatHandlingOpt.' + getVatCodeId(row.vatHandling))}
                        </em>
                    )}
                </TableCell>
                <TableCell>{row.priceVatExcluded}</TableCell>
            </TableRow>
        );
    } else if (row.rowType === 1) {
        return (
            <TableRow key={row.storageId}>
                <TableCell></TableCell>
                <TableCell colSpan={5}>
                    <em>{row.productInfo}</em>
                </TableCell>
            </TableRow>
        );
    }
    throw new Error('invalid row');
};

export default InvoiceRow;
