import FilterProduct from './filterproduct';
import Machine from './machine';
import { round } from 'mathjs';
import ObjectIdGenerator from '../modules/objectidgenerator';

export class FilterOrder {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.storageId = json.storageId;
            this.product = new FilterProduct(json.product);
            if (json.machine) this.machine = new Machine(json.machine);
            this.machineName = json.machineName;
            this.machineLocation = json.machineLocation;
            this.machineArea = json.machineArea;
            this.machineAdditionalInfo = json.machineAdditionalInfo;
            this.count = json.count;
            this.discount = json.discount;
            this.totalPriceWithoutVat = json.totalPriceWithoutVat;
            this.unitPriceWithoutVat = json.unitPriceWithoutVat;
            this.viewOrder = json.viewOrder;
            this.machineViewOrder = json.machineViewOrder;
            this.palletInformation = json.palletInformation;
            this.filterAdditionalInfo = json.filterAdditionalInfo;
        } else {
            this.id = 0;
            this.storageId = 0;
            this.product = null;
            this.machine = null;
            this.machineName = '';
            this.machineLocation = '';
            this.machineArea = '';
            this.machineAdditionalInfo = '';
            this.count = 0;
            this.discount = 0;
            this.totalPriceWithoutVat = 0;
            this.unitPriceWithoutVat = 0;
            this.viewOrder = 0;
            this.machineViewOrder = 0;
            this.palletInformation = null;
            this.filterAdditionalInfo = '';
        }
    }

    unitPriceRounded() {
        return this.unitPriceWithoutVat % 1 > 0 ? round(this.unitPriceWithoutVat, 2) : this.unitPriceWithoutVat;
    }

    static NewObject() {
        const promise = new Promise(function (resolve, reject) {
            ObjectIdGenerator.newId()
                .then(function (id) {
                    resolve(new FilterOrder({ id }));
                })
                .catch(function (err) {
                    console.log(err);
                    reject();
                });
        });
        return promise;
    }
}

export default FilterOrder;
