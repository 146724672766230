import React, { useEffect, useState } from 'react';
import imgLogo from '../img/viti-crm-w.png';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import VadoLogoLogin from '../main/vadologologin';
import backgroundImg from '../img/building.jpg';
import Alert from '@material-ui/lab/Alert';
import cred from '../devcred';
import packageInfo from '../../package.json';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SpinnerButton from '../common/spinnerbutton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import languages from '../i18n/languages';
import { Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: '95%',
    },
    button: {
        margin: theme.spacing(),
        width: '95%',
    },
    header: {
        backgroundImage: `url(${backgroundImg})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //filter: "blur(8px)",
        //webkitFilter: "blur(8px)",
    },
    containerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
}));

export default function Login(props) {
    const classes = useStyles();
    const isCustomerSite = process.env.REACT_APP_TARGET === 'customerui';
    const usernameInput = React.createRef();
    const passwordInput = React.createRef();
    const dev = process.env.NODE_ENV === 'development' ? true : false;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [inputWarning, setInputWarning] = useState('');
    const [changingLang, setChangingLang] = useState(false);
    const { t, i18n } = useTranslation();
    const selectedLang = i18n.language;

    useEffect(() => {
        usernameInput.current.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usernameInput.current]);

    const inputChange = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;

        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
        }
    };

    const onClick = () => {
        if (username.length < 3 || password.length < 8) {
            setInputWarning(t('login.invalidUsernameOrPassword'));
        } else {
            props.onSubmit(username, password);
            setUsername('');
            setPassword('');
            setInputWarning('');
        }
    };
    const demoLogin = () => {
        props.onSubmit(cred.username, cred.password);
    };
    const demoLoginUserOnly = () => {
        props.onSubmit('testimies@yksi.fi', 'Penaali1');
    };
    const demoLoginProd = () => {
        props.onSubmit(cred.usernameprod, cred.password);
    };
    const demoLoginInvoice = () => {
        props.onSubmit(cred.usernameinv, cred.password);
    };

    const getSelectedLang = () => {
        return languages.find((l) => l.lang === selectedLang);
    };

    const changeLanguage = (newLang) => {
        setChangingLang(false);
        document.cookie = `lang=${newLang};max-age=31536000;path=/`;
        i18n.changeLanguage(newLang);
    };

    if (props.isAuthenticated) {
        return null;
    }

    return (
        <div className={classes.header}>
            <div className={classes.containerStyle}>
                <Grid container style={{ marginTop: '50px' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        {isCustomerSite && (
                            <div style={{ paddingBottom: '10px' }}>
                                <VadoLogoLogin />
                            </div>
                        )}
                        {!isCustomerSite && <img src={imgLogo} alt="logo" style={{ paddingBottom: '10px' }} />}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', margin: '10px' }}>
                        <Paper
                            style={{
                                margin: 'auto',
                                textAlign: 'center',
                                backgroundColor: 'white',
                                maxWidth: '500px',
                                borderRadius: '10px',
                                padding: '1em',
                            }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <h2>{t('login.title')}</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="username"
                                        name="username"
                                        label={t('login.username')}
                                        value={username}
                                        className={classes.textField}
                                        margin="normal"
                                        onChange={inputChange}
                                        inputRef={usernameInput}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                passwordInput.current.focus();
                                            }
                                        }}
                                        disabled={props.loginInProgress}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        label={t('login.password')}
                                        value={password}
                                        className={classes.textField}
                                        type="password"
                                        autoComplete=""
                                        margin="normal"
                                        onChange={inputChange}
                                        inputRef={passwordInput}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                onClick();
                                            }
                                        }}
                                        disabled={props.loginInProgress}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    {props.loginFailed && <Alert severity="warning">{t('login.loginFailed')}</Alert>}
                                    {inputWarning.length > 0 && <Alert severity="warning">{inputWarning}</Alert>}
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                                    <SpinnerButton
                                        inProgress={props.loginInProgress}
                                        text={t('login.title')}
                                        fullWidth={true}
                                        onClick={onClick}
                                    />
                                </Grid>
                                {dev && (
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={demoLogin}
                                            className={classes.button}>
                                            demo login
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={demoLoginUserOnly}
                                            className={classes.button}>
                                            demo login User-only
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={demoLoginProd}
                                            className={classes.button}>
                                            demo-production
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={demoLoginInvoice}
                                            className={classes.button}>
                                            demo-invoicing
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                        <br />
                        <br />
                        {!changingLang && (
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setChangingLang(true);
                                    }}>
                                    {getSelectedLang().flagImg}
                                    <span style={{ color: 'white', marginLeft: '5px' }}>
                                        {t(getSelectedLang().name)}
                                    </span>
                                </Button>
                            </Grid>
                        )}
                        {changingLang && (
                            <Select
                                required
                                error={false}
                                id="language"
                                name="language"
                                value={selectedLang}
                                open={true}
                                onChange={(evt) => changeLanguage(evt.target.value)}>
                                {languages.map((lang) => (
                                    <MenuItem key={lang.lang} value={lang.lang}>
                                        {lang.flagImg}
                                        <span style={{ marginLeft: '5px' }}>{t(lang.name)}</span>
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        <br />
                        {isCustomerSite && (
                            <div style={{ color: 'white' }}>
                                VADO myyntitilaukset <br />
                                Powered by MyynninTuki&trade; &copy;&nbsp;2019-2022 Vitilumi Oy, v{packageInfo.version}
                            </div>
                        )}
                        {!isCustomerSite && (
                            <div style={{ color: 'white' }}>
                                MyynninTuki&trade; &copy;&nbsp;2019-2022 Vitilumi Oy, v{packageInfo.version}
                            </div>
                        )}
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

Login.propTypes = {
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loginFailed: PropTypes.bool,
    loginInProgress: PropTypes.bool,
};
