import moment from 'moment';
import ProductNote from './productnote';

class FilterProduct {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.storageId = json.storageId;
            this.productCode = json.productCode;
            this.productName = json.productName;
            this.type = json.type;
            this.fabricSurfaceArea = json.fabricSurfaceArea ? json.fabricSurfaceArea : 0;
            this.dimensionWidthMm = json.dimensionWidthMm;
            this.dimensionHeightMm = json.dimensionHeightMm;
            this.dimensionDepthMm = json.dimensionDepthMm;
            this.numberOfPockets = json.numberOfPockets;
            this.priceWithoutVat = json.priceWithoutVat ? json.priceWithoutVat : 0;
            this.toBeReplaced = json.toBeReplaced;
            this.productionPrice = json.productionPrice ? json.productionPrice : 0;
            this.expired = json.expired;
            this.status = json.status;
            this.added = moment(json.added);
            this.addedByUser = json.addedByUser;
            this.replacedByProductId = json.replacedByProductId;
            if (json.notes && json.notes.length > 0) {
                this.notes = json.notes.map((note) => new ProductNote(note));
            } else {
                this.notes = [];
            }
        } else {
            this.id = 0;
            this.storageId = 0;
            this.productCode = '';
            this.productName = '';
            this.type = '';
            this.fabricSurfaceArea = 0;
            this.dimensionWidthMm = 0;
            this.dimensionHeightMm = 0;
            this.dimensionDepthMm = 0;
            this.numberOfPockets = 0;
            this.priceWithoutVat = 0;
            this.toBeReplaced = false;
            this.productionPrice = 0;
            this.expired = false;
            this.status = 0;
            this.replacedByProductId = null;
            this.added = moment();
            this.addedByUser = undefined;
            this.notes = [];
        }
    }

    addNote(note) {
        this.notes.push(new ProductNote({ storageId: Math.random() * 10000, text: note, productId: this.id }));
    }

    setNoteText(text, storageId) {
        const noteToEditInd = this.notes.findIndex((n) => n.storageId === storageId);
        if (noteToEditInd !== -1) {
            if (text && text.length > 0) {
                this.notes[noteToEditInd].text = text;
            } else {
                this.notes.splice(noteToEditInd, 1);
            }
        }
    }

    static StatusInUse = () => 0;
    static StatusDraftRequest = () => 1;
    static StatusImported = () => 2;
}

export default FilterProduct;
