import i18next from 'i18next';

function translate(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

class InvoiceRow {
    constructor(json) {
        this.storageId = json.storageId;
        this.productNumber = json.productNumber;
        this.productInfo = json.productInfo;
        this.productName = json.productName;
        this.quantity = json.quantity;
        this.unit = json.unit;
        this.pricePerUnit = json.pricePerUnit;
        this.discountPercentage = json.discountPercentage;
        this.vatRate = json.vatRate;
        this.rowType = json.rowType; //// 0=tuote, 1=selite
        this.salesAccountNumber = json.salesAccountNumber;
        this.priceVatIncluded = json.priceVatIncluded;
        this.priceVatExcluded = json.priceVatExcluded;
        this.viewOrder = json.viewOrder;
        this.productStorageId = json.productStorageId;
        this.vatHandling = json.vatHandling;
    }

    salesAccountDescription() {
        const ret = this.salesAccountOptions().find((n) => n.value === this.salesAccountNumber).description;
        return ret;
    }

    salesAccountOptions() {
        return [
            { value: 3000, description: translate('invoice.salesAccounts.salesVat24') },
            { value: 3003, description: translate('invoice.salesAccounts.salesVat0') },
            { value: 3343, description: translate('invoice.salesAccounts.communityServiceSalesVat0') },
            { value: 3355, description: translate('invoice.salesAccounts.communitySalesVat0') },
            { value: 3383, description: translate('invoice.salesAccounts.salesOutsideOfCommunity') },
        ];
    }
}

export default InvoiceRow;
