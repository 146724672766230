import React from 'react';
import TreeItem from './treeitem';
import TreeDescription from '../domain/treedescription';
import * as locationActions from '../modules/locationdata';
import Location from '../domain/location';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import store from '../store';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import LocationNameInputDialog from './locationNameInputDialog';
import { Alert } from '@material-ui/lab';
import i18next from 'i18next';
const _ = require('lodash');

function t(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
});

class Tree extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nodes: null,
            newFolderName: '',
            targetFolderId: null,
            folderNameModalOpen: false,
            folderNameErrorMsg: '',
            locationNameModalOpen: false,
            createNewLocationInProgress: false,
            folderNameEditMode: false,
            folderItemOnEdit: null,
        };
    }

    createId = () => {
        let rnd = Math.random() * 999999;
        return Math.floor(rnd);
    };

    findItemNode = (node, locId) => {
        let locNode = null;
        if (node.locations.length > 0) {
            locNode = node.locations.find((loc) => loc.id === locId);
        }
        if (locNode == null && node.childFolders.length > 0) {
            for (let i = 0; i < node.childFolders.length; i++) {
                let retLoc = this.findItemNode(node.childFolders[i], locId);
                if (retLoc != null) {
                    locNode = retLoc;
                    break;
                }
            }
        }
        return locNode;
    };

    findFolder = (node, folId) => {
        let targetFol = null;
        if (node.childFolders.length > 0) {
            targetFol = node.childFolders.find((fol) => fol.id === folId);
            if (targetFol == null && node.childFolders.length > 0) {
                for (let i = 0; i < node.childFolders.length; i++) {
                    let retFolder = this.findFolder(node.childFolders[i], folId);
                    if (retFolder != null) {
                        return retFolder;
                    }
                }
            }
        }
        return targetFol;
    };

    updateFolderName = (node, folId, newName) => {
        if (node.id === folId) {
            node.name = newName;
        } else if (node.childFolders.length > 0) {
            for (let i = 0; i < node.childFolders.length; i++) {
                this.updateFolderName(node.childFolders[i], folId, newName);
            }
        }
        return node;
    };

    removeLoc = (node, locId) => {
        let locNode = null;
        if (node.locations.length > 0) {
            locNode = node.locations.find((loc) => loc.id === locId);
            if (locNode) {
                node.locations = node.locations.filter((loc) => loc.id !== locId);
            }
        }
        if (locNode == null && node.childFolders.length > 0) {
            locNode = node.childFolders.map((node) => this.removeLoc(node, locId));
        }
        return node;
    };

    removeFolderFromTree = (node, folId) => {
        let targetFol = null;
        if (node.childFolders.length > 0) {
            targetFol = node.childFolders.find((fol) => fol.id === folId);
            if (targetFol) {
                node.childFolders = node.childFolders.filter((fol) => fol.id !== folId);
            }
        }
        if (targetFol == null && node.childFolders.length > 0) {
            targetFol = node.childFolders.map((node) => this.removeFolderFromTree(node, folId));
        }
        return node;
    };

    findNodeAddLoc = (node, destinationFolderId, loc) => {
        if (node.id === destinationFolderId) {
            node.locations.push(loc);
            return node;
        } else if (node.childFolders.length > 0) {
            node.childFolders.map((node) => this.findNodeAddLoc(node, destinationFolderId, loc));
        }
        return node;
    };

    findNodeAddFolder = (node, destinationFolderId, newFolder) => {
        if (node.id === destinationFolderId) {
            node.childFolders.push(newFolder);
            return node;
        } else if (node.childFolders.length > 0) {
            node.childFolders.map((node) => this.findNodeAddFolder(node, destinationFolderId, newFolder));
        }
        return node;
    };

    moveItem = async (fromId, toId) => {
        if (fromId === toId) return;
        let treenodes = _.cloneDeep(await this.getCustomerTreeData());
        let toBeMoved = this.findItemNode(treenodes, fromId);
        treenodes = this.removeLoc(treenodes, fromId);
        let newnodes = this.findNodeAddLoc(treenodes, toId, toBeMoved);
        this.props.updateCustomerTreedata(this.props.customer.id, newnodes);
    };

    removeFolder = async (folderId) => {
        let treenodes = _.cloneDeep(await this.getCustomerTreeData());
        let newnodes = this.removeFolderFromTree(treenodes, folderId);
        this.props.updateCustomerTreedata(this.props.customer.id, newnodes);
    };

    addNewFolder = (targetFolderId) => {
        this.setState({
            ...this.state,
            folderNameModalOpen: true,
            targetFolderId: targetFolderId,
        });
    };

    renameFolder = async (folderId) => {
        //acquire target folder object
        let targetFolder = this.findFolder(await this.getCustomerTreeData(), folderId);
        //update state to open folder name modal and set state to match folder edit
        this.setState({
            ...this.state,
            folderNameModalOpen: true,
            folderNameEditMode: true,
            folderItemOnEdit: targetFolder,
            newFolderName: targetFolder.name,
        });
    };

    addNewLocation = (targetFolderId) => {
        this.getCustomerFull(this.props.customer.id);
        this.setState({ ...this.state, locationNameModalOpen: true, targetFolderId: targetFolderId });
    };

    saveNewFolder = async () => {
        let newId = this.createId();
        let folderName = this.state.newFolderName;
        let folderId = this.state.targetFolderId;
        let newFolder = new TreeDescription({
            id: newId,
            customerId: this.props.customer.id,
            isRoot: false,
            name: folderName,
        });
        let treenodes = _.cloneDeep(await this.getCustomerTreeData());
        let newnodes = this.findNodeAddFolder(treenodes, folderId, newFolder);

        this.props.updateCustomerTreedata(this.props.customer.id, newnodes);

        this.setState({
            ...this.state,
            folderNameModalOpen: false,
            targetFolderId: null,
            newFolderName: '',
        });
    };

    saveNewFolderName = async () => {
        let treenodes = _.cloneDeep(await this.getCustomerTreeData());
        let newnodes = this.updateFolderName(treenodes, this.state.folderItemOnEdit.id, this.state.newFolderName);
        this.props.updateCustomerTreedata(this.props.customer.id, newnodes);

        this.setState({
            ...this.state,
            folderNameModalOpen: false,
            folderNameEditMode: false,
            folderItemOnEdit: null,
            newFolderName: '',
        });
    };

    confirmFoldername = () => {
        console.log('confirm');
        if (this.state.newFolderName !== null && this.state.newFolderName.length > 0) {
            if (this.state.folderNameEditMode) {
                this.saveNewFolderName();
            } else {
                this.saveNewFolder();
            }
        } else {
            this.setState({
                ...this.state,
                folderNameErrorMsg: t('general.giveNameBeforeSaving'),
            });
        }
    };

    cancelFolderName = () => {
        this.setState({
            ...this.state,
            folderNameModalOpen: false,
            folderNameErrorMsg: '',
            folderNameEditMode: false,
            folderItemOnEdit: null,
        });
    };

    cancelLocationName = () => {
        this.setState({ ...this.state, locationNameModalOpen: false });
    };

    confirmLocationName = async (name) => {
        this.setState({ ...this.state, createNewLocationInProgress: true });
        Location.NewObject().then((loc) => {
            loc.name = name;
            loc.customer = this.props.customersById[this.props.customer.id];
            loc.useCustomerEInvoiceInfo =
                this.props.customersById[this.props.customer.id].electronicInvoiceAddress != null;
            loc.useCustomerBillingAddress = this.props.customersById[this.props.customer.id].billingAddress != null;
            // save location
            store.dispatch(locationActions.saveLocation(loc, false, false)).then(async (savedLoc) => {
                let treenodes = _.cloneDeep(await this.getCustomerTreeData());
                let newnodes = this.findNodeAddLoc(treenodes, this.state.targetFolderId, savedLoc);
                this.props.updateCustomerTreedata(this.props.customer.id, newnodes);

                this.setState({
                    ...this.state,
                    locationNameModalOpen: false,
                    createNewLocationInProgress: false,
                    targetFolderId: null,
                });
            });
        });
    };

    handleChange = (event) => {
        var newState = { ...this.state };
        const target = event.target;
        const value = target.value;
        const name = target.name;

        switch (name) {
            case 'kansio':
                newState.newFolderName = value;
                newState.folderNameErrorMsg = '';
                break;
            default:
                break;
        }
        this.setState(newState);
    };

    addToCart = (ids) => {
        this.props.newOrderFromLocations(ids);
    };

    folderNameInputDialog = () => {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        return (
            <Dialog
                onClose={this.cancelFolderName}
                aria-labelledby="simple-dialog-title"
                open={this.state.folderNameModalOpen}
                fullWidth={true}
                maxWidth={'md'}
                fullScreen={fullScreen}>
                <DialogContent dividers>
                    <TextField
                        error={this.state.folderNameErrorMsg.length > 0}
                        id="standard-multiline-flexible"
                        label={t('general.folderName')}
                        className={this.props.classes.root}
                        onChange={this.handleChange}
                        margin="normal"
                        name={'kansio'}
                        value={this.state.newFolderName}
                    />
                    {this.state.folderNameErrorMsg.length > 0 && (
                        <Alert severity="warning">{this.state.folderNameErrorMsg}</Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        className={this.props.classes.button}
                        name="btn-confirm-folder-name"
                        onClick={this.confirmFoldername}>
                        {t('buttons.save')}
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        className={this.props.classes.button}
                        name="btn-cancel-folder-name"
                        onClick={this.cancelFolderName}>
                        {t('buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    getCustomerTreeData = async () => {
        if (!this.props.customersById[this.props.customer.id]) await this.getCustomerFull(this.props.customer.id);

        return this.props.customersById[this.props.customer.id].treeData;
    };

    getCustomerFull = async (customerId) => {
        if (!this.props.customersById[customerId]) await this.props.getCustomerFull(customerId);
    };

    render() {
        if (!this.props.customer) return null;

        const customer = !!this.props.customersById[this.props.customer.id]
            ? this.props.customersById[this.props.customer.id]
            : this.props.customer;

        const treenodes = customer.treeData;

        return (
            <div>
                <TreeItem
                    id={treenodes ? treenodes.id : customer.id}
                    treedata={treenodes}
                    customerId={customer.id}
                    name={customer.name}
                    onNewFolder={this.addNewFolder}
                    onRenameFolder={this.renameFolder}
                    onRemoveFolder={this.removeFolder}
                    onNewLocation={this.addNewLocation}
                    type={'root'}
                    onMoveItem={this.moveItem}
                    editMode={this.props.edit}
                    history={this.props.history}
                    addToCart={this.addToCart}
                    getCustomerFull={this.getCustomerFull}
                    store={store}
                    userPortfolios={this.props.userPortfolios}
                />
                <this.folderNameInputDialog />
                <LocationNameInputDialog
                    dialogOpen={this.state.locationNameModalOpen}
                    cancel={this.cancelLocationName}
                    confirm={this.confirmLocationName}
                />
            </div>
        );
    }
}

export default withStyles(styles)(Tree);
