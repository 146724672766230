import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

export default function ProductSelectorAutocomplete(props) {
    const products = useSelector((state) => state.productdata.filterProducts);
    const allowNewProd = props.allowNewProd;
    const { t } = useTranslation();
    const defaultProps = {
        options: products,
        getOptionLabel: (option) =>
            option.notes && option.notes.length > 0
                ? `${option.productName} (${option.notes.map((n) => n.text).join(',')})`
                : `${option.productName}`,
    };

    const onSelect = (event, value, reason) => {
        if (reason === 'select-option') {
            props.selected(value.id);
        } else if (reason === 'clear') {
            props.dismiss();
        }
    };

    const noOptions = () => {
        if (!!allowNewProd) {
            return (
                <Button
                    name="btn-new-product"
                    variant="contained"
                    color="primary"
                    onMouseDown={props.newProduct}
                    onClick={props.newProduct}
                    startIcon={<AddIcon />}>
                    {t('general.product')}
                </Button>
            );
        }
        return '-';
    };

    useEffect(() => {
        if (products) {
            const elem = document.getElementById('productautocomplete');
            if (elem) {
                elem.focus();
            }
        }
    }, [products]);

    return (
        <Autocomplete
            {...defaultProps}
            id="productautocomplete"
            name="productautocomplete"
            loading={!products}
            loadingText={t('general.loading')}
            noOptionsText={noOptions()}
            onChange={onSelect}
            renderInput={(params) => <TextField {...params} label={t('general.product')} margin="normal" />}
        />
    );
}
