import axios from 'axios';
import CONFIG, { isCustomerUi } from '../config';
import store from '../store';
import { AuthenticationFailed } from './authentication';
import { toast } from 'react-toastify';
import ResolveErrorMessage from '../common/errormessageresolver';

axios.interceptors.response.use(null, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            store.dispatch(AuthenticationFailed());
        } else if (!isCustomerUi && error.response.data && error.response.data.message) {
            toast.error(ResolveErrorMessage(error.response.data.message), { autoClose: 5000, closeOnClick: false });
        }
    } else if (error.request) {
    } else {
    }
    return Promise.reject(error.response);
});

function getAuthStr() {
    return 'Bearer ' + sessionStorage.getItem('accessToken');
}

function getConnectionIdHeader() {
    return { 'x-ws-connection-id': sessionStorage.getItem('wsConnectionId') };
}

const Ajax = {
    get(url) {
        const promise = new Promise(function (resolve, reject) {
            axios
                .get(CONFIG.backendUrl + url, {
                    withCredentials: CONFIG.axiosWithCred,
                    headers: { Authorization: getAuthStr(), ...getConnectionIdHeader() },
                })
                .then(function (result) {
                    resolve(result);
                })
                .catch(function (err) {
                    reject(err);
                });
        });
        return promise;
    },
    post(url, data, options) {
        const promise = new Promise(function (resolve, reject) {
            axios({
                method: 'post',
                withCredentials: CONFIG.axiosWithCred,
                url: CONFIG.backendUrl + url,
                data: JSON.stringify(data),
                contentType: 'application/json',
                responseType: options && options.responseType ? options.responseType : '',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Authorization: getAuthStr(),
                    ...getConnectionIdHeader(),
                    Accept:
                        options && options.acceptHeader ? options.acceptHeader : 'application/json, text/plain, */*',
                },
            })
                .then(function (result) {
                    resolve(result);
                })
                .catch(function (err) {
                    reject(err);
                });
        });
        return promise;
    },
    put(url, jsondata) {
        const promise = new Promise(function (resolve, reject) {
            axios({
                method: 'put',
                withCredentials: CONFIG.axiosWithCred,
                url: CONFIG.backendUrl + url,
                data: JSON.stringify(jsondata),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Authorization: getAuthStr(),
                    ...getConnectionIdHeader(),
                },
            })
                .then(function (result) {
                    resolve(result);
                })
                .catch(function (err) {
                    reject(err);
                });
        });
        return promise;
    },
    delete(url, json) {
        const promise = new Promise(function (resolve, reject) {
            axios({
                method: 'delete',
                withCredentials: CONFIG.axiosWithCred,
                url: CONFIG.backendUrl + url,
                data: json ? JSON.stringify(json) : null,
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    Authorization: getAuthStr(),
                    ...getConnectionIdHeader(),
                },
            })
                .then(function (result) {
                    resolve(result);
                })
                .catch(function (err) {
                    reject(err);
                });
        });
        return promise;
    },
    postBase64ReturnBlob(url, data, acceptHeader) {
        const promise = new Promise(function (resolve, reject) {
            axios({
                method: 'post',
                withCredentials: CONFIG.axiosWithCred,
                data: data,
                url: CONFIG.backendUrl + url,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: getAuthStr(),
                    ...getConnectionIdHeader(),
                    Accept: acceptHeader ? acceptHeader : 'application/json, text/plain, */*',
                },
            })
                .then(function (result) {
                    resolve(result);
                })
                .catch(function (err) {
                    reject(err);
                });
        });
        return promise;
    },
    postBase64ReturnJson(url, data) {
        const promise = new Promise(function (resolve, reject) {
            axios({
                method: 'post',
                withCredentials: CONFIG.axiosWithCred,
                data: data,
                url: CONFIG.backendUrl + url,
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: getAuthStr(),
                    ...getConnectionIdHeader(),
                },
            })
                .then(function (result) {
                    resolve(result);
                })
                .catch(function (err) {
                    reject(err);
                });
        });
        return promise;
    },
};

export default Ajax;
