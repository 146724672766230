import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NumericInputElement, { TypeOnBlur } from '../common/numericinput';
import { formatCurrency } from '../common/common';
import { Checkbox, Chip, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    filterCountEdit: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'table-row',
        },
    },
    sectionMobile: {
        display: 'table-row',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    mobileText: {
        fontSize: '0.8em',
    },
    fadedRow: {
        opacity: 0.3,
    },
    checkbox: {
        padding: '0px 0px 0px 4px',
    },
    evenRow: {
        backgroundColor: '#f0f0f0',
    },
    highlightProdName: {
        fontWeight: 'bold',
    },
    checkboxAlignTopTd: {
        verticalAlign: 'top',
    },
}));

export function FilterOrderRowViewInternal(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const saving = props.saving;

    const onClickRow = () => {
        props.startEditRow();
    };
    const onClickToSelect = (e) => {
        e.stopPropagation();
        props.selectRow(props.filterOrder.id);
    };
    const getRowClass = () => {
        let className = '';
        if (props.selected) className = 'highlight';
        if (props.fadeRow) className += ' ' + classes.fadedRow;
        if (props.index === 0 || props.index % 2 === 0) className += ' ' + classes.evenRow;
        return className;
    };
    const filterCountChanged = async (id, value) => {
        await props.filterCountChanged(props.filterOrder.id, value);
    };
    const editPalletInfo = (e) => {
        e.stopPropagation();
        props.editFoPalletInfo([props.filterOrder.id]);
    };

    return (
        <Fragment>
            <tr className={getRowClass() + ' ' + classes.sectionDesktop}>
                <td
                    onClick={onClickToSelect}
                    name={`filter-order-row-select-${props.filterOrder.id}`}
                    className={classes.checkboxAlignTopTd}>
                    <Checkbox checked={!!props.selected} readOnly />
                </td>
                <td onClick={onClickRow} name={'filter-order-row'}>
                    {!props.priceOnly && (
                        <div>
                            {props.filterOrder.machineName}

                            {props.filterOrder.machineLocation && props.filterOrder.machineLocation.length > 0 && (
                                <span>
                                    <br />
                                    {t('location.position')}: <strong>{props.filterOrder.machineLocation}</strong>
                                </span>
                            )}
                            {props.filterOrder.machineArea && props.filterOrder.machineArea.length > 0 && (
                                <span>
                                    <br />
                                    {t('location.serviceArea')}: <strong>{props.filterOrder.machineArea}</strong>
                                </span>
                            )}
                            {props.filterOrder.palletInformation && props.filterOrder.palletInformation.length > 0 && (
                                <React.Fragment>
                                    {!props.editingOrder ? (
                                        <React.Fragment>
                                            <br />
                                            <Chip
                                                name={'edit-pallet-info-' + props.filterOrder.id}
                                                label={
                                                    t('order.palletGroup') + ': ' + props.filterOrder.palletInformation
                                                }
                                                variant="outlined"
                                                onClick={editPalletInfo}
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <br />
                                            {`${t('order.palletGroup')}: ${props.filterOrder.palletInformation} `}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </td>

                <td onClick={onClickRow}>
                    {props.filterOrder.product.productName}
                    {props.filterOrder.filterAdditionalInfo && (
                        <span>
                            <br />
                            <em>{props.filterOrder.filterAdditionalInfo}</em>
                        </span>
                    )}
                </td>
                <td className={'filter-count-' + props.filterOrder.id}>
                    {!props.priceOnly && (
                        <NumericInputElement
                            id={null}
                            value={props.filterCount}
                            onChange={filterCountChanged}
                            type={TypeOnBlur}
                            min={1}
                            saving={saving}
                        />
                    )}
                </td>
                <td>{formatCurrency(props.filterOrder.unitPriceWithoutVat)}</td>
                {props.allowPricing && <td>{formatCurrency(props.filterOrder.totalPriceWithoutVat)}</td>}
            </tr>

            <tr className={getRowClass() + ' ' + classes.sectionMobile}>
                <td
                    onClick={onClickToSelect}
                    name={`filter-order-row-select-${props.filterOrder.id}`}
                    className={classes.checkboxAlignTopTd}>
                    <Checkbox className={classes.checkbox} checked={!!props.selected} onChange={onClickToSelect} />
                </td>
                <td onClick={onClickRow} name={'filter-order-row'}>
                    <span className={classes.highlightProdName}>{props.filterOrder.product.productName}</span>
                    <br />
                    {!props.priceOnly && (
                        <span className={classes.mobileText}>
                            <strong>{props.filterOrder.machineName}</strong>
                            {props.filterOrder.machineLocation && props.filterOrder.machineLocation.length > 0 && (
                                <span>, {props.filterOrder.machineLocation}</span>
                            )}
                            {props.filterOrder.machineArea && props.filterOrder.machineArea.length > 0 && (
                                <span>, {props.filterOrder.machineArea}</span>
                            )}
                        </span>
                    )}
                    {!props.priceOnly && props.selected && !props.editingOrder && (
                        <NumericInputElement
                            id={null}
                            value={props.filterCount}
                            onChange={filterCountChanged}
                            type={TypeOnBlur}
                            min={1}
                            saving={saving}
                        />
                    )}
                    {props.allowPricing && (
                        <div>
                            {props.filterCount} x {formatCurrency(props.filterOrder.unitPriceWithoutVat)}
                            &nbsp;=&nbsp;{formatCurrency(props.filterOrder.totalPriceWithoutVat)}
                        </div>
                    )}
                    {!props.priceOnly &&
                        props.filterOrder.palletInformation &&
                        props.filterOrder.palletInformation.length > 0 && (
                            <React.Fragment>
                                {!props.editingOrder ? (
                                    <React.Fragment>
                                        <Link style={{ color: 'black' }} underline="always" onClick={editPalletInfo}>
                                            {t('order.palletGroup')}:&nbsp;{props.filterOrder.palletInformation}
                                        </Link>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {`${t('order.palletGroup')}: ${props.filterOrder.palletInformation} `}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    }
                </td>
            </tr>
        </Fragment>
    );
}

const shouldSkipRender = (prevProps, nextProps) => {
    if (prevProps.fadeRow !== nextProps.fadeRow) return false;
    if (prevProps.selected !== nextProps.selected) return false;
    if (prevProps.filterCount !== nextProps.filterCount) return false;
    if (prevProps.filterOrder.totalPriceWithoutVat !== nextProps.filterOrder.totalPriceWithoutVat) return false;
    if (prevProps.filterOrder.unitPriceWithoutVat !== nextProps.filterOrder.unitPriceWithoutVat) return false;
    if (prevProps.filterOrder.palletInformation !== nextProps.filterOrder.palletInformation) return false;
    if (prevProps.filterOrder.machineName !== nextProps.filterOrder.machineName) return false;
    if (prevProps.filterOrder.machineLocation !== nextProps.filterOrder.machineLocation) return false;
    if (prevProps.filterOrder.machineArea !== nextProps.filterOrder.machineArea) return false;
    if (prevProps.saving !== nextProps.saving) return false;

    return true;
};

const FilterOrderRowView = React.memo(FilterOrderRowViewInternal, shouldSkipRender);

export default FilterOrderRowView;
