import FilterProduct from '../domain/filterproduct';
import ContractPrice from '../domain/contractprice';
import { ProductBundle } from '../domain/productbundle';
import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import { filter, cloneDeep, groupBy, uniq, orderBy } from 'lodash';
import { SAVE_LOCATION_SUCCESS } from './locationdata';
import { IsProductStandard } from '../common/productdataparse';

export const FETCH_FILTER_DATA_REQUESTED = 'filterdata/FETCHDATA';
export const FETCHED_FILTER_DATA = 'filterdata/FETCHEDDATA';
export const FETCH_FILTER_DATA_FAILED = 'filterdata/FETCHCLIENTDATAFAILED';
export const ADDING_NEW_LEGACY_PRODUCT = 'filterdata/ADDING_NEW_LEGACY_PRODUCT';
export const ADDED_NEW_LEGACY_PRODUCT = 'filterdata/ADDED_NEW_LEGACY_PRODUCT';
export const SAVE_NEW_PRODUCT_IN_PROGRESS = 'filterdata/SAVE_NEW_PRODUCT_IN_PROGRESS';
export const SAVE_NEW_PRODUCT_DONE = 'filterdata/SAVE_NEW_PRODUCT_DONE';
export const UPDATE_PRODUCT_IN_PROGRESS = 'filterdata/UPDATE_PRODUCT_IN_PROGRESS';
export const UPDATE_PRODUCT_DONE = 'filterdata/UPDATE_PRODUCT_DONE';
export const GET_CONTRACT_PRICES_DONE = 'filterdata/GET_CONTRACT_PRICES_DONE';
export const SAVE_CONTRACT_PRICE_DONE = 'filterdata/SAVE_CONTRACT_PRICE_DONE';
export const DELETE_PRODUCT_DONE = 'filterdata/DELETE_PRODUCT_DONE';
export const DELETE_CONTRACTPRICE_DONE = 'filterdata/DELETE_CONTRACTPRICE_DONE';
export const PRODUCTS_UPDATED = 'filterdata/PRODUCTS_UPDATED';
export const SAVING_CONTRACT_PRICES_BULK = 'filterdata/SAVING_CONTRACT_PRICES_BULK';
export const SAVE_CONTRACT_PRICES_BULK_DONE = 'filterdata/SAVE_CONTRACT_PRICES_BULK_DONE';
export const SAVING_CONTRACT_PRICES_BULK_FAILED = 'filterdata/SAVING_CONTRACT_PRICES_BULK_FAILED';
export const FETCH_PRODUCT_BUNDLE_DATA_IN_PROGRESS = 'filterdata/FETCH_PRODUCT_BUNDLE_DATA_IN_PROGRESS';
export const FETCH_PRODUCT_BUNDLE_DATA_DONE = 'filterdata/FETCH_PRODUCT_BUNDLE_DATA_DONE';
export const SAVE_PRODUCT_BUNDLE_DONE = 'filterdata/SAVE_PRODUCT_BUNDLE_DONE';
export const UPDATE_PRODUCT_BUNDLE_DONE = 'filterdata/UPDATE_PRODUCT_BUNDLE_DONE';
export const REMOVE_PRODUCT_BUNDLE_DONE = 'filterdata/REMOVE_PRODUCT_BUNDLE_DONE';

const initialState = {
    filterProducts: null,
    filterProductsVersion: null,
    fetchingFilterProducts: false,
    fetchFilterDataFailed: false,
    contractPricesByContractId: {},
    contractPricesBulkSaveOngoing: false,
    allProductNotes: [],
    duplicateProducts: [],
    nonStandardProducts: [],
    productBundles: null,
    fetchingProductBundles: false,
};

const filterDuplicateProducts = (productList) => {
    let duplicates = [];
    if (productList.length > 0) {
        const grouped = groupBy(productList, 'productName');
        duplicates = filter(grouped, (item, toinen) => {
            return item.length > 1;
        });
    }
    return duplicates;
};

const filterNonStandardProducts = (productList) => {
    const nsp = filter(productList, (item) => {
        return !IsProductStandard(item.productName);
    });
    return nsp.length > 0 ? orderBy(nsp, ['productName']) : [];
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILTER_DATA_REQUESTED:
            return {
                ...state,
                fetchingFilterProducts: true,
                fetchFilterDataFailed: false,
                filterProductsVersion: null,
            };
        case PRODUCTS_UPDATED:
        case FETCHED_FILTER_DATA:
            const newProds = filter(action.products, (p) => p.status !== FilterProduct.StatusImported()).map(
                (p) => new FilterProduct(p)
            );
            return {
                ...state,
                filterProducts: newProds,
                filterProductsVersion: action.version,
                fetchingFilterProducts: false,
                fetchFilterDataFailed: false,
                contractPricesByContractId: {},
                allProductNotes: gatherProductNotes(newProds),
                duplicateProducts: filterDuplicateProducts(newProds),
                nonStandardProducts: filterNonStandardProducts(newProds),
            };

        case FETCH_FILTER_DATA_FAILED:
            return {
                ...state,
                filterProducts: null,
                filterProductsVersion: null,
                fetchingFilterProducts: false,
                fetchFilterDataFailed: true,
                contractPricesByContractId: {},
                duplicateProducts: [],
                nonStandardProducts: [],
            };
        case SAVE_NEW_PRODUCT_DONE:
        case UPDATE_PRODUCT_DONE:
        case ADDED_NEW_LEGACY_PRODUCT:
            let newProducts = filter(state.filterProducts, (p) => p.id !== action.product.id);
            newProducts.push(new FilterProduct(action.product));
            return {
                ...state,
                filterProducts: newProducts,
                filterProductsVersion: action.version,
                contractPricesByContractId: {},
                allProductNotes: gatherProductNotes(newProducts),
                duplicateProducts: filterDuplicateProducts(newProducts),
                nonStandardProducts: filterNonStandardProducts(newProducts),
                productBundles: null,
            };
        case ADDING_NEW_LEGACY_PRODUCT:
        case UPDATE_PRODUCT_IN_PROGRESS:
        case SAVE_NEW_PRODUCT_IN_PROGRESS:
            return state;
        case GET_CONTRACT_PRICES_DONE: {
            const newContractData = cloneDeep(state.contractPricesByContractId);
            newContractData[action.contractId] = action.contractPrices.map((p) => {
                return new ContractPrice(p);
            });
            return {
                ...state,
                contractPricesByContractId: newContractData,
            };
        }
        case SAVE_CONTRACT_PRICE_DONE: {
            // contractPrice
            const newContractData = cloneDeep(state.contractPricesByContractId);
            const oldInd = newContractData[action.contractPrice.contractId].findIndex(
                (p) => p.product.id === action.contractPrice.product.id
            );
            if (oldInd !== -1) {
                newContractData[action.contractPrice.contractId][oldInd] = new ContractPrice(action.contractPrice);
            } else {
                newContractData[action.contractPrice.contractId].push(new ContractPrice(action.contractPrice));
            }

            return {
                ...state,
                contractPricesByContractId: newContractData,
            };
        }
        case SAVE_CONTRACT_PRICES_BULK_DONE: {
            const newContractData = cloneDeep(state.contractPricesByContractId);

            for (const cp of action.contractPrices) {
                const oldInd = newContractData[action.contractId].findIndex((p) => p.product.id === cp.product.id);
                if (oldInd !== -1) {
                    newContractData[action.contractId][oldInd] = new ContractPrice(cp);
                } else {
                    newContractData[action.contractId].push(new ContractPrice(cp));
                }
            }

            return {
                ...state,
                contractPricesByContractId: newContractData,
                contractPricesBulkSaveOngoing: false,
            };
        }
        case DELETE_CONTRACTPRICE_DONE: {
            const newContractData = cloneDeep(state.contractPricesByContractId);
            const contractPrices = newContractData[action.contractId].filter(
                (p) => p.product.storageId !== action.productCid
            );
            newContractData[action.contractId] = contractPrices;

            return {
                ...state,
                contractPricesByContractId: newContractData,
            };
        }
        case DELETE_PRODUCT_DONE: {
            const newProds = filter(state.filterProducts, (p) => p.storageId !== action.storageId);
            return {
                ...state,
                filterProducts: newProds,
                filterProductsVersion: action.version,
                allProductNotes: gatherProductNotes(newProds),
                duplicateProducts: filterDuplicateProducts(newProds),
                nonStandardProducts: filterNonStandardProducts(newProds),
            };
        }
        case SAVE_LOCATION_SUCCESS:
            return {
                ...state,
                contractPricesByContractId: {},
            };
        case SAVING_CONTRACT_PRICES_BULK: {
            return {
                ...state,
                contractPricesBulkSaveOngoing: true,
            };
        }
        case SAVING_CONTRACT_PRICES_BULK_FAILED: {
            return {
                ...state,
                contractPricesBulkSaveOngoing: false,
            };
        }
        case FETCH_PRODUCT_BUNDLE_DATA_IN_PROGRESS: {
            return {
                ...state,
                fetchingProductBundles: true,
            };
        }
        case FETCH_PRODUCT_BUNDLE_DATA_DONE: {
            let bundles = action.data.map((b) => new ProductBundle(b));
            bundles = orderBy(bundles, ['name']);
            return {
                ...state,
                productBundles: bundles,
                fetchingProductBundles: false,
            };
        }
        case SAVE_PRODUCT_BUNDLE_DONE: {
            const bundle = new ProductBundle(action.productBundle);
            let newProductBundles = cloneDeep(state.productBundles);
            newProductBundles.push(bundle);
            newProductBundles = orderBy(newProductBundles, ['name']);
            return {
                ...state,
                productBundles: newProductBundles,
            };
        }
        case UPDATE_PRODUCT_BUNDLE_DONE: {
            const bundle = new ProductBundle(action.productBundle);
            let newProductBundles = filter(state.productBundles, (p) => p.id !== bundle.id);
            newProductBundles.push(bundle);
            return {
                ...state,
                productBundles: newProductBundles,
            };
        }
        case REMOVE_PRODUCT_BUNDLE_DONE: {
            const newProductBundles = filter(state.productBundles, (p) => p.id !== action.data.productBundleId);
            return {
                ...state,
                productBundles: newProductBundles,
            };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

const gatherProductNotes = (products) => {
    const notes = [];
    for (const prod of products) {
        if (prod.notes && prod.notes.length > 0) {
            prod.notes.map((note) => notes.push(note.text));
        }
    }
    return uniq(notes);
};

export const getFilterProducts = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_FILTER_DATA_REQUESTED,
        });
        return Ajax.get('api/product/')
            .then(function (data) {
                dispatch({
                    type: FETCHED_FILTER_DATA,
                    products: data.data.products,
                    version: data.data.version,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_FILTER_DATA_FAILED,
                });
            });
    };
};

export const uploadCatalogExcel = (excel) => {
    return (dispatch) => {
        var promise = new Promise((resolve, reject) => {
            return Ajax.postBase64ReturnJson('api/productcatalogupdate/upload/', excel)
                .then(function (data) {
                    console.log(data);
                    resolve(data.data);
                })
                .catch(function (err) {
                    reject();
                    console.log(err);
                });
        });
        return promise;
    };
};

export const updateCatalog = (guid) => {
    return (dispatch) => {
        var promise = new Promise((resolve, reject) => {
            return Ajax.post('api/productcatalogupdate/update/' + guid, {})
                .then(function (data) {
                    resolve(data.data);
                })
                .catch(function (err) {
                    reject();
                    console.log(err);
                });
        });
        return promise;
    };
};

export const markAsLegacyProduct = (product) => {
    return async (dispatch) => {
        dispatch({ type: ADDING_NEW_LEGACY_PRODUCT });
        try {
            const resp = await Ajax.post('api/product/legacyinfo/', product);
            dispatch({
                type: ADDED_NEW_LEGACY_PRODUCT,
                product: resp.data.product,
                version: resp.data.version,
            });
        } catch (err) {
            console.log(err);
        }
    };
};

export const saveNewProduct = (product) => {
    return async (dispatch) => {
        dispatch({ type: SAVE_NEW_PRODUCT_IN_PROGRESS });
        try {
            const resp = await Ajax.post('api/product/', product);
            dispatch({
                type: SAVE_NEW_PRODUCT_DONE,
                product: resp.data.product,
                version: resp.data.version,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const saveProductRequest = (product) => {
    return async (dispatch) => {
        dispatch({ type: SAVE_NEW_PRODUCT_IN_PROGRESS });
        try {
            const resp = await Ajax.post('api/product/productrequest/', product);
            dispatch({
                type: SAVE_NEW_PRODUCT_DONE,
                product: resp.data.product,
                version: resp.data.version,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const updateProduct = (product) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_PRODUCT_IN_PROGRESS });
        try {
            product.status = FilterProduct.StatusInUse();
            const resp = await Ajax.put('api/product/' + product.id, product);
            dispatch({
                type: UPDATE_PRODUCT_DONE,
                product: resp.data.product,
                version: resp.data.version,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const getProductUsage = async (productCid) => {
    try {
        if (!productCid) return [];
        const resp = await Ajax.get('api/product/usage/' + productCid);
        return resp.data;
    } catch (err) {
        return [];
    }
};

export const getProductListXlsx = async () => {
    try {
        const resp = await Ajax.get('api/product/allxlsx');
        return resp.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const getProductListXlsxForContract = async (contractId) => {
    try {
        const resp = await Ajax.get(`api/product/contractpricesxlsx/${contractId}`);
        return resp.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const getContractPrices = (contractId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/contractprice/${contractId}`);
            dispatch({
                type: GET_CONTRACT_PRICES_DONE,
                contractPrices: resp.data,
                contractId,
            });
            return true;
        } catch (err) {
            console.log(err);
        }
    };
};

export const saveContractPricesInLocation = (prices) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SAVING_CONTRACT_PRICES_BULK,
            });
            const respDatas = [];
            for (const price of prices) {
                const resp = await Ajax.put(
                    `api/contractprice/${price.contractId}/product/${price.product.storageId}`,
                    price
                );
                respDatas.push(resp.data);
            }

            dispatch({
                type: SAVE_CONTRACT_PRICES_BULK_DONE,
                contractPrices: respDatas,
                contractId: prices[0].contractId,
            });
            return true;
        } catch (err) {
            dispatch({
                type: SAVING_CONTRACT_PRICES_BULK_FAILED,
            });
            console.log(err);
            return false;
        }
    };
};

export const saveContractPrice = (priceInfo) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.put(
                `api/contractprice/${priceInfo.contractId}/product/${priceInfo.product.storageId}`,
                priceInfo
            );
            dispatch({
                type: SAVE_CONTRACT_PRICE_DONE,
                contractPrice: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const deleteContractPrice = (contractId, productCid) => {
    return async (dispatch) => {
        try {
            await Ajax.delete(`api/contractprice/${contractId}/product/${productCid}`);
            dispatch({
                type: DELETE_CONTRACTPRICE_DONE,
                contractId,
                productCid,
            });
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const deleteProduct = (storageId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.delete(`api/product/${storageId}`);
            dispatch({
                type: DELETE_PRODUCT_DONE,
                storageId: storageId,
                version: resp.data.version,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const replaceProduct = (oldCid, newCid) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/product/replace/${oldCid}/with/${newCid}`);
            dispatch({
                type: PRODUCTS_UPDATED,
                products: resp.data.products,
                version: resp.data.version,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const mergeProducts = (oldCids, newCid) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.post(`api/product/merge/`, { oldCids, newCid });
            dispatch({
                type: PRODUCTS_UPDATED,
                products: resp.data.products,
                version: resp.data.version,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const getUpdateStatus = (uid) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/productcatalogupdate/updatestatus/${uid}`);
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const addOrUpdateProductToStore = (product, version) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_NEW_PRODUCT_DONE,
            product,
            version,
        });
    };
};

export const removeProductFromStore = (product, version) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_PRODUCT_DONE,
            storageId: product.storageId,
            version,
        });
    };
};

export const getProductListVersionFromBackend = () => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/product/version');
            return resp.data.version;
        } catch (err) {
            console.log(err);
        }
    };
};

export const getAllProductBundle = () => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_PRODUCT_BUNDLE_DATA_IN_PROGRESS,
        });
        try {
            const resp = await Ajax.get('api/product/allproductbundles');
            dispatch({
                type: FETCH_PRODUCT_BUNDLE_DATA_DONE,
                data: resp.data,
            });
        } catch (err) {
            console.log(err);
        }
    };
};

export const saveProductBundle = (productBundle) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.post('api/product/productbundle', productBundle);
            dispatch({
                type: SAVE_PRODUCT_BUNDLE_DONE,
                productBundle: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const updateProductBundle = (productBundle) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.put('api/product/productbundle', productBundle);
            dispatch({
                type: UPDATE_PRODUCT_BUNDLE_DONE,
                productBundle: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const removeProductBundle = (productBundleId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.delete(`api/product/productbundle/${productBundleId}`);
            dispatch({
                type: REMOVE_PRODUCT_BUNDLE_DONE,
                data: resp.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};
