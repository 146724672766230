import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomerTreedata, getCustomerFull } from '../modules/customerdata';
import { newOrderFromLocations } from '../modules/orderdata';
import Tree from './tree';

export default function ConnectedTree(props) {
    const dispatch = useDispatch();
    return (
        <Tree
            updateCustomerTreedata={(custId, treedata) => dispatch(updateCustomerTreedata(custId, treedata))}
            newOrderFromLocations={(locationIds) => dispatch(newOrderFromLocations(locationIds))}
            customer={props.customer}
            edit={props.edit}
            history={props.history}
            customersById={useSelector((state) => state.customerdata.customersById)}
            getCustomerFull={(id) => dispatch(getCustomerFull(id))}
            userPortfolios={props.userPortfolios}
        />
    );
}
