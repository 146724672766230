import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeOrderFromHistory, getOrdersByOrderNumber } from '../modules/orderdata';
import { getOrdersByOrderNumber as customerGetOrdersByOrderNumber } from '../modules/customerorderdata';
import ProductionOrderView from './productionorderview';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import EditIcon from '@material-ui/icons/Edit';
import User from '../domain/user';
import { includes } from 'lodash';
import { Tooltip } from '@material-ui/core';
import DeleteButton from '../common/deletebutton';
import { useTranslation } from 'react-i18next';
import PrintIcon from '@material-ui/icons/Print';
import DeleteFromHistoryConfirmationDialog from './deletefromhistoryconfirmationdialog';
import { getOrderPrintView } from '../modules/orderdata';
import { toast } from 'react-toastify';

export default function ProductionOrderViewContainer(props) {
    const dispatch = useDispatch();
    const customerUi = process.env.REACT_APP_TARGET === 'customerui';
    const ordersByOrderNumberCust = useSelector((state) => state.customerorderdata.ordersByOrderNumber);
    const ordersByOrderNumberVado = useSelector((state) => state.orderdata.ordersByOrderNumber);
    const userprofile = useSelector((state) => state.authentication.userProfile);
    const portfolios = useSelector((state) => state.portfoliodata.portfolioDescriptions);
    const [portfolio, setPortfolio] = useState({});
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [createPdfOnProgress, setCreatePdfOnProgress] = useState(false);
    const orderNumber = parseInt(props.match.params.orderNumber, 10);
    const orders = customerUi ? ordersByOrderNumberCust[orderNumber] : ordersByOrderNumberVado[orderNumber];
    const allowPricing = customerUi ? includes(userprofile.roles, User.RoleAllowPricing()) : true;
    const { t } = useTranslation();

    useEffect(() => {
        if (!orders) {
            if (customerUi) {
                dispatch(customerGetOrdersByOrderNumber(orderNumber));
            } else {
                dispatch(getOrdersByOrderNumber(orderNumber));
            }
        }
    });

    useEffect(() => {
        if (orders && portfolios && portfolios.length > 0) {
            setPortfolio(portfolios.find((p) => p.id === orders[0].portfolioId));
        }
    }, [orders, portfolios]);

    const close = () => {
        props.history.goBack();
    };

    const remove = () => {
        setRemoveDialogOpen(true);
    };

    const edit = () => {
        props.history.push(`/ordernumber/edit/${orders[0].orderNumber}`);
    };

    const cancelRemove = () => {
        setRemoveDialogOpen(false);
    };

    const confirmRemove = async (reason) => {
        setRemoving(true);
        setRemoveDialogOpen(false);
        if (await removeOrderFromHistory(orders[0].orderNumber, reason)(dispatch)) {
            close();
        }
        setRemoving(false);
    };

    const isRemovable = () => {
        return !!orders && orders[0].status <= 4;
    };

    const isEditable = () => {
        return !!orders && orders[0].status <= 4; //&& moment().add(7, 'd').isBefore(moment(orders[0].deliveryTime));
    };

    const onPrint = async () => {
        setCreatePdfOnProgress(true);
        await generatePdf();
    };

    const generatePdf = async () => {
        const result = await getOrderPrintView(orderNumber, `${t('general.order')}_${orderNumber}.pdf`)(dispatch);

        if (result) {
            toast(t('createPdf.createPdfSuccess'), { timeout: 1000, hideProgressBar: true });
        } else {
            toast(t('createPdf.createPdfFail'), { autoClose: 5000 });
        }
        setCreatePdfOnProgress(false);
    };

    if (!orders) {
        return (
            <div>
                {t('general.loading')} <CircularProgress color={'secondary'} size={'1rem'} />
            </div>
        );
    }

    return (
        <Grid container>
            <ProductionOrderView
                orders={orders}
                close={close}
                allowPricing={allowPricing}
                portfolio={portfolio}
                customerUi={customerUi}
            />

            {removeDialogOpen && (
                <DeleteFromHistoryConfirmationDialog
                    open={removeDialogOpen}
                    cancel={cancelRemove}
                    confirm={confirmRemove}
                />
            )}
            <Footer>
                {!customerUi && (
                    <React.Fragment>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                name="btn-print"
                                onClick={onPrint}
                                startIcon={<PrintIcon />}
                                endIcon={
                                    createPdfOnProgress ? <CircularProgress color={'secondary'} size={'1rem'} /> : ''
                                }
                                disabled={createPdfOnProgress}>
                                {t('buttons.downloadPdf')}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Tooltip title={isRemovable ? '' : t('order.orderCannotBeRemoved')}>
                                <span>
                                    <DeleteButton
                                        onSubmit={remove}
                                        name="btn-remove"
                                        deleting={removing}
                                        disabled={!isRemovable() || removing}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                name="btn-edit"
                                onClick={edit}
                                disabled={!isEditable() || removing}
                                startIcon={<EditIcon />}
                                title={isEditable ? null : t('order.orderCannotBeEdited')}>
                                {t('buttons.fix')}
                            </Button>
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item>
                    <Button disabled={removing} variant="contained" color="secondary" name="btn-close" onClick={close}>
                        {t('buttons.close')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </Grid>
    );
}
