import Ajax from './ajax';
import { toast } from 'react-toastify';
import Order from '../domain/order';
import ProductionOrder from '../domain/productionorder';
import { LOGGED_OFF } from './authentication';
import { cloneDeep } from 'lodash';
import i18next from 'i18next';

export const FETCH_ORDER_DATA_REQ = 'orderdata/FETCHORDERDATAREQ';
export const FETCH_ORDER_DATA_COMPLETED = 'orderdata/FETCHORDERDATACOMPLETED';
export const FETCH_ORDER_DATA_FAILED = 'orderdata/FETCHORDERDATAFAILED';

export const SAVING_ORDER = 'orderdata/SAVINGORDER';
export const ORDER_SAVED = 'orderdata/ORDERSAVED';
export const ORDER_SAVE_FAILED = 'orderdata/ORDERSAVEFAILED';

export const SAVE_ORDER_SHALLOW = 'orderdata/SAVEORDERSHALLOW';

export const SAVING_COMBINED_ORDER = 'orderdata/SAVINGCOMBINEDORDER';
export const SAVING_COMBINED_ORDER_SUCCESS = 'orderdata/SAVINGCOMBINEDORDERSUCCESS';
export const SAVING_COMBINED_ORDER_FAIL = 'orderdata/SAVINGCOMBINEDORDERFAIL';

export const REMOVE_ORDER = 'orderdata/REMOVEORDER';

export const SENDING_ORDERS = 'orderdata/SENDINGORDERS';
export const SEND_ORDERS_SUCCESS = 'orderdata/SENDORDERSSUCCESS';
export const SEND_ORDERS_FAILED = 'orderdata/SENDORDERSFAILED';

export const GET_ORDERDATA_FULL_REQ = 'orderdata/GETFULLREQ';
export const GET_ORDERDATA_FULL_SUCCESS = 'orderdata/GETFULLSUCCESS';
export const GET_ORDERDATA_FULL_FAIL = 'orderdata/GETFULLFAIL';

export const GET_PROD_ORDERDATA_FULL_REQ = 'orderdata/GETPRODFULLREQ';
export const GET_PROD_ORDERDATA_FULL_SUCCESS = 'orderdata/GETPRODFULLSUCCESS';
export const GET_PROD_ORDERDATA_FULL_FAIL = 'orderdata/GETPRODFULLFAIL';

export const REMOVE_ORDER_HISTORY_DATA_REQ = 'orderdata/REMOVEORDERHISTORYREQ';
export const REMOVE_ORDER_HISTORY_DATA_SUCCESS = 'orderdata/REMOVEORDERHISTORYSUCCESS';
export const REMOVE_ORDER_HISTORY_DATA_FAIL = 'orderdata/REMOVEORDERHISTORYFAIL';

export const FETCH_PROD_ORDERS_OPEN_DATA = 'orderdata/FETCHPRODORDERDATAOPEN';
export const FETCH_PROD_ORDERS_OPEN_DATA_SUCCESS = 'orderdata/FETCHPRODORDERDATAOPENSUCCESS';
export const FETCH_PROD_ORDERS_DELIVERED_DATA = 'orderdata/FETCHPRODORDERDATADELIVERED';
export const FETCH_PROD_ORDERS_DELIVERED_DATA_SUCCESS = 'orderdata/FETCHPRODORDERDATADELIVEREDSUCCESS';
export const FETCH_PROD_ORDERS_INVOICED_DATA = 'orderdata/FETCHPRODORDERDATAINVOICED';
export const FETCH_PROD_ORDERS_INVOICED_DATA_SUCCESS = 'orderdata/FETCHPRODORDERDATAINVOICEDSUCCESS';
export const FETCH_PROD_ORDER_DATA_FAIL = 'orderdata/FETCHPRODORDERDATAFAIL';

export const UPDATE_PROD_ORDER_DELIVERED = 'orderdata/UPDATEPRODORDERDELIVERED';
export const UPDATE_PROD_ORDER_DELIVERED_SUCCESS = 'orderdata/UPDATEPRODORDERDELIVEREDSUCCESS';
export const UPDATE_PROD_ORDER_DELIVERED_FAIL = 'orderdata/UPDATEPRODORDERDELIVEREDFAIL';

export const UPDATE_ORDER_COMBINED_STATUS = 'orderdata/UPDATEORDERCOMBINEDSTATUS';

export const FETCH_ORDER_BY_ORDER_NUMBER = 'orderdata/ORDER_BY_ORDER_NUMBER';
export const FETCH_ORDER_BY_ORDER_NUMBER_SUCCESS = 'orderdata/ORDER_BY_ORDER_NUMBER_SUCCESS';
export const FETCH_ORDER_BY_ORDER_NUMBER_FAIL = 'orderdata/ORDER_BY_ORDER_NUMBER_FAIL';

export const UPDATE_SELECTED_DRAFT_ORDERS = 'orderdata/UPDATE_SELECTED_DRAFT_ORDERS';

export const SAVE_ORDER_SHALLOW_BATCH_STARTED = 'orderdata/SAVE_ORDER_SHALLOW_BATCH_STARTED';
export const SAVE_ORDER_SHALLOW_BATCH_SUCCESS = 'orderdata/SAVE_ORDER_SHALLOW_BATCH_SUCCESS';
export const SAVE_ORDER_SHALLOW_BATCH_FAIL = 'orderdata/SAVE_ORDER_SHALLOW_BATCH_FAIL';

export const PRODUCTION_ORDER_CHANGED = 'orderdata/PRODUCTION_ORDER_CHANGED';

export const DataSaveStatus = {
    Saving: 1,
    SaveSuccess: 2,
    SaveFailed: 3,
};

export const OrderSendStatus = {
    Sending: 1,
    SendSuccessful: 2,
    SendFailed: 3,
    SendFailedUnspecified: 4,
};

export const OrderDeleteStatus = {
    Deleting: 1,
    DeleteSucess: 2,
    DeleteFailed: 3,
};

const initialState = {
    pendingOrders: null,
    isFetchingOrderData: false,
    fetchOrderDataFailed: false,
    orderSaveStatus: {},
    orderSavingInProgress: false,
    sendingOrdersInProgress: false,
    orderSendFailed: false,
    savingCombinedOrder: false,
    savingCombinedOrderFail: false,
    savingCombinedOrderSuccess: false,
    orderDataById: {},
    getOrderByIdInProgress: false,
    getOrderByIdFailed: false,
    orderHistoryDeleteStatus: {},
    openProdOrders: null,
    invoicedOrders: null,
    deliveredOrders: null,
    productionOrderDataByOrderNumber: {},
    isFetchingProdOrderData: false,
    fetchProdOrderDataFailed: false,
    isFetchingProdOrdersOpen: false,
    isFetchingProdOrdersDelivered: false,
    isFetchingProdOrdersInvoiced: false,
    isUpdatingProdOrder: false,
    ordersByOrderNumber: {}, // would contain e.g. 12345: [order1, order2,..]
    selectedDraftOrders: null,
    saveOrderShallowBatchInProgress: false,
    printPreview: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_DATA_REQ:
            return {
                ...state,
                isFetchingOrderData: true,
                fetchOrderDataFailed: false,
            };
        case FETCH_ORDER_DATA_COMPLETED:
            const orderList = [];
            for (const o of action.orders) {
                orderList.push(new Order(o));
            }
            return {
                ...state,
                pendingOrders: orderList,
                isFetchingOrderData: false,
                fetchOrderDataFailed: false,
            };
        case FETCH_ORDER_DATA_FAILED:
            return {
                ...state,
                actions: [],
                isFetchingOrderData: false,
                fetchOrderDataFailed: true,
            };
        case SAVING_ORDER: {
            let newStatus = Object.assign({}, state.orderSaveStatus);
            if (action.id != null) newStatus[action.id] = DataSaveStatus.Saving;
            return {
                ...state,
                orderSaveStatus: newStatus,
                orderSavingInProgress: true,
            };
        }
        case ORDER_SAVED: {
            let newStatus = Object.assign({}, state.orderSaveStatus);

            let newPendingOrderList = [];

            if (action.multiple === true) {
                for (let o of state.pendingOrders) {
                    let newInd = 0;
                    let addOld = true;
                    for (let newOrder of action.orders) {
                        if (newOrder.id === o.id) {
                            newPendingOrderList.push(new Order(newOrder));
                            addOld = false;
                            action.orders.splice(newInd, 1);
                            break;
                        }
                        newInd++;
                    }
                    if (addOld) newPendingOrderList.push(o);
                }
                if (action.orders.length > 0) {
                    for (let o of action.orders) {
                        newPendingOrderList.push(new Order(o));
                    }
                }
            } else {
                newStatus[action.order.id] = DataSaveStatus.SaveSuccess;
                let addOrderAsNew = true;
                for (let o of state.pendingOrders) {
                    if (o.id === action.order.id) {
                        newPendingOrderList.push(new Order(action.order));
                        addOrderAsNew = false;
                    } else {
                        newPendingOrderList.push(o);
                    }
                }
                if (addOrderAsNew) newPendingOrderList.push(new Order(action.order));
            }
            return {
                ...state,
                pendingOrders: newPendingOrderList,
                orderSaveStatus: newStatus,
                orderSavingInProgress: false,
            };
        }
        case ORDER_SAVE_FAILED: {
            let newStatus = Object.assign({}, state.orderSaveStatus);
            if (action.id != null) newStatus[action.id] = DataSaveStatus.SaveFailed;
            return {
                ...state,
                orderSaveStatus: newStatus,
                orderSavingInProgress: false,
            };
        }
        case SAVE_ORDER_SHALLOW: {
            let newPendingOrderList = [];
            for (let o of state.pendingOrders) {
                if (o.id === action.order.id) {
                    newPendingOrderList.push(new Order(action.order));
                } else {
                    newPendingOrderList.push(o);
                }
            }
            return {
                ...state,
                pendingOrders: newPendingOrderList,
            };
        }
        case REMOVE_ORDER: {
            let newPendingOrderList = [];
            for (let o of state.pendingOrders) {
                if (o.id !== action.id) {
                    newPendingOrderList.push(o);
                }
            }
            return {
                ...state,
                pendingOrders: newPendingOrderList,
            };
        }
        case SENDING_ORDERS: {
            return {
                ...state,
                sendingOrdersInProgress: true,
                orderSendFailed: false,
            };
        }
        case SEND_ORDERS_SUCCESS: {
            const sentIds = action.orders.map((o) => o.id);
            const orderList = state.pendingOrders.filter((o) => !sentIds.includes(o.id));
            const orderNumber = action.orders[0].orderNumber;
            let newOrdersByOrderNumber = state.ordersByOrderNumber[orderNumber] ? {} : state.ordersByOrderNumber;
            return {
                ...state,
                pendingOrders: orderList,
                ordersByOrderNumber: newOrdersByOrderNumber,
                sendingOrdersInProgress: false,
                orderSendFailed: false,
            };
        }
        case SEND_ORDERS_FAILED: {
            return {
                ...state,
                sendingOrdersInProgress: false,
                orderSendFailed: true,
            };
        }
        case SAVING_COMBINED_ORDER: {
            return {
                ...state,
                savingCombinedOrder: true,
                savingCombinedOrderFail: false,
                savingCombinedOrderSuccess: false,
            };
        }
        case SAVING_COMBINED_ORDER_SUCCESS: {
            return {
                ...state,
                savingCombinedOrder: false,
                savingCombinedOrderFail: false,
                savingCombinedOrderSuccess: true,
            };
        }
        case SAVING_COMBINED_ORDER_FAIL: {
            return {
                ...state,
                savingCombinedOrder: false,
                savingCombinedOrderFail: true,
                savingCombinedOrderSuccess: false,
            };
        }
        case GET_ORDERDATA_FULL_REQ:
            return {
                ...state,
                getOrderByIdInProgress: true,
                getOrderByIdFailed: false,
            };
        case GET_ORDERDATA_FULL_SUCCESS:
            let newArr = Object.assign({}, state.orderDataById);
            newArr[action.data.id] = new Order(action.data);
            return {
                ...state,
                orderDataById: newArr,
                getOrderByIdFailed: false,
                getOrderByIdInProgress: false,
            };
        case GET_ORDERDATA_FULL_FAIL:
            return {
                ...state,
                getOrderByIdFailed: true,
                getOrderByIdInProgress: false,
            };
        case REMOVE_ORDER_HISTORY_DATA_REQ: {
            let newStatus = Object.assign({}, state.orderHistoryDeleteStatus);
            newStatus[action.id] = OrderDeleteStatus.Deleting;
            return {
                ...state,
                orderHistoryDeleteStatus: newStatus,
            };
        }
        case REMOVE_ORDER_HISTORY_DATA_SUCCESS: {
            let newStatus = Object.assign({}, state.orderHistoryDeleteStatus);
            newStatus[action.id] = OrderDeleteStatus.DeleteSucess;
            return {
                ...state,
                orderHistoryDeleteStatus: newStatus,
            };
        }
        case REMOVE_ORDER_HISTORY_DATA_FAIL: {
            let newStatus = Object.assign({}, state.orderHistoryDeleteStatus);
            newStatus[action.id] = OrderDeleteStatus.DeleteFailed;
            return {
                ...state,
                orderHistoryDeleteStatus: newStatus,
            };
        }
        case FETCH_PROD_ORDERS_OPEN_DATA:
            return {
                ...state,
                isFetchingProdOrdersOpen: true,
            };
        case FETCH_PROD_ORDERS_DELIVERED_DATA:
            return {
                ...state,
                isFetchingProdOrdersDelivered: true,
            };
        case FETCH_PROD_ORDERS_INVOICED_DATA:
            return {
                ...state,
                isFetchingProdOrdersInvoiced: true,
            };
        case FETCH_PROD_ORDERS_OPEN_DATA_SUCCESS: {
            let newProdOrderList = [];
            for (let o of action.prodOrders) {
                newProdOrderList.push(new ProductionOrder(o));
            }
            return {
                ...state,
                openProdOrders: newProdOrderList,
                isFetchingProdOrdersOpen: false,
            };
        }
        case FETCH_PROD_ORDERS_DELIVERED_DATA_SUCCESS: {
            let newProdOrderList = [];
            for (let o of action.prodOrders) {
                newProdOrderList.push(new ProductionOrder(o));
            }
            return {
                ...state,
                deliveredOrders: newProdOrderList,
                isFetchingProdOrdersDelivered: false,
            };
        }
        case FETCH_PROD_ORDERS_INVOICED_DATA_SUCCESS: {
            let newProdOrderList = [];
            for (let o of action.prodOrders) {
                newProdOrderList.push(new ProductionOrder(o));
            }
            return {
                ...state,
                invoicedOrders: newProdOrderList,
                isFetchingProdOrdersInvoiced: false,
            };
        }
        case FETCH_PROD_ORDER_DATA_FAIL:
            return {
                ...state,
                isFetchingProdOrdersOpen: false,
                isFetchingProdOrdersDelivered: false,
                isFetchingProdOrdersInvoiced: false,
            };
        case UPDATE_PROD_ORDER_DELIVERED:
            return {
                ...state,
                isUpdatingProdOrder: true,
            };
        case UPDATE_PROD_ORDER_DELIVERED_SUCCESS:
            return {
                ...state,
                isUpdatingProdOrder: false,
            };
        case UPDATE_PROD_ORDER_DELIVERED_FAIL:
            return {
                ...state,
                isUpdatingProdOrder: true,
            };
        case PRODUCTION_ORDER_CHANGED: {
            const newOpenOrders = state.openProdOrders
                ? state.openProdOrders.filter((o) => o.orderNumber !== action.order.orderNumber)
                : null;
            const newDeliveredOrders = state.deliveredOrders
                ? state.deliveredOrders.filter((o) => o.orderNumber !== action.order.orderNumber)
                : null;
            const newInvoicedOrders = state.invoicedOrders
                ? state.invoicedOrders.filter((o) => o.orderNumber !== action.order.orderNumber)
                : null;

            if (action.order.status === Order.StatusOrdered() && newOpenOrders) {
                newOpenOrders.push(new ProductionOrder(action.order));
            } else if (
                action.order.status === Order.StatusDelivered() &&
                newDeliveredOrders &&
                (!action.order.invoiceNumbers || action.order.invoiceNumbers.length === 0)
            ) {
                newDeliveredOrders.push(new ProductionOrder(action.order));
            } else if (
                action.order.status === Order.StatusDelivered() &&
                newInvoicedOrders &&
                action.order.invoiceNumbers &&
                action.order.invoiceNumbers.length
            ) {
                newInvoicedOrders.push(new ProductionOrder(action.order));
            }

            return {
                ...state,
                isUpdatingProdOrder: false,
                openProdOrders: newOpenOrders,
                deliveredOrders: newDeliveredOrders,
                invoicedOrders: newInvoicedOrders,
            };
        }
        case GET_PROD_ORDERDATA_FULL_REQ:
            return {
                ...state,
                isFetchingProdOrderData: true,
                fetchProdOrdersFailed: false,
            };
        case GET_PROD_ORDERDATA_FULL_SUCCESS:
            let newProdOrdArr = Object.assign({}, state.productionOrderDataByOrderNumber);
            newProdOrdArr[action.data.orderNumber] = new ProductionOrder(action.data);
            return {
                ...state,
                productionOrderDataByOrderNumber: newProdOrdArr,
                isFetchingProdOrderData: false,
                fetchProdOrdersFailed: false,
            };
        case GET_PROD_ORDERDATA_FULL_FAIL:
            return {
                ...state,
                isFetchingProdOrderData: false,
                fetchProdOrdersFailed: true,
            };
        case FETCH_ORDER_BY_ORDER_NUMBER:
            return state;
        case FETCH_ORDER_BY_ORDER_NUMBER_SUCCESS: {
            let newOrderList = cloneDeep(state.ordersByOrderNumber);
            newOrderList[action.orderNumber] = action.orders.map((item) => {
                return new Order(item);
            });
            return {
                ...state,
                ordersByOrderNumber: newOrderList,
            };
        }
        case FETCH_ORDER_BY_ORDER_NUMBER_FAIL:
            return state;
        case UPDATE_SELECTED_DRAFT_ORDERS:
            return {
                ...state,
                selectedDraftOrders: action.selected,
            };
        case SAVE_ORDER_SHALLOW_BATCH_STARTED:
            return {
                ...state,
                saveOrderShallowBatchInProgress: true,
            };
        case SAVE_ORDER_SHALLOW_BATCH_SUCCESS:
        case SAVE_ORDER_SHALLOW_BATCH_FAIL:
            return {
                ...state,
                saveOrderShallowBatchInProgress: false,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const saveOrderShallow = (order) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_ORDER_SHALLOW,
            order: order,
        });
        try {
            let data = await Ajax.put('api/Order/shallow/' + order.id, order);
            dispatch({
                type: ORDER_SAVED,
                order: data.data,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const saveOrderShallowBatch = (orders) => {
    return async (dispatch) => {
        dispatch({
            type: SAVE_ORDER_SHALLOW_BATCH_STARTED,
        });
        try {
            let data = await Ajax.put('api/Order/shallow/batch/', orders);
            dispatch({
                type: FETCH_ORDER_DATA_COMPLETED,
                orders: data.data,
            });
            dispatch({
                type: SAVE_ORDER_SHALLOW_BATCH_SUCCESS,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: SAVE_ORDER_SHALLOW_BATCH_FAIL,
            });
            return false;
        }
    };
};

export const saveOrder = (newOrder) => (dispatch) => {
    dispatch({
        type: SAVING_ORDER,
        id: newOrder.id,
    });
    let promise = Ajax.put('api/Order/' + newOrder.id, newOrder);
    promise
        .then(function (data) {
            dispatch({
                type: ORDER_SAVED,
                order: data.data,
            });
        })
        .catch(function (err) {
            console.log(err);
            dispatch({
                type: ORDER_SAVE_FAILED,
                id: newOrder.id,
            });
        });
    return promise;
};

export const getPendingOrders = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_ORDER_DATA_REQ,
        });
        Ajax.get('api/Order/draft/')
            .then(function (data) {
                dispatch({
                    type: FETCH_ORDER_DATA_COMPLETED,
                    orders: data.data.draftOrders,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_ORDER_DATA_FAILED,
                });
            });
    };
};

export const removeOrder = (id) => {
    return (dispatch) => {
        Ajax.delete('api/Order/' + id)
            .then(function (res) {
                dispatch({
                    type: REMOVE_ORDER,
                    id: id,
                });
            })
            .then(function () {
                dispatch(getPendingOrders());
            })
            .catch(function (err) {
                console.log(err);
            });
    };
};

export const saveFilterOrder = (order, fOrder) => {
    return async (dispatch) => {
        dispatch({
            type: SAVING_ORDER,
            id: order.id,
        });
        try {
            const resp = await Ajax.put('api/order/' + order.id + '/filterorder/' + fOrder.id, fOrder);
            dispatch({
                type: ORDER_SAVED,
                order: resp.data,
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: ORDER_SAVE_FAILED,
                id: order.id,
            });
        }
    };
};

export const getOrderDataFull = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_ORDERDATA_FULL_REQ,
        });
        Ajax.get('api/order/' + id)
            .then(function (data) {
                dispatch({
                    type: GET_ORDERDATA_FULL_SUCCESS,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: GET_ORDERDATA_FULL_FAIL,
                });
            });
    };
};

const sendOrdersInternal = (orders, options, combine, dispatch, language, reservations) => {
    dispatch({
        type: SENDING_ORDERS,
        orders: orders,
    });
    const url = combine ? 'api/order/sendcombined/' : 'api/order/send/';
    Ajax.post(url, { orders: orders, options: options, language: language, reservations })
        .then(function (res) {
            dispatch({
                type: SEND_ORDERS_SUCCESS,
                orders: orders,
            });
            dispatch(getPendingOrders());
        })
        .catch(function (err) {
            console.log(err);
            dispatch({
                type: SEND_ORDERS_FAILED,
                orders: orders,
            });
        });
};

export const sendOrdersCombined = (orders, options, language, reservations) => {
    return async (dispatch) => {
        dispatch({
            type: SAVING_COMBINED_ORDER,
        });
        try {
            await Ajax.put('api/Order/shallow/batch/', orders);
            dispatch({
                type: SAVING_COMBINED_ORDER_SUCCESS,
            });
            sendOrdersInternal(orders, options, true, dispatch, language, reservations);
        } catch (err) {
            console.log(err);
            dispatch({
                type: SAVING_COMBINED_ORDER_FAIL,
            });
        }
    };
};

export const sendOrders = (orders, options, language) => {
    return (dispatch) => {
        sendOrdersInternal(orders, options, false, dispatch, language);
    };
};

export const sendEditOrders = (orders, options, language, reservations) => {
    return async (dispatch) => {
        dispatch({
            type: SENDING_ORDERS,
            orders: orders,
        });
        try {
            await Ajax.post('api/order/sendedit/', { orders, options, language, reservations });
            dispatch({
                type: SEND_ORDERS_SUCCESS,
                orders: orders,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: SEND_ORDERS_FAILED,
                orders: orders,
            });
            return false;
        }
    };
};

export const newOrderFromLocations = (locationIds) => {
    return (dispatch, getState) => {
        toast.info(i18next.t('toast.addingLocationToOrder'), { autoClose: 1000, hideProgressBar: true });
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.post('api/order/fromlocations/', locationIds)
            .then(function (res) {
                toast.info(i18next.t('toast.addedLocationToOrder'), { autoClose: 1500, hideProgressBar: true });
                dispatch({
                    type: ORDER_SAVED,
                    multiple: true,
                    orders: res.data,
                });
            })
            .catch(function (err) {
                toastError('toast.addingLocationToOrderFailed');
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const addFilterToOrder = (locationId, machineId, filterId) => {
    return (dispatch) => {
        toast.info(i18next.t('toast.addingFilterToOrder'), { autoClose: 1000, hideProgressBar: true });
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.post('api/order/location/' + locationId + '/machine/' + machineId + '/filter/' + filterId)
            .then(function (res) {
                toast.info(i18next.t('toast.addedFilterToOrder'), { autoClose: 1500, hideProgressBar: true });
                dispatch({
                    type: ORDER_SAVED,
                    order: res.data,
                });
            })
            .catch(function (err) {
                toastError('toast.addingFilterToOrderFailed');
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const addNewProductToOrder = (locationId, machineId, productCode) => {
    return (dispatch) => {
        toast.info(i18next.t('toast.addingProductToOrder'), { autoClose: 1000, hideProgressBar: true });
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.put('api/order/location/' + locationId + '/machine/' + machineId + '/product/' + productCode)
            .then(function (res) {
                toast.info(i18next.t('toast.addedProductToOrder'), { autoClose: 1500, hideProgressBar: true });
                dispatch({
                    type: ORDER_SAVED,
                    order: res.data,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const addMachineToOrder = (locId, machineId) => {
    return (dispatch) => {
        toast.info(i18next.t('toast.addingMachineToOrder'), { autoClose: 1000, hideProgressBar: true });
        dispatch({
            type: SAVING_ORDER,
            id: null,
        });
        Ajax.post('api/order/location/' + locId + '/machine/' + machineId)
            .then(function (res) {
                toast.info(i18next.t('toast.addedMachineToOrder'), { autoClose: 1500, hideProgressBar: true });
                dispatch({
                    type: ORDER_SAVED,
                    order: res.data,
                });
            })
            .catch(function (err) {
                toastError('toast.addingMachineToOrderFailed');
                dispatch({
                    type: ORDER_SAVE_FAILED,
                    id: null,
                });
            });
    };
};

export const removeOrderFromHistory = (orderId, reason) => {
    return async (dispatch) => {
        toast.info(i18next.t('toast.removingOrderInfo'), { autoClose: 1000, hideProgressBar: true });
        dispatch({
            type: REMOVE_ORDER_HISTORY_DATA_REQ,
            id: orderId,
        });
        try {
            await Ajax.delete('api/Order/fromhistory/' + orderId, { removeReason: reason });
            toast.info(i18next.t('toast.removedOrderInfo'), { autoClose: 1500, hideProgressBar: true });
            dispatch({
                type: REMOVE_ORDER_HISTORY_DATA_SUCCESS,
                id: orderId,
            });
            return true;
        } catch (err) {
            toast.error(i18next.t('toast.removingOrderInfoFailed'), { autoClose: 3000 });
            dispatch({
                type: REMOVE_ORDER_HISTORY_DATA_FAIL,
                id: orderId,
            });
            console.log(err);
            return false;
        }
    };
};

export const getProdOrdersOpen = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_PROD_ORDERS_OPEN_DATA,
        });
        Ajax.get('api/production/orders/open')
            .then(function (data) {
                dispatch({
                    type: FETCH_PROD_ORDERS_OPEN_DATA_SUCCESS,
                    prodOrders: data.data,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_PROD_ORDER_DATA_FAIL,
                });
                toast.error(i18next.t('toast.gettingOrderInfoFailed'), { autoClose: 3000 });
            });
    };
};
export const getProdOrdersDelivered = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_PROD_ORDERS_DELIVERED_DATA,
        });
        Ajax.get('api/production/orders/delivered')
            .then(function (data) {
                dispatch({
                    type: FETCH_PROD_ORDERS_DELIVERED_DATA_SUCCESS,
                    prodOrders: data.data,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_PROD_ORDER_DATA_FAIL,
                });
                toast.error(i18next.t('toast.gettingOrderInfoFailed'), { autoClose: 3000 });
            });
    };
};
export const getProdOrdersInvoiced = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_PROD_ORDERS_INVOICED_DATA,
        });
        Ajax.get('api/production/orders/invoiced')
            .then(function (data) {
                dispatch({
                    type: FETCH_PROD_ORDERS_INVOICED_DATA_SUCCESS,
                    prodOrders: data.data,
                });
            })
            .catch(function (err) {
                dispatch({
                    type: FETCH_PROD_ORDER_DATA_FAIL,
                });
                toast.error(i18next.t('toast.gettingOrderInfoFailed'), { autoClose: 3000 });
            });
    };
};

export const productionOrderChanged = (order) => {
    return (dispatch) => {
        dispatch({
            type: PRODUCTION_ORDER_CHANGED,
            order: order,
        });
    };
};

export const markOrderAsDelivered = (orderNumber, deliveryDetails) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_PROD_ORDER_DELIVERED,
        });
        try {
            await Ajax.put('api/production/' + orderNumber, {
                newStatus: Order.StatusDelivered(),
                deliveryDetails: deliveryDetails,
            });
            dispatch({
                type: UPDATE_PROD_ORDER_DELIVERED_SUCCESS,
                orderNumber: orderNumber,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: UPDATE_PROD_ORDER_DELIVERED_FAIL,
            });
            return false;
        }
    };
};

export const getProductionOrderDataFull = (orderNumber) => {
    return (dispatch) => {
        dispatch({
            type: GET_PROD_ORDERDATA_FULL_REQ,
        });
        Ajax.get('api/production/' + orderNumber)
            .then(function (data) {
                dispatch({
                    type: GET_PROD_ORDERDATA_FULL_SUCCESS,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: GET_PROD_ORDERDATA_FULL_FAIL,
                });
            });
    };
};

export const getOrdersByOrderNumber = (orderNumber) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ORDER_BY_ORDER_NUMBER });
        try {
            const resp = await Ajax.get('api/order/ordernumber/' + orderNumber);
            dispatch({
                type: FETCH_ORDER_BY_ORDER_NUMBER_SUCCESS,
                orders: resp.data,
                orderNumber: orderNumber,
            });
        } catch (err) {
            console.log(err.toJSON());
            dispatch({ type: FETCH_ORDER_BY_ORDER_NUMBER_FAIL });
        }
    };
};

export const updateSelectedDraftOrders = (selected) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_SELECTED_DRAFT_ORDERS, selected });
    };
};

export const getPoForOrder = async (orderNumber) => {
    try {
        const resp = await Ajax.get('api/production/purchase/' + orderNumber);
        return resp.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const getOrderPrintView = (orderNumber, fileName = 'document.pdf') => {
    return async (dispatch) => {
        try {
            const response = await Ajax.post(
                'api/order/print/',
                { orderNumber, options: {} },
                { responseType: 'blob', acceptHeader: 'application/pdf' }
            );

            if (response.status === 200) {
                var blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(url);
                return true;
            }

            return false;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

export const addOrdersFromExcel = (customerId, orderData) => {
    return async (dispatch) => {
        try {
            const result = await Ajax.post(`api/order/fromexcel/`, { customerId, orderDataBase64: orderData });
            dispatch({ type: ORDER_SAVED, multiple: true, orders: result.data });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };
};

const toastError = (message) => {
    toast.error(i18next.t(message), { timeout: 5000 });
};
