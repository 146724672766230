import FilterProduct from './filterproduct';

class ProductBundle {
    constructor(json) {
        if (json != null) {
            this.clusterId = json.clusterId;
            this.id = json.id;
            this.name = json.name;
            this.code = json.code;
            this.products = [];
            if (json.products != null && json.products.length > 0) {
                for (let prod of json.products) {
                    this.products.push(new ProductBundleProduct(new FilterProduct(prod.product), prod.productCount));
                }
            }
        } else {
            this.cluster_id = 0;
            this.id = 0;
            this.name = '';
            this.code = '';
            this.products = [];
        }
    }
}

class ProductBundleProduct {
    constructor(product, productCount) {
        this.product = product;
        this.productCount = productCount;
    }
}

export { ProductBundle, ProductBundleProduct };
