import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import ConnectedTree from './../treeview/connectedtree';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import NotesView, { TypeCustomer } from './notesview';
import CustomerViewBasicInfo from './customerviewbasicinfo';
import { getCustomerFull } from '../modules/customerdata';
import { saveLocation } from '../modules/locationdata';
import LocationNameInputDialog from '../treeview/locationNameInputDialog';
import Location from '../domain/location';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import PartyView from '../common/partyview';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { addOrdersFromExcel } from '../modules/orderdata';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles((theme) => ({
    accordion: {
        marginTop: '10px',
    },
}));

export default function CustomerView(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customerId = parseInt(props.match.params.customerId, 10);
    const [editTree, setEditTree] = useState(false);
    const [locationNameInputDialogOpen, setLocationNameInputDialogOpen] = useState(false);
    const customersById = useSelector((state) => state.customerdata.customersById);
    const getCustomerByIdInProgress = useSelector((state) => state.customerdata.getCustomerByIdInProgress);
    const getCustomerByIdFailed = useSelector((state) => state.customerdata.getCustomerByIdFailed);
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const showcaseMode = useSelector((state) => state.appstate.showcaseMode);
    const [uploading, setUploading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (customersById[customerId] == null && !getCustomerByIdInProgress) {
                getCustomerFull(customerId)(dispatch);
            }
        })();
    }, [customerId, customersById, dispatch, getCustomerByIdInProgress]);

    const onEditItem = () => {
        props.history.push('/customers/edit/' + customerId);
    };

    const onClose = () => {
        props.history.goBack();
    };

    const getCustomerFullFromProps = (id) => {
        return customersById[id];
    };
    const onActivateEdit = () => {
        setEditTree(true);
    };
    const onEditComplete = () => {
        setEditTree(false);
    };

    const showLocationInputNameDialog = (show) => {
        setLocationNameInputDialogOpen(show);
    };
    const saveNewLocation = (name) => {
        Location.NewObject().then((loc) => {
            loc.name = name;
            loc.customer = getCustomerFullFromProps(customerId);
            if (customerFull.electronicInvoiceAddress != null) {
                loc.useCustomerEInvoiceInfo = true;
            }
            if (customerFull.billingAddress != null) {
                loc.useCustomerBillingAddress = true;
            }
            // save location
            dispatch(saveLocation(loc, true, false)).then(() => {
                showLocationInputNameDialog(false);
                props.history.push('/locations/' + loc.id);
                props.history.push('/locations/edit/' + loc.id);
            });
        });
    };

    const usedInLocationsDom = (title, locations) => {
        return (
            <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h5>
                        {title} ({locations.length} kpl)
                    </h5>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        {locations.length === 0 && <Grid item>{t('customer.noLocations')}</Grid>}
                        {locations.length > 0 &&
                            locations.map((loc) => (
                                <Grid
                                    item
                                    key={loc.id}
                                    xs={12}
                                    onClick={() => props.history.push('/locations/' + loc.id)}>
                                    {loc.name}
                                </Grid>
                            ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    const fileUpload = async (evt) => {
        const fileReader = new FileReader();
        setUploading(true);
        fileReader.readAsDataURL(evt.target.files[0]);
        fileReader.onload = async (e) => {
            try {
                await addOrdersFromExcel(customerId, fileReader.result)(dispatch);
                setUploading(false);
            } catch (err) {
                console.log(err);
                setUploading(false);
            }
        };
    };

    let customerFull = getCustomerFullFromProps(customerId);

    if (customerFull == null) {
        return (
            <div>
                {getCustomerByIdInProgress && <Spinner padding="20px" title={t('general.loading')} />}
                {!getCustomerByIdInProgress && getCustomerByIdFailed && (
                    <div style={{ padding: '20px' }}>
                        <Alert severity="error">{t('customer.failedToRetrieveCustomerDataRetry')}</Alert>
                    </div>
                )}
            </div>
        );
    }

    return (
        <Container maxWidth={'lg'}>
            <Grid item xs={12}>
                <h2>
                    <Button name="btn-close" onClick={onClose}>
                        <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                    </Button>
                    {customerFull.name}
                </h2>

                {!getCustomerByIdInProgress && customerFull != null && (
                    <div>
                        <CustomerViewBasicInfo customer={customerFull} showcaseMode={showcaseMode} />

                        <NotesView id={customerId} type={TypeCustomer} />

                        {customerFull.partyRelations && (
                            <div style={{ marginBottom: '20px' }}>
                                <h5>{t('general.contactInformation')}</h5>
                                <PartyView showControls={false} parties={customerFull.partyRelations} />
                            </div>
                        )}

                        <Grid container>
                            <Grid item xs={12}>
                                <hr style={{ marginTop: '10px' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <h5>{t('general.locations')}</h5>
                            </Grid>
                            <Grid container>
                                <Grid item md={2} xs={12}>
                                    {!editTree && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onActivateEdit}
                                                size={'small'}>
                                                <EditIcon />
                                                {t('buttons.edit')}
                                            </Button>
                                        </Grid>
                                    )}
                                    {editTree && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onEditComplete}
                                                size={'small'}>
                                                <CheckIcon />
                                                {t('buttons.close')}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item md={10} xs={12}>
                                    <ConnectedTree
                                        edit={editTree}
                                        customer={customerFull}
                                        history={props.history}
                                        userPortfolios={userProfile.portfolioDescriptions}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {customerFull != null && (
                            <Grid container>
                                <Grid item xs={12}>
                                    {usedInLocationsDom(
                                        t('customer.invoicingCustomerInLocations'),
                                        customerFull.customerAsBillingCustomerLocations
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {usedInLocationsDom(
                                        t('customer.deliveryCustomerInLocations'),
                                        customerFull.customerAsDeliveryCustomerLocations
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </div>
                )}
            </Grid>
            <Footer>
                <Grid item>
                    <Button
                        name="btn-muokkaa"
                        variant="contained"
                        color="primary"
                        onClick={onEditItem}
                        startIcon={<EditIcon />}>
                        {t('buttons.edit')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        name="btn-new-location"
                        variant="contained"
                        color="primary"
                        onClick={() => showLocationInputNameDialog(true)}
                        startIcon={<AddIcon />}>
                        {t('general.newLocation')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        name="btn-sulje"
                        variant="contained"
                        color="default"
                        onClick={onClose}
                        startIcon={<CloseIcon />}>
                        {t('buttons.close')}
                    </Button>
                </Grid>
                <Grid item>
                    <div>
                        <label htmlFor="upload-excel">
                            <input
                                style={{ display: 'none' }}
                                id="upload-excel"
                                name="upload-excel"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={fileUpload}
                                type="file"
                            />

                            <Button
                                color="primary"
                                disabled={uploading}
                                component="span"
                                aria-label="add"
                                variant="contained"
                                startIcon={<AddShoppingCartIcon />}
                                endIcon={uploading ? <CircularProgress color={'secondary'} size={'1rem'} /> : ''}>
                                {t('buttons.addToCart')}&nbsp;(.xlsx)
                            </Button>
                        </label>
                    </div>
                </Grid>
            </Footer>
            <FooterPadding />
            <LocationNameInputDialog
                dialogOpen={locationNameInputDialogOpen}
                cancel={() => showLocationInputNameDialog(false)}
                confirm={saveNewLocation}
            />
        </Container>
    );
}
