import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import { Alert } from '@material-ui/lab/';
import FeedbackItem from '../domain/feedbackitem';
import LocationDeliveryAddressResolver from '../modules/locationdeliveryaddressresolver';
import { formatCurrency } from '../common/common';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LocationMargin from './locationmargin';
import LocationBillingInfoView from './locationBillingInfoView';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold',
    },
}));

export default function LocationViewBasicInfo(props) {
    const locationFull = props.location;
    const customerUi = props.customerUi;
    const viewOnly = props.viewOnly;
    const classes = useStyles();
    const { t } = useTranslation();
    //const showcaseMode = useSelector((state) => state.appstate.showcaseMode);
    let deliveryInfo = null;
    let remainders = '';

    if (locationFull != null) {
        deliveryInfo = LocationDeliveryAddressResolver.getDeliveryAddress(locationFull);

        if (locationFull.reminderMonths != null && locationFull.reminderMonths.length > 0) {
            for (let m of locationFull.reminderMonths) {
                if (m === locationFull.reminderMonths[0]) remainders += moment.months(m - 1);
                else remainders += ', ' + moment.months(m - 1);
            }
        } else {
            remainders = t('frontpage.noReminders');
        }
    }

    const clickCustomer = () => {
        props.history.push('/customers/' + locationFull.customer.id);
    };

    const clickBillingCustomer = () => {
        props.history.push('/customers/' + locationFull.billingCustomer.id);
    };

    const onSendFeedBack = (reason) => {
        let msg = '';
        switch (reason) {
            case 1:
                msg = 'Toimitustiedot (osoite) puuttuvat.';
                break;
            case 2:
                msg = 'Laskutustiedot puuttuvat';
                break;
            default:
                return;
        }
        var feedback =
            'Hei, <br/><br/>Olet saanut palautetta asiakkaan ' +
            locationFull.customer.name +
            ' edustajalta.' +
            'Palaute on liittyy kohteeseen ' +
            locationFull.name +
            '<br/><br/>' +
            '<b>Viesti:</b> ' +
            msg +
            '.<br/>';

        let fbItem = new FeedbackItem();
        fbItem.locationId = locationFull.id;
        fbItem.customerId = locationFull.customer.id;
        fbItem.message = feedback;
        props.sendFeedback(fbItem);
    };

    const reminderLabel = customerUi ? t('general.orderDate') : t('location.reminders');

    return (
        <Grid item>
            {customerUi === false && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('general.portfolio')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {locationFull.portfolio.name}
                    </Grid>
                </Grid>
            )}
            {customerUi === false && !props.showcaseMode && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('general.value')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {formatCurrency(locationFull.orderValueWithoutVat)}
                    </Grid>
                </Grid>
            )}
            {customerUi === false && !props.showcaseMode && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('general.margin')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocationMargin location={locationFull} />
                    </Grid>
                </Grid>
            )}
            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.customerNumber')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.customerNumber}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.businessId')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.businessId}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.vatId')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.vatId}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('parameterCategories.ContractNumber')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {!!locationFull.contractNumber && <span>{locationFull.contractNumber.value}</span>}
                </Grid>
            </Grid>
            {customerUi === false && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('parameterCategories.InvoicingTerms')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {!!locationFull.invoicingTerms && <span>{locationFull.invoicingTerms.value}</span>}
                    </Grid>
                </Grid>
            )}
            {customerUi === false && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('parameterCategories.DeliveryTerms')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {!!locationFull.deliveryTerms && <span>{locationFull.deliveryTerms.value}</span>}
                    </Grid>
                </Grid>
            )}
            {customerUi === false && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('parameterCategories.DeliveryMethod')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {!!locationFull.deliveryMethod && <span>{locationFull.deliveryMethod.value}</span>}
                        {!!locationFull.customer && !!locationFull.customer.deliveryMethod && (
                            <span>
                                ({t('general.customer')}: <em>{locationFull.customer.deliveryMethod.value}</em>)
                            </span>
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.serviceArea')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.serviceArea}
                </Grid>
            </Grid>

            {locationFull.customer && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('general.customer')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link name="link-open-customer" onClick={clickCustomer}>
                            {locationFull.customer.name}
                        </Link>
                    </Grid>
                </Grid>
            )}
            {locationFull.billingCustomer && (
                <Grid item container>
                    <Grid item xs={12} md={6} className={classes.bold}>
                        {t('general.invoicingCustomer')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link name="link-open-billing-customer" onClick={clickBillingCustomer}>
                            {locationFull.billingCustomer.name}
                        </Link>
                    </Grid>
                </Grid>
            )}
            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.deliveryInfo')}
                </Grid>

                {deliveryInfo && (
                    <Grid item xs={12} md={6}>
                        {deliveryInfo}
                    </Grid>
                )}
                {!deliveryInfo && (
                    <Grid item xs={12} md={6}>
                        <Alert severity="error">
                            {t('general.deliveryInfoIncomplete')}&nbsp;
                            {customerUi === true && !viewOnly && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    name="btn-send-feedback-delivery-info"
                                    onClick={() => onSendFeedBack(1)}
                                    startIcon={<EmailIcon />}>
                                    {t('buttons.sendFeedback')}
                                </Button>
                            )}
                        </Alert>
                    </Grid>
                )}
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.contactInformation')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.contactPersonName}(
                    <a href={'tel:' + locationFull.contactPersonTel}>{locationFull.contactPersonTel}</a>)
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.packingInstructions')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.packingInstructions}
                    {!!locationFull.customer && !!locationFull.customer.packingInstructions && (
                        <span>
                            &nbsp;({t('general.customer')}: <em>{locationFull.customer.packingInstructions}</em>)
                        </span>
                    )}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.yourReference')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.reference}
                    {!!locationFull.customer && !!locationFull.customer.reference && (
                        <span>
                            &nbsp;({t('general.customer')}: <em>{locationFull.customer.reference}</em>)
                        </span>
                    )}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('parameterCategories.OurReference')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {!!locationFull.ourReference && <span>{locationFull.ourReference.value}</span>}
                    {!!locationFull.customer && !!locationFull.customer.ourReference && (
                        <span>
                            &nbsp;({t('general.customer')}: <em>{locationFull.customer.ourReference.value}</em>)
                        </span>
                    )}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {reminderLabel}
                </Grid>
                <Grid item xs={12} md={6}>
                    <em>{remainders}</em>
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12}>
                    <hr style={{ marginTop: '0' }} />
                </Grid>
                <Grid item xs={12}>
                    <h5>{t('general.invoicingInfo')}</h5>
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.customerNumber')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.billingInformation && (
                        <React.Fragment>
                            {locationFull.billingInformation.billingResponsibleCustomerNumber}
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.businessId')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {locationFull.billingInformation && (
                        <React.Fragment>{locationFull.billingInformation.billingResponsibleBusinessId}</React.Fragment>
                    )}
                </Grid>
            </Grid>

            <Grid item container>
                <Grid item xs={12} md={6} className={classes.bold}>
                    {t('general.invoicingInfo')}
                </Grid>

                {locationFull && locationFull.billingInformationValid && (
                    <Grid item xs={12} md={6}>
                        {locationFull.billingInformation.billingAddress && (
                            <Grid item xs={12}>
                                {locationFull.billingInformation.billingResponsible}
                                <br />
                                {locationFull.billingInformation.billingAddress.streetAddress},&nbsp;
                                {locationFull.billingInformation.billingAddress.zipCode}&nbsp;
                                {locationFull.billingInformation.billingAddress.city}
                            </Grid>
                        )}
                        {locationFull.billingInformation.electronicInvoiceAddress && (
                            <Grid item xs={12}>
                                {locationFull.billingInformation.electronicInvoiceAddress.ovt},&nbsp;
                                {locationFull.billingInformation.electronicInvoiceAddress.operatorBrokerId},&nbsp;
                                {locationFull.billingInformation.electronicInvoiceAddress.operator}
                            </Grid>
                        )}

                        {locationFull.billingInformation.emailInvoiceAddress != null &&
                            locationFull.billingInformation.emailInvoiceAddress.length > 0 && (
                                <Grid item xs={12}>
                                    {locationFull.billingInformation.emailInvoiceAddress}
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            {t('parameterCategories.ContractNumber')}:{' '}
                            {locationFull.billingInformation.billingResponsibleContractNumber}
                        </Grid>
                    </Grid>
                )}
                {(!locationFull || !locationFull.billingInformationValid) && (
                    <Grid item xs={12} md={6}>
                        <Alert severity="error">
                            {t('errors.invalidInvoicingInfo')} &nbsp;
                            {customerUi === true && !viewOnly && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    name="btn-send-feedback-billing-info"
                                    onClick={() => onSendFeedBack(2)}
                                    startIcon={<EmailIcon />}>
                                    {t('buttons.sendFeedback')}
                                </Button>
                            )}
                        </Alert>
                    </Grid>
                )}
                {customerUi === false && locationFull.billingInformationValid && (
                    <Grid item xs={12}>
                        <LocationBillingInfoView location={locationFull} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
