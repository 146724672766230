import HomeContainer from '../action/homecontainer';
import Customers from '../customer/customers';
import Locations from '../location/locations';
import ShoppingBasketContainer from '../order/shoppingbasketcontainer';
import LogoutPage from '../common/logout';
import CustomerHomeContainer from '../customerui/home/customerhomecontainer';
import CustomerLocations from '../customerui/locations/customerlocations';
import CustomerShoppingBasketContainer from '../customerui/basket/customershoppingbasketcontainer';
import FeedbackPage from '../feedback/feedbackpage';
import Reports from '../reporting/reports';
import UserData from '../userprofile/userdataview';
import ProductList from '../products/productlist';
import AdminContainer from '../admin/admincontainer';
import InvoiceListContainer from '../invoice/invoicelistcontainer';
import ProductionContainer from '../production/productioncontainer';
import DebugInfo from '../debuginfo/debuginfo';
import OrderHistoryContainer from '../reporting/orderhistorycontainer';

var AppRoAppRoutesStatic = [];
if (process.env.REACT_APP_TARGET === 'customerui') {
    AppRoAppRoutesStatic = [
        {
            Id: 1,
            Path: '/home',
            Component: CustomerHomeContainer,
        },
        {
            Id: 3,
            Path: '/locations',
            Component: CustomerLocations,
        },
        {
            Id: 4,
            Path: '/basket',
            Component: CustomerShoppingBasketContainer,
        },
        {
            Id: 5,
            Path: '/logout',
            Component: LogoutPage,
        },
        {
            Id: 6,
            Path: '/feedback',
            Component: FeedbackPage,
        },
        {
            Id: 7,
            Path: '/reports',
            Component: Reports,
        },
        {
            Id: 8,
            Path: '/user',
            Component: UserData,
        },
        {
            Id: 14,
            Path: '/orderhistory',
            Component: OrderHistoryContainer,
        },
    ];
} else {
    AppRoAppRoutesStatic = [
        {
            Id: 1,
            Path: '/home',
            Component: HomeContainer,
        },
        {
            Id: 2,
            Path: '/customers',
            Component: Customers,
        },
        {
            Id: 3,
            Path: '/locations',
            Component: Locations,
        },
        {
            Id: 4,
            Path: '/basket',
            Component: ShoppingBasketContainer,
        },
        {
            Id: 5,
            Path: '/logout',
            Component: LogoutPage,
        },
        {
            Id: 6,
            Path: '/feedback',
            Component: FeedbackPage,
        },
        {
            Id: 7,
            Path: '/reports',
            Component: Reports,
        },
        {
            Id: 8,
            Path: '/user',
            Component: UserData,
        },
        {
            Id: 9,
            Path: '/products',
            Component: ProductList,
        },
        {
            Id: 10,
            Path: '/admin',
            Component: AdminContainer,
        },
        {
            Id: 11,
            Path: '/production',
            Component: ProductionContainer,
        },
        {
            Id: 12,
            Path: '/invoices',
            Component: InvoiceListContainer,
        },
        {
            Id: 13,
            Path: '/debug',
            Component: DebugInfo,
        },
        {
            Id: 14,
            Path: '/orderhistory',
            Component: OrderHistoryContainer,
        },
    ];
}

export default AppRoAppRoutesStatic;
