import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from '../common/common';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
    },
    marginBottom: {
        marginBottom: '20px',
    },
    table: {
        width: '100%',
    },
    itemValueRow: {
        background: 'lightgray',
    },
    totalValue: {
        textAlign: 'right',
    },
    backArrow: {
        cursor: 'pointer',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    mobileTable: {
        '& .MuiTableCell-sizeSmall': {
            padding: '6px 0px 6px 16px',
        },
    },
}));

export default function ProductionOrderView(props) {
    const classes = useStyles();
    const [showRowDetails, setShowRowDetails] = useState(false);
    const { t } = useTranslation();

    const formatDeliveryTime = (deliveryTime) => {
        var date = moment(deliveryTime);
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        }
        return deliveryTime;
    };

    const orderTotalValueWithoutVat = () => {
        return formatCurrency(props.orders.reduce((prev, cur) => prev + cur.orderTotalPriceWithoutVat, 0));
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item>
                    <h2>
                        {props.close && (
                            <ArrowBackIosIcon onClick={props.close} className={classes.backArrow} name="close" />
                        )}
                        {t('general.order')} {props.orders[0].orderNumber}, {props.orders[0].customer.name}{' '}
                        {moment(props.orders[0].orderSent).format('DD.MM.YYYY')}
                    </h2>
                </Grid>
            </Grid>
            {!props.customerUi && (
                <Grid container>
                    <Grid item xs={12} md={6} className={classes.title}>
                        {t('general.portfolio')}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {props.portfolio.name}
                    </Grid>
                </Grid>
            )}
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.orderNumber')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].orderNumber}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.state')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].orderStatusText()}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.customer')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].customer.name}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.orderer')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].ordererName}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('order.deliveryRecipientTel')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].ordererTel}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('order.orderSent')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {moment(props.orders[0].orderSent).format('DD.MM.YYYY')}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.deliveryInfo')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders && props.orders.length > 1 && props.orders[0].orderToPrivateCustomer ? (
                        <span>...</span>
                    ) : (
                        props.orders[0].getDeliveryInfo()
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('parameterCategories.InvoicingTerms')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].billingTerms}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.invoicingInfo')}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                        {props.orders[0].getBillingAddressInfo()}
                    </Grid>
                    <Grid item xs={12}>
                        {props.orders[0].getElectronicInvoiceAddressInfo()}
                    </Grid>
                    <Grid item xs={12}>
                        {props.orders[0].emailInvoiceAddress}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('order.confirmationEmail')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].confirmationEmail}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('parameterCategories.ContractNumber')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].contractNumber}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('parameterCategories.DeliveryMethod')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].deliveryMethod}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('parameterCategories.DeliveryTerms')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].deliveryTerms}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.deliveryDate')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {formatDeliveryTime(props.orders[0].deliveryTime)}
                </Grid>
            </Grid>
            <Grid container className={classes.marginBottom}>
                <Grid item xs={12} md={6} className={classes.title}>
                    {t('general.yourReference')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {props.orders[0].reference}
                </Grid>
            </Grid>
            <FormControlLabel
                control={
                    <Switch
                        checked={showRowDetails}
                        onChange={() => setShowRowDetails(!showRowDetails)}
                        name="show-details"
                    />
                }
                label={t('general.showAdditionalInfo')}
            />
            {props.orders.map((order) => (
                <Grid container key={order.id} className={classes.marginBottom}>
                    <Grid item xs={12}>
                        <h3>{order.location ? order.location.name : order.getDeliveryInfo()}</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <h5>{t('order.orderedProducts')}</h5>

                        <div className={classes.sectionDesktop}>
                            <Table size="small" className={classes.table}>
                                {showRowDetails && (
                                    <colgroup>
                                        <col width="20%" />
                                        <col width="20%" />
                                        <col width="20%" />
                                        <col width="10%" />
                                        <col width="20%" />
                                        <col width="10%" />
                                    </colgroup>
                                )}
                                {!showRowDetails && (
                                    <colgroup>
                                        <col width="30%" />
                                        <col width="50%" />
                                        <col width="20%" />
                                    </colgroup>
                                )}
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('location.machine')}</TableCell>
                                        {showRowDetails && (
                                            <React.Fragment>
                                                <TableCell>{t('location.position')}</TableCell>
                                                <TableCell>{t('location.serviceArea')}</TableCell>
                                                <TableCell>{t('product.code')}</TableCell>
                                            </React.Fragment>
                                        )}
                                        <TableCell>{t('product.name')}</TableCell>
                                        <TableCell>{t('pcs')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.orderedFilters.map((o) => (
                                        <TableRow key={o.id}>
                                            <TableCell>{o.machineName}</TableCell>
                                            {showRowDetails && (
                                                <React.Fragment>
                                                    <TableCell>{o.machineLocation}</TableCell>
                                                    <TableCell>{o.machineArea}</TableCell>
                                                    <TableCell>{o.product.productCode}</TableCell>
                                                </React.Fragment>
                                            )}
                                            <TableCell>{o.product.productName}</TableCell>
                                            <TableCell>{o.count}</TableCell>
                                        </TableRow>
                                    ))}
                                    {props.allowPricing && (
                                        <TableRow className={classes.itemValueRow}>
                                            <TableCell colSpan={showRowDetails ? 3 : 0}></TableCell>
                                            <TableCell colSpan={showRowDetails ? 2 : 0}>
                                                {t('invoice.totalPriceVatZero')}
                                            </TableCell>
                                            <TableCell>{formatCurrency(order.orderTotalPriceWithoutVat)}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <div className={classes.sectionMobile}>
                            <Table classes={{ root: classes.mobileTable }} size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="30%" maxwidth="30%">
                                            {t('location.machine')}
                                        </TableCell>
                                        <TableCell width="50%">{t('product.name')}</TableCell>
                                        <TableCell width="20%">{t('pcs')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.orderedFilters.map((o) => (
                                        <TableRow key={o.id}>
                                            <TableCell width="30%" maxwidth="30%">
                                                {o.machineName}
                                                {showRowDetails && (
                                                    <span>
                                                        {o.machineLocation && o.machineLocation.length > 0 && (
                                                            <span>
                                                                <br />
                                                                {t('location.position')}: <em>{o.machineLocation}</em>
                                                            </span>
                                                        )}
                                                        {o.machineArea && o.machineArea.length > 0 && (
                                                            <span>
                                                                <br />
                                                                {t('location.serviceArea')}: <em>{o.machineArea}</em>
                                                            </span>
                                                        )}
                                                    </span>
                                                )}
                                            </TableCell>
                                            <TableCell width="50%">{o.product.productName}</TableCell>
                                            <TableCell width="20%">{o.count}</TableCell>
                                        </TableRow>
                                    ))}
                                    {props.allowPricing && (
                                        <TableRow className={classes.itemValueRow}>
                                            <TableCell width="30%" maxwidth="30%"></TableCell>
                                            <TableCell width="50%">{t('invoice.totalPriceVatZero')}</TableCell>
                                            <TableCell width="20%">
                                                {formatCurrency(order.orderTotalPriceWithoutVat)}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>
                </Grid>
            ))}
            {props.allowPricing && (
                <Grid container className={classes.totalValue}>
                    <Grid item xs={12}>
                        {t('order.theWholeOrder')}&nbsp;
                        {t('invoice.totalPriceVatZero')}
                        {orderTotalValueWithoutVat()}
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}
