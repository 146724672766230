import FilterProduct from './filterproduct';
import { round } from 'mathjs';

class ContractPrice {
    constructor(json) {
        if (json != null) {
            this.contractId = json.contractId;
            this.product = new FilterProduct(json.product);
            this.price = json.price;
            this.discount = json.discount;
            this.inUse = json.inUse;
        }
    }

    calculateDiscountPrice() {
        return round(this.product.priceWithoutVat * ((100 - this.discount) / 100), 3);
    }

    calculateDiscountPercent() {
        return round(100 - (this.price / this.product.priceWithoutVat) * 100, 3);
    }

    marginEur() {
        if (
            this.product &&
            this.product.productionPrice &&
            this.product.productionPrice > 0 &&
            (this.price || this.discount)
        ) {
            return !!this.price
                ? this.price - this.product.productionPrice
                : this.calculateDiscountPrice() - this.product.productionPrice;
        } else {
            return -1;
        }
    }

    marginPercentage() {
        if (
            this.product &&
            this.product.productionPrice &&
            this.product.productionPrice > 0 &&
            (this.price || this.discount)
        ) {
            return !!this.price
                ? ((1 - this.product.productionPrice / this.price) * 100).toFixed(2)
                : ((1 - this.product.productionPrice / this.calculateDiscountPrice()) * 100).toFixed(2);
        } else {
            return -1;
        }
    }
}

export default ContractPrice;
