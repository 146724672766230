import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ActionAllowed } from '../rbac/rbacutil';
import { rbacactions } from '../rbac/rbacrules';
import { getContractPrices, saveContractPrice, deleteContractPrice } from '../modules/productdata';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContractPrice from '../domain/contractprice';
import ContractPricesProduct from './contractpricesproduct';
import { sortBy } from 'lodash';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import FilterProductSelectDialog from '../location/filterproductselectdialog';
import DeleteConfirmDialog from '../common/deleteconfirmdialog';
import ConfirmationDialog from '../common/confirmationdialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getProductListXlsxForContract } from '../modules/productdata';
const dayjs = require('dayjs');

const useStyles = makeStyles((theme) => ({
    accordion: {
        marginTop: '0.5em',
    },
    addbutton: {
        marginBottom: '1em',
        marginTop: '0.5em',
    },
}));

export default function ContractPrices() {
    const contractPrices = useSelector((state) => state.productdata.contractPricesByContractId);
    const parameters = useSelector((state) => state.parameterdata.parameters);
    const user = useSelector((state) => state.authentication.userProfile);
    const allProducts = useSelector((state) => state.productdata.filterProducts);
    const [selectedRow, setSelectedRow] = useState({});
    const [editOpenRow, setEditOpenRow] = useState({});
    const [priceInEdit, setPriceInEdit] = useState(null);
    const [saving, setSaving] = useState(false);

    const [reff] = useState(React.createRef());
    const [blobUrl, setBlobUrl] = useState('');
    const [downloadFilename, setDownloadFilename] = useState('');
    const [loadingXlsx, setLoadingXlsx] = useState(false);

    // for adding new contract price
    const [showNewProdSelector, setShowNewProdSelector] = useState(false);
    const [contractIdInScope, setContractIdInScope] = useState(null);
    const [addNewProdTitle, setAddNewProdTitle] = useState('');

    // for removing contract price
    const [deleteConfirmTitle, setDeleteConfirmTitle] = useState('');
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [itemToDeleteId, setItemToDeleteId] = useState(null);

    // for changing the pricing scheme between fixed <-> percentage based
    const [confirmChangePricingSchemeText, setConfirmChangePricingSchemeText] = useState('');
    const [confirmChangeSchemeOpen, setConfirmChangeSchemeOpen] = useState(false);
    const [confirmSchemeChangeParams, setConfirmSchemeChangeParams] = useState({});

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onAccordionChange = (evt, expanded, contractId) => {
        if (expanded && !contractPrices[contractId]) {
            getContractPrices(contractId)(dispatch);
        }
    };

    const onSelectRow = (contractId, productId) => {
        if (!ActionAllowed(rbacactions.AdjustContractPricing, user)) return;

        if (editOpenRow.contractId) return;
        setSelectedRow({ contractId, productId });
    };

    const editRow = (contractId, productId) => {
        if (!ActionAllowed(rbacactions.AdjustContractPricing, user)) return;

        const cp = contractPrices[contractId].find((p) => p.product.id === productId);
        const toEdit = new ContractPrice(cp);
        if (toEdit.price === null && toEdit.discount === null) {
            toEdit.price = toEdit.product.priceWithoutVat;
        }
        setPriceInEdit(new ContractPrice(toEdit));
        setEditOpenRow({ contractId, productId });
    };

    const cancelEdit = () => {
        setEditOpenRow({});
        setPriceInEdit(null);
    };

    const saveRow = async (row) => {
        await saveContractPrice(row)(dispatch);
    };

    const isSelected = (contractId, productId) => {
        return selectedRow.contractId === contractId && selectedRow.productId === productId;
    };

    const isEditOpen = (contractId, productId) => {
        return editOpenRow.contractId === contractId && editOpenRow.productId === productId;
    };

    const onChangePriceRow = (priceRow) => {
        setPriceInEdit(priceRow);
    };

    const sortPrices = (prices) => {
        return sortBy(prices, (o) => {
            if (o.price === null && o.discount === null) {
                return 'Ö' + o.product.productName;
            } else {
                return o.product.productName;
            }
        });
    };

    const addNewProduct = (contractId) => {
        setAddNewProdTitle(
            t('product.addProductToContract', {
                contractNumber: parameters['ContractNumber'].find((p) => p.id === contractId).value,
            })
        );
        setShowNewProdSelector(true);
        setContractIdInScope(contractId);
    };

    const onSelectNewProd = async (product) => {
        setShowNewProdSelector(false);
        if (contractPrices[contractIdInScope].find((p) => p.product.id === product)) {
            toast.error(t('product.productIsAlreadyIncludedInContractPrices'), {
                autoClose: 5000,
                hideProgressBar: false,
            });
        } else {
            const newPrice = {
                contractId: contractIdInScope,
                discount: 0.0,
                price: null,
                inUse: false,
                product: allProducts.find((p) => p.id === product),
            };
            await saveContractPrice(newPrice)(dispatch);
            toast.info(t('product.productWasAddedToContractPricing'), {
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        setContractIdInScope(null);
    };

    const confirmDeleteContractPrice = async () => {
        await deleteContractPrice(itemToDeleteId.contractId, itemToDeleteId.productCid)(dispatch);
        setItemToDeleteId(null);
        setDeleteConfirmTitle('');
        setConfirmDeleteOpen(false);
    };

    const onDeleteContractPrice = (contractId, productCid) => {
        setItemToDeleteId({ contractId, productCid });
        setDeleteConfirmTitle(
            `Sopimushinnan tuotteelle ${
                contractPrices[contractId].find((p) => p.product.storageId === productCid).product.productName
            }`
        );
        setConfirmDeleteOpen(true);
    };

    const toFixedPrices = (contractId) => {
        setConfirmChangePricingSchemeText(t('product.confirmChangePricingToUseNetPrices'));
        setConfirmSchemeChangeParams({ toFixed: true, contractId });
        setConfirmChangeSchemeOpen(true);
    };

    const toPercentagePrices = (contractId) => {
        setConfirmChangePricingSchemeText(t('product.confirmChangePricingToUseDiscounts'));
        setConfirmSchemeChangeParams({ toFixed: false, contractId });
        setConfirmChangeSchemeOpen(true);
    };

    const doPricingSchemeChange = async () => {
        setConfirmChangeSchemeOpen(false);
        setSaving(true);
        for (const p of sortPrices(contractPrices[confirmSchemeChangeParams.contractId])) {
            if (p.price || p.discount) {
                let newRow = new ContractPrice(p);
                if (confirmSchemeChangeParams.toFixed && !p.price) {
                    newRow.price = newRow.calculateDiscountPrice();
                    newRow.discount = null;
                } else if (!confirmSchemeChangeParams.toFixed && !p.discount) {
                    newRow.discount = newRow.calculateDiscountPercent();
                    newRow.price = null;
                } else {
                    continue;
                }
                await saveContractPrice(newRow)(dispatch);
            }
        }
        setSaving(false);
    };

    const loadProductListXlsx = async (e, contractId) => {
        if (blobUrl) {
            e.stopPropagation();
            return;
        }
        setLoadingXlsx(true);
        const xlsx = await getProductListXlsxForContract(contractId);

        const buff = Buffer.from(xlsx, 'base64');
        const blob = new Blob([buff.buffer], {
            type: 'application/vnd.openxmlformats',
        });
        const url = URL.createObjectURL(blob);
        const contractNumber = parameters['ContractNumber'].find((c) => c.id === contractId).value;
        setBlobUrl(url);
        setDownloadFilename(`Tuotteet_${contractNumber}_${dayjs().format()}.xlsx`);

        setTimeout(() => {
            reff.current.click();
            setTimeout(() => {
                setBlobUrl('');
                setLoadingXlsx(false);
            }, 1000);
        }, 10);
    };

    if (parameters == null) return <div>{t('general.loading')}</div>;
    if (!parameters['ContractNumber']) return null;

    return (
        <Grid container alignItems={'flex-end'} alignContent={'flex-end'} justifyContent={'flex-end'}>
            <div style={{ display: 'hidden' }}>
                <a // eslint-disable-line
                    ref={reff}
                    href={blobUrl.length > 0 ? blobUrl : null}
                    download={blobUrl.length > 0 ? downloadFilename : null}></a>
            </div>
            {parameters['ContractNumber'].map((c) => (
                <Grid key={c.id} item xs={12}>
                    <Accordion
                        className={classes.accordion}
                        onChange={(evt, expanded) => onAccordionChange(evt, expanded, c.id)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} name={'contract-prices-' + c.id}>
                            <strong>{c.value}</strong>
                        </AccordionSummary>
                        <AccordionDetails>
                            {!contractPrices[c.id] && <span>{t('general.loading')}</span>}
                            {!!contractPrices[c.id] && (
                                <Grid container>
                                    {contractPrices[c.id].length === 0 && (
                                        <span>{t('product.noPricingInformation')}</span>
                                    )}
                                    {contractPrices[c.id].length > 0 && (
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Button
                                                    disabled={saving}
                                                    className={classes.addbutton}
                                                    startIcon={
                                                        saving ? (
                                                            <CircularProgress size={'1rem'} />
                                                        ) : (
                                                            <SwapHorizontalCircleIcon />
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    name="btn-to-fixed-prices"
                                                    onClick={() => toFixedPrices(c.id)}>
                                                    {t('product.netPrices')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    disabled={saving}
                                                    className={classes.addbutton}
                                                    startIcon={
                                                        saving ? (
                                                            <CircularProgress size={'1rem'} />
                                                        ) : (
                                                            <SwapHorizontalCircleIcon />
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    name="btn-to-percentage-pricing"
                                                    onClick={() => toPercentagePrices(c.id)}>
                                                    {t('product.percentageDiscount')}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.addbutton}
                                                    disabled={loadingXlsx}
                                                    name="btn-load-product-xsls"
                                                    onClick={(e) => loadProductListXlsx(e, c.id)}
                                                    startIcon={
                                                        loadingXlsx ? (
                                                            <CircularProgress color={'secondary'} size={'1rem'} />
                                                        ) : (
                                                            <CloudDownloadIcon />
                                                        )
                                                    }>
                                                    {t('location.buttons.download')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {sortPrices(contractPrices[c.id]).map((p) => (
                                        <ContractPricesProduct
                                            priceRow={isEditOpen(c.id, p.product.id) ? priceInEdit : p}
                                            key={p.product.id}
                                            edit={isEditOpen(c.id, p.product.id)}
                                            highlight={isSelected(c.id, p.product.id)}
                                            selectRow={() => onSelectRow(c.id, p.product.id)}
                                            editRow={() => editRow(c.id, p.product.id)}
                                            save={async (row) => await saveRow(row)}
                                            cancel={cancelEdit}
                                            onChange={onChangePriceRow}
                                            delete={onDeleteContractPrice}
                                        />
                                    ))}
                                    <Grid item xs={12}>
                                        <Button
                                            disabled={saving}
                                            className={classes.addbutton}
                                            startIcon={<AddIcon />}
                                            variant="contained"
                                            color="primary"
                                            name="btn-add-new-product"
                                            onClick={() => addNewProduct(c.id)}>
                                            {t('general.product')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            ))}
            <FilterProductSelectDialog
                allowNewProd={false}
                title={addNewProdTitle}
                show={showNewProdSelector}
                selected={onSelectNewProd}
            />
            <DeleteConfirmDialog
                open={confirmDeleteOpen}
                itemToDelete={deleteConfirmTitle}
                cancel={() => {
                    setConfirmDeleteOpen(false);
                    setItemToDeleteId(null);
                    setDeleteConfirmTitle('');
                }}
                confirmDelete={confirmDeleteContractPrice}
            />
            <ConfirmationDialog
                open={confirmChangeSchemeOpen}
                confirm={doPricingSchemeChange}
                cancel={() => setConfirmChangeSchemeOpen(false)}
                confirmText={t('product.change')}>
                {confirmChangePricingSchemeText}
            </ConfirmationDialog>
        </Grid>
    );
}
