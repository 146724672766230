import Location from '../domain/location';
import LocationDescription from '../domain/locationdescription';
import Ajax from './ajax';
import history from '../history';
import { REMOVE_ORDER_HISTORY_DATA_SUCCESS } from './orderdata';
import { SAVE_NEW_CUSTOMER_SUCCESS } from './customerdata';
import { LOGGED_OFF } from './authentication';
import { SAVE_CONTRACT_PRICE_DONE, UPDATE_PRODUCT_DONE, DELETE_PRODUCT_DONE, PRODUCTS_UPDATED } from './productdata';
import { toast } from 'react-toastify';
import i18next from 'i18next';
const _ = require('lodash');

export const SAVE_LOCATION_IN_PROGRESS = 'locationdata/SAVE_LOCATION_IN_PROGRESS';
export const SAVE_LOCATION_SUCCESS = 'locationdata/SAVE_LOCATION_SUCCESS';
export const SAVE_LOCATION_FAILED = 'locationdata/SAVE_LOCATION_FAILED';

export const REMOVE_LOCATION_IN_PROGRESS = 'locationdata/REMOVE_LOCATION_IN_PROGRESS';
export const REMOVE_LOCATION_SUCCESS = 'locationdata/REMOVE_LOCATION_SUCCESS';
export const REMOVE_LOCATION_FAILED = 'locationdata/REMOVE_LOCATION_FAILED';

export const GET_DESCRIPTIVE_INFO_IN_PROGRESS = 'locationdata/getdescinfoinprogress';
export const GET_DESCRIPTIVE_INFO_FAIL = 'locationdata/getdescinfofail';
export const GET_DESCRIPTIVE_INFO_SUCCESS = 'locationdata/getdescinfosuccess';

export const GET_LOCATION_FULL_IN_PROGRESS = 'locationdata/getlocfullinproc';
export const GET_LOCATION_FULL_SUCCESS = 'locationdata/getlocfullsucces';
export const GET_LOCATION_FULL_FAILED = 'locationdata/getlocfullfailed';
export const GET_LOCATION_FULL_NOT_FOUND = 'locationdata/getlocfullnotfound';

export const TREE_VIEW_FILTER_UPDATE = 'locationdata/treeviewfilterupdate';
export const LOCATION_VIEW_FILTER_UPDATE = 'locationdata/locationviewfilterupdate';

export const NOTE_SAVED = 'locationdata/notesaved';
export const NOTE_REMOVED = 'customerdata/notesremoved';

export const GET_PRINT_PREVIEW_IN_PROGRESS = 'locationdata/getofferinproc';
export const GET_PRINT_PREVIEW_READY = 'locationdata/getofferready';

export const CLEAR_LOCATION_FULL_DATA = 'locationdata/CLEAR_LOCATION_FULL_DATA';
export const CLEAR_LOCATION_FULL_DATA_BY_ID = 'locationdata/CLEAR_LOCATION_FULL_DATA_BY_ID';

export const LOCATION_PORTFOLIO_UPDATED = 'locationdata/portfolioupdated';
export const LOCATION_PORTFOLIO_UPDATE_IN_PROGRESS = 'locationdata/portfolioupdateinprogress';
export const LOCATION_PORTFOLIO_UPDATE_FAILED = 'locationdata/portfolioupdatefailed';

export const DataRemoveStatus = {
    Removing: 1,
    RemoveSuccess: 2,
    RemoveFailed: 3,
};

export const SaveFailureReason = {
    GeneralError: 1,
    VersionConflict: 2,
};

const initialState = {
    savingData: false,
    saveDataFailed: false,
    saveDone: false,
    saveFailReason: {},
    locationDescriptions: null,
    getLocationDescriptionsInProgress: false,
    getLocationDescriptionsFailed: false,
    locationsById: {},
    getLocByIdInProgress: false,
    getLocByIdFailed: false,
    removeLocationStatus: {},
    locationFilter: '',
    treeViewCustomerFilter: '',
    printInProgress: false,
    printPreview: '',
    locationPortfolioUpdateInProgress: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_LOCATION_IN_PROGRESS: {
            return {
                ...state,
                savingData: true,
                saveDataFailed: false,
                saveDone: false,
                saveFailReason: {},
            };
        }
        case SAVE_LOCATION_FAILED:
            return {
                ...state,
                savingData: false,
                saveDataFailed: true,
                saveDone: true,
                saveFailReason: action.reason,
            };
        case SAVE_LOCATION_SUCCESS: {
            let locationsNew = Object.assign({}, state.locationsById);
            if (action.addToStore) locationsNew[action.location.id] = new Location(action.location);
            return {
                ...state,
                locationsById: locationsNew,
                savingData: false,
                saveDataFailed: false,
                saveDone: true,
                saveFailReason: {},
            };
        }
        case REMOVE_LOCATION_IN_PROGRESS: {
            let newStatus = Object.assign({}, state.removeLocationStatus);
            newStatus[action.id] = DataRemoveStatus.Removing;
            return {
                ...state,
                removeLocationStatus: newStatus,
            };
        }
        case REMOVE_LOCATION_FAILED: {
            let newStatus = Object.assign({}, state.removeLocationStatus);
            newStatus[action.id] = DataRemoveStatus.RemoveFailed;
            return {
                ...state,
                removeLocationStatus: newStatus,
            };
        }
        case REMOVE_LOCATION_SUCCESS: {
            //cleanup removed location from locationlist
            let locationsNew = Object.assign({}, state.locationsById);
            locationsNew[action.data.locationId] = null;
            //cleanup removed location from descriptive list
            let newlocationDescriptions = [];
            if (!!state.locationDescriptions) {
                for (var locDesc of state.locationDescriptions) {
                    if (locDesc.id !== action.data.locationId) {
                        newlocationDescriptions.push(locDesc);
                    }
                }
            }
            let newStatus = Object.assign({}, state.removeLocationStatus);
            newStatus[action.data.locationId] = DataRemoveStatus.RemoveSuccess;
            return {
                ...state,
                removeLocationStatus: newStatus,
                locationsById: locationsNew,
                locationDescriptions: !!state.locationDescriptions ? newlocationDescriptions : null,
            };
        }
        case GET_DESCRIPTIVE_INFO_IN_PROGRESS:
            return {
                ...state,
                getLocationDescriptionsInProgress: true,
                getLocationDescriptionsFailed: false,
            };
        case GET_DESCRIPTIVE_INFO_FAIL:
            return {
                ...state,
                getLocationDescriptionsFailed: true,
                getLocationDescriptionsInProgress: false,
                locationDescriptions: null,
            };
        case GET_DESCRIPTIVE_INFO_SUCCESS: {
            let data = [];
            if (action.data != null && action.data.length > 0) {
                for (let loc of action.data) {
                    data.push(new LocationDescription(loc));
                }
            }
            return {
                ...state,
                locationDescriptions: data,
                getLocationDescriptionsInProgress: false,
            };
        }
        case GET_LOCATION_FULL_IN_PROGRESS:
            return {
                ...state,
                getLocByIdInProgress: true,
                getLocByIdFailed: false,
            };
        case GET_LOCATION_FULL_SUCCESS:
            let newArr = Object.assign({}, state.locationsById);
            newArr[action.data.id] = new Location(action.data);

            return {
                ...state,
                locationsById: newArr,
                getLocByIdFailed: false,
                getLocByIdInProgress: false,
            };
        case GET_LOCATION_FULL_NOT_FOUND:
            return {
                ...state,
                getLocByIdFailed: false,
                getLocByIdInProgress: false,
            };
        case GET_LOCATION_FULL_FAILED:
            return {
                ...state,
                getLocByIdFailed: true,
                getLocByIdInProgress: false,
            };
        case LOCATION_VIEW_FILTER_UPDATE: {
            return {
                ...state,
                locationFilter: action.filter,
            };
        }
        case TREE_VIEW_FILTER_UPDATE: {
            return {
                ...state,
                treeViewCustomerFilter: action.filter,
            };
        }
        case REMOVE_ORDER_HISTORY_DATA_SUCCESS:
            return {
                ...state,
                locationsById: {},
            };
        case SAVE_NEW_CUSTOMER_SUCCESS:
            return {
                ...state,
                locationsById: {},
            };
        case NOTE_SAVED: {
            let location = new Location(state.locationsById[action.locationId]);
            location.notes = action.notes;
            let newArr = Object.assign({}, state.locationsById);
            newArr[action.locationId] = new Location(location);
            return {
                ...state,
                locationsById: newArr,
            };
        }
        case NOTE_REMOVED: {
            let location = new Location(state.locationsById[action.locationId]);
            location.notes = _.filter(location.notes, function (n) {
                return n.storageId !== action.note.storageId;
            });
            let newArr = _.cloneDeep(state.locationsById);
            newArr[action.locationId] = new Location(location);
            return {
                ...state,
                locationsById: newArr,
            };
        }
        case PRODUCTS_UPDATED:
        case DELETE_PRODUCT_DONE:
        case UPDATE_PRODUCT_DONE:
        case SAVE_CONTRACT_PRICE_DONE: {
            return {
                ...state,
                locationsById: {},
            };
        }
        case GET_PRINT_PREVIEW_IN_PROGRESS: {
            return {
                ...state,
                printInProgress: true,
                printPreview: '',
            };
        }
        case GET_PRINT_PREVIEW_READY: {
            return {
                ...state,
                printInProgress: false,
                printPreview: action.data,
            };
        }
        case CLEAR_LOCATION_FULL_DATA: {
            const newLocations = {};
            if (!!action.doNotRemoveThis) {
                const preserve = state.locationsById[action.doNotRemoveThis];
                newLocations[action.doNotRemoveThis] = preserve;
            }
            return {
                ...state,
                locationsById: newLocations,
            };
        }
        case CLEAR_LOCATION_FULL_DATA_BY_ID: {
            const newArr = Object.assign({}, state.locationsById);
            newArr[action.id] = undefined;
            return {
                ...state,
                locationsById: newArr,
            };
        }
        case LOCATION_PORTFOLIO_UPDATED:
            return {
                ...state,
                locationsById: {},
                locationDescriptions: null,
                locationPortfolioUpdateInProgress: false,
            };
        case LOCATION_PORTFOLIO_UPDATE_IN_PROGRESS:
            return {
                ...state,
                locationPortfolioUpdateInProgress: true,
            };
        case LOCATION_PORTFOLIO_UPDATE_FAILED:
            return {
                ...state,
                locationPortfolioUpdateInProgress: false,
            };
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

const sanitizeLocation = (location) => {
    const toSend = _.cloneDeep(location);

    // clear out treedata from customer references as it may contain circular dependencies.
    if (toSend.customer) toSend.customer.treeData = undefined;
    if (toSend.deliveryCustomer) toSend.deliveryCustomer.treeData = undefined;
    if (toSend.billingCustomer) toSend.billingCustomer.treeData = undefined;

    return toSend;
};

export const saveLocation =
    (location, clearTreeData = true, addToStore = true) =>
    (dispatch) => {
        dispatch({
            type: SAVE_LOCATION_IN_PROGRESS,
            location: location,
        });
        let promise = new Promise((resolve, reject) => {
            Ajax.put('api/Location/' + location.id, sanitizeLocation(location))
                .then((data) => {
                    dispatch({
                        type: SAVE_LOCATION_SUCCESS,
                        location: data.data,
                        clearTreeData: clearTreeData,
                        addToStore: addToStore,
                    });
                    resolve(data.data);
                })
                .catch(function (err) {
                    dispatch({
                        type: SAVE_LOCATION_FAILED,
                        reason:
                            err.response && err.response.status === 409
                                ? SaveFailureReason.VersionConflict
                                : SaveFailureReason.GeneralError,
                    });
                    reject();
                });
        });
        return promise;
    };

export const getBillingInformation = (location) => {
    return Ajax.post('api/billinginformation/', location);
};

export const removeLocation = (location) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_LOCATION_IN_PROGRESS,
            id: location.id,
        });
        Ajax.delete('api/location/' + location.id)
            .then(function (data) {
                dispatch({
                    type: REMOVE_LOCATION_SUCCESS,
                    data: data.data,
                    customerId: location.customer.id,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: REMOVE_LOCATION_FAILED,
                    id: location.id,
                });
            });
    };
};

export const getDescriptiveInfo = () => {
    return (dispatch) => {
        dispatch({
            type: GET_DESCRIPTIVE_INFO_IN_PROGRESS,
        });
        Ajax.get('api/location/')
            .then(function (data) {
                dispatch({
                    type: GET_DESCRIPTIVE_INFO_SUCCESS,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                dispatch({
                    type: GET_DESCRIPTIVE_INFO_FAIL,
                });
            });
    };
};

export const getLocationFull = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_LOCATION_FULL_IN_PROGRESS,
        });
        Ajax.get('api/location/' + id)
            .then(function (data) {
                dispatch({
                    type: GET_LOCATION_FULL_SUCCESS,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
                // 404 = not found => not existing or deleted location
                if (err.response && err.response.status === 404) {
                    toastError('errors.locationMissingOrRemoved');
                    history.replace('/locations');
                    dispatch({ type: GET_LOCATION_FULL_NOT_FOUND });
                } else {
                    dispatch({
                        type: GET_LOCATION_FULL_FAILED,
                    });
                    toastError('general.fetchFailedRetry');
                }
            });
    };
};

export const saveNote = (note) => {
    return async (dispatch) => {
        try {
            let resp = await Ajax.post('api/note/', note);
            dispatch({
                type: NOTE_SAVED,
                notes: resp.data,
                locationId: note.locationId,
            });
            return true;
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 404) {
                toastError('toast.locationInfoCouldNotBeSavedPotentiallyRemoved');
            }
            return false;
        }
    };
};

export const editNote = (note) => {
    return async (dispatch) => {
        try {
            let resp = await Ajax.put('api/note/', note);
            dispatch({
                type: NOTE_SAVED,
                notes: resp.data,
                locationId: note.locationId,
            });
            return true;
        } catch (err) {
            console.log(err);
            toastError('toast.locationInfoCouldNotBeSavedPotentiallyRemoved');
            return false;
        }
    };
};

export const removeNote = (note) => {
    return async (dispatch) => {
        try {
            await Ajax.delete('api/note/' + note.storageId);
            dispatch({
                type: NOTE_REMOVED,
                note: note,
                locationId: note.locationId,
            });
            return true;
        } catch (err) {
            console.log(err);
            toastError('toast.locationInfoCouldNotBeSavedPotentiallyRemoved');
            return false;
        }
    };
};

export const getOfferPrint = (location, deliveryFee, printLanguage, options) => {
    return (dispatch) => {
        dispatch({
            type: GET_PRINT_PREVIEW_IN_PROGRESS,
        });
        Ajax.post('api/location/print/', {
            location: location,
            deliveryFee: deliveryFee,
            language: printLanguage,
            options: options,
        })
            .then(function (data) {
                dispatch({
                    type: GET_PRINT_PREVIEW_READY,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
            });
    };
};

export const getFilterListPrint = (location, printLanguage) => {
    return (dispatch) => {
        dispatch({
            type: GET_PRINT_PREVIEW_IN_PROGRESS,
        });
        Ajax.post('api/location/printfilterlist/', {
            location: location,
            deliveryFee: null,
            language: printLanguage,
            options: null,
        })
            .then(function (data) {
                dispatch({
                    type: GET_PRINT_PREVIEW_READY,
                    data: data.data,
                });
            })
            .catch(function (err) {
                console.log(err);
            });
    };
};

export const refreshLocationFullDataIfNeeded = (id, cid) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/location/${id}/currentcid/${cid}`);

            if (resp.status === 204) {
                // 204 = HTTP no content. Data is okay in the frontend
                return true;
            } else if (resp.status === 200) {
                // 200 = HTTP OK, new data provided
                dispatch({
                    type: GET_LOCATION_FULL_SUCCESS,
                    data: resp.data,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };
};

export const clearLocationFullDatas = (doNotRemoveThis) => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_LOCATION_FULL_DATA,
            doNotRemoveThis: doNotRemoveThis,
        });
    };
};

export const clearLocationFullDataById = (id) => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_LOCATION_FULL_DATA_BY_ID,
            id: id,
        });
    };
};

export const updatePortfolio = (locationId, portfolioId, customerId) => (dispatch) => {
    dispatch({ type: LOCATION_PORTFOLIO_UPDATE_IN_PROGRESS });
    let promise = Ajax.put('api/location/' + locationId + '/portfolio/' + portfolioId);
    promise
        .then(() => {
            dispatch({ type: LOCATION_PORTFOLIO_UPDATED, customerId });
        })
        .catch((err) => {
            if (err.response && err.response.status === 404) {
                toastError('toast.locationInfoCouldNotBeSavedPotentiallyRemoved');
            }
            console.log(err);
            dispatch({ type: LOCATION_PORTFOLIO_UPDATE_FAILED });
        });
    return promise;
};

export const getLocationXlsx = async (id) => {
    try {
        const resp = await Ajax.get(`api/location/offerxlsx/${id}`);
        return resp.data;
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const getLocationByCustomerNumber = (customerNumber) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/location/customerNumber/${customerNumber}`);
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

const toastError = (message) => {
    toast.error(i18next.t(message), { timeout: 5000, hideProgressBar: true });
};
