import FilterProduct from './filterproduct';
import { round } from 'mathjs';

class Filter {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.product = new FilterProduct(json.product);
            this.count = json.count;
            this.discount = json.discount;
            this.price = json.price;
            this.additionalInfo = json.additionalInfo;
            this.viewOrder = json.viewOrder;
            this.originalProductName = json.originalProductName;
            this.contractPricesInUse = json.contractPricesInUse;
        } else {
            this.id = 0;
            this.product = new FilterProduct();
            this.count = 0;
            this.discount = 0;
            this.price = null;
            this.additionalInfo = '';
            this.viewOrder = 1;
        }
    }

    priceRounded() {
        return this.price % 1 > 0 ? round(this.price, 2) : this.price;
    }

    discountRounded(precision) {
        if (!!!precision) precision = 4;
        return this.discount % 1 > 0 ? round(this.discount, precision) : this.discount;
    }

    calculateDiscountPrice() {
        const price = round(this.product.priceWithoutVat * ((100 - this.discount) / 100), 2);
        if (isNaN(price)) return 0;
        return price;
    }

    calculateDiscountPercent() {
        const discount = round(100 - (this.price / this.product.priceWithoutVat) * 100, 4);
        if (isNaN(discount)) return 0;
        return discount;
    }

    getFilterTotalValue() {
        //returns filter price multiplicated by count
        return this.discount === null
            ? this.price * this.count
            : ((100 - this.discount) / 100) * (this.count * this.product.priceWithoutVat);
    }

    //return filter price, if price set, return rounded price, if discount set, calculates rounded price
    getFilterPriceRounded() {
        return this.discount == null
            ? round(this.price, 2)
            : round(((100 - this.discount) / 100) * this.product.priceWithoutVat, 2);
    }

    marginEur() {
        if (this.product.productionPrice && this.product.productionPrice > 0) {
            return this.getFilterTotalValue() - this.product.productionPrice * this.count;
        } else {
            return -1;
        }
    }

    marginPercentage() {
        if (this.product.productionPrice && this.product.productionPrice > 0) {
            return ((1 - (this.product.productionPrice * this.count) / this.getFilterTotalValue()) * 100).toFixed(2);
        } else {
            return -1;
        }
    }
}

export default Filter;
