import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import SaveButton from '../common/savebutton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { saveCapacityAllocations, loadCapacityAllocations } from '../modules/prodplanningdata';
import { buildCapacityByPortfolioAndWeekData } from './weeklycapacitydatabuilder';
import {
    formatWeekIdentifierMoment,
    getWeekFromIdentifier,
    getYearFromIdentifier,
    generateContinuousWeekList,
} from '../order/capacityplanner';
import CapacityAllocation from '../domain/capacityallocation';
import { useTranslation } from 'react-i18next';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    paper: {
        padding: '5px',
    },
}));

const ProductionPlanning = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const capacityAllocationsByWeek = useSelector((state) => state.prodplanningdata.capacityAllocationsByWeek);
    const portfolioDescriptions = useSelector((state) => state.portfoliodata.portfolioDescriptions);
    const loadingCapacityData = useSelector((state) => state.prodplanningdata.loadingCapacityData);
    const [edit, setEdit] = useState(false);

    // mutated data for view. Contains data from backend & possible changes by user
    const [weeklyCapacitiesPerPortfolio, setWeeklyCapacitiesPerPortfolio] = useState({});
    const [urgentCapacity, setUrgentCapacity] = useState({});

    // contains item changes by user that are sent to backend in case data is saved
    const [changedItems, setChangedItems] = useState([]);

    const [weekList, setWeekList] = useState([]);
    const [startWeek, setStartWeek] = useState(null);
    const [endWeek, setEndWeek] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [hasChanges, setHasChanges] = useState([]);
    const [saving, setSaving] = useState(false);
    const [changingSelection, setChangingSelection] = useState(false);

    const { t } = useTranslation();

    // used to build the data for UI
    // inputs: backend data (capacityAllocationsByWeek) + potential changes by user (changedItems)
    useEffect(() => {
        if (
            !portfolioDescriptions ||
            portfolioDescriptions.length === 0 ||
            !capacityAllocationsByWeek ||
            Object.keys(capacityAllocationsByWeek).length === 0 ||
            weekList.length === 0
        ) {
            return;
        }
        const capacityData = buildCapacityByPortfolioAndWeekData(
            capacityAllocationsByWeek,
            portfolioDescriptions,
            weekList,
            changedItems
        );

        setWeeklyCapacitiesPerPortfolio(capacityData.capacityPerPortfolio);
        setUrgentCapacity(capacityData.urgentCapacity);
        setChangingSelection(false);
    }, [capacityAllocationsByWeek, portfolioDescriptions, weekList, changedItems]);

    // used only to populate initial week list
    useEffect(() => {
        if (weekList.length === 0) {
            const start = moment().subtract(1, 'months');
            const end = moment().add(4, 'months');
            setStartWeek(`${formatWeekIdentifierMoment(start)}`);
            setEndWeek(`${formatWeekIdentifierMoment(end)}`);
            setStartDate(start);
            setEndDate(end);
            setWeekList(
                generateContinuousWeekList(formatWeekIdentifierMoment(start), formatWeekIdentifierMoment(end), true)
            );
        }
    }, [weekList.length]);

    useEffect(() => {
        if (changedItems && changedItems.length > 0) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [changedItems]);

    // makes sure that we have loaded all necessary data from backend
    useEffect(() => {
        if (loadingCapacityData) return;

        if (weekList && weekList.length > 0 && startWeek && endWeek && capacityAllocationsByWeek) {
            const sortedWeeks = weekList.sort();
            if (
                !capacityAllocationsByWeek[sortedWeeks[0]] ||
                !capacityAllocationsByWeek[sortedWeeks[sortedWeeks.length - 1]]
            ) {
                dispatch(
                    loadCapacityAllocations(
                        getYearFromIdentifier(startWeek),
                        getWeekFromIdentifier(startWeek),
                        getYearFromIdentifier(endWeek),
                        getWeekFromIdentifier(endWeek)
                    )
                );
            } else {
                setChangingSelection(false);
            }
        } else if (
            weekList &&
            weekList.length &&
            Object.keys(capacityAllocationsByWeek).length === 0 &&
            startWeek &&
            endWeek
        ) {
            dispatch(
                loadCapacityAllocations(
                    getYearFromIdentifier(startWeek),
                    getWeekFromIdentifier(startWeek),
                    getYearFromIdentifier(endWeek),
                    getWeekFromIdentifier(endWeek)
                )
            );
        }
    }, [weekList, capacityAllocationsByWeek, startWeek, endWeek, dispatch, loadingCapacityData]);

    const onChange = (e, portfolioId, yearAndWeek) => {
        const year = getYearFromIdentifier(yearAndWeek);
        const week = getWeekFromIdentifier(yearAndWeek);
        const newValue = parseInt(e.target.value);

        const weeklyCapaForPortfolio = capacityAllocationsByWeek[yearAndWeek].find(
            (a) => a.portfolioId === portfolioId
        );

        const newItems = cloneDeep(changedItems);
        const indInChangedItems = newItems.findIndex(
            (a) => a.portfolioId === portfolioId && a.year === year && a.week === week
        );

        if (
            (weeklyCapaForPortfolio && weeklyCapaForPortfolio.allocatedCapacity !== newValue) ||
            (!weeklyCapaForPortfolio && newValue > 0)
        ) {
            // 1) there is capacity configured in backend data and we're about to change it
            //   --> add the changed value to "changedItems" state variable.
            //
            // 2) There is no configured capacity for the given portfolio and week -> add/update item
            //
            if (indInChangedItems !== -1) {
                newItems.splice(indInChangedItems, 1);
            }
            newItems.push(
                new CapacityAllocation({
                    portfolioId,
                    year,
                    week,
                    allocatedCapacity: newValue,
                })
            );
        } else if (
            (weeklyCapaForPortfolio && weeklyCapaForPortfolio.allocatedCapacity === newValue) ||
            !weeklyCapaForPortfolio
        ) {
            // 1) there is capacity configured in the backend data and it is the same as
            // the edited value --> Make sure any change object are removed from "changedItems"
            //
            // 2) There is no configured capacity in backend for this case, and the value that is edited is null/0/empty
            //    --> make sure change item is cleaned out
            if (indInChangedItems !== -1) {
                newItems.splice(indInChangedItems, 1);
            }
        }
        setChangedItems(newItems);
    };

    const cancelEdit = () => {
        setChangedItems([]);
        setEdit(false);
    };

    const saveChanges = async () => {
        setSaving(true);
        await saveCapacityAllocations(changedItems)(dispatch);
        setChangedItems([]);
        setSaving(false);
    };

    const changeSelection = async (months) => {
        setChangingSelection(true);
        const start = startDate.add(months, 'months');
        const end = endDate.add(months, 'months');
        setStartWeek(`${formatWeekIdentifierMoment(start)}`);
        setEndWeek(`${formatWeekIdentifierMoment(end)}`);
        setWeekList(
            generateContinuousWeekList(formatWeekIdentifierMoment(start), formatWeekIdentifierMoment(end), true)
        );
    };

    if (!weeklyCapacitiesPerPortfolio || !portfolioDescriptions) return null;

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('general.portfolio')}</TableCell>
                            {weekList.map((w) => (
                                <TableCell key={w}>{w}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {portfolioDescriptions.map((p) => (
                            <TableRow key={p.name}>
                                <TableCell component="th" scope="row">
                                    {p.name}
                                </TableCell>
                                {!edit &&
                                    weekList.map((w) => (
                                        <TableCell key={w}>
                                            {weeklyCapacitiesPerPortfolio &&
                                                weeklyCapacitiesPerPortfolio[p.id] &&
                                                weeklyCapacitiesPerPortfolio[p.id].capacities[w] && (
                                                    <span>{weeklyCapacitiesPerPortfolio[p.id].capacities[w]}</span>
                                                )}
                                        </TableCell>
                                    ))}
                                {edit &&
                                    weekList.map((w) => (
                                        <TableCell key={w}>
                                            {weeklyCapacitiesPerPortfolio && weeklyCapacitiesPerPortfolio[p.id] && (
                                                <Input
                                                    value={
                                                        weeklyCapacitiesPerPortfolio[p.id].capacities[w]
                                                            ? weeklyCapacitiesPerPortfolio[p.id].capacities[w]
                                                            : 0
                                                    }
                                                    onChange={(e) => onChange(e, p.id, w)}
                                                    name={p.id + w}
                                                />
                                            )}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {t('admin.urgentCapacity')}
                            </TableCell>
                            {!edit &&
                                weekList.map((w) => (
                                    <TableCell key={w}>
                                        {urgentCapacity[w] && <span>{urgentCapacity[w]}</span>}
                                        {!urgentCapacity[w] && <span>0</span>}
                                    </TableCell>
                                ))}
                            {weekList.map((w) => (
                                <TableCell key={w}>
                                    <Input
                                        value={urgentCapacity[w] ? urgentCapacity[w] : 0}
                                        onChange={(e) => onChange(e, 0, w)}
                                        name={'urgent' + w}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Footer>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="previous"
                        disabled={changingSelection}
                        onClick={() => changeSelection(-3)}
                        startIcon={<SkipPreviousIcon />}>
                        -3kk
                    </Button>
                </Grid>
                {!edit && (
                    <Grid item>
                        <Button variant="contained" color="primary" name="btn-edit" onClick={() => setEdit(true)}>
                            <i className="fas fa-pencil-alt" />
                            &nbsp;{t('buttons.edit')}
                        </Button>
                    </Grid>
                )}
                {edit && (
                    <Grid item>
                        <SaveButton
                            disabled={!hasChanges || saving || changingSelection}
                            hasChanges={hasChanges}
                            onSubmit={saveChanges}
                            saving={saving}
                        />
                    </Grid>
                )}
                {edit && (
                    <Grid item>
                        <Button variant="contained" color="primary" name="btn-cancel" onClick={cancelEdit}>
                            {t('buttons.cancel')}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="next"
                        disabled={changingSelection}
                        onClick={() => changeSelection(3)}
                        endIcon={<SkipNextIcon />}>
                        +3kk
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </Grid>
    );
};

export default ProductionPlanning;
