import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import { invoiceFromOrder, saveInvoice } from '../modules/invoicedata';
import { getProductionOrderDataFull, getPoForOrder } from '../modules/orderdata';
import Order from '../domain/order';
import ConfirmationDialog from '../common/confirmationdialog';
import moment from 'moment';
import Spinner from '../common/spinner';
import { ActionAllowed } from '../rbac/rbacutil';
import { rbacactions } from '../rbac/rbacrules';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import ProductionComplateDialog from './productioncompletedialog';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    buttonRow: {
        float: 'right',
    },
    weekheader: {
        paddingTop: '10px',
        paddingLeft: '10px',
    },
}));

const columns = [
    { id: 'ordernumber', label: 'general.orderNumber' },
    { id: 'orderdate', label: 'general.ordered' },
    { id: 'deliveryDate', label: 'general.deliveryDate' },
    { id: 'customerName', label: 'production.customerAndLocation' },
    { id: 'capacityReserved', label: 'production.capacityReservations' },
    //{ id: 'portfolio', label: 'Salkku' },
];

const nextStateLabel = [
    { state: 4, label: 'order.orderSent' },
    { state: 8, label: 'production.makeInvoice' },
];

function OrderRow(props) {
    const order = props.row;
    const [open, setOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [confirmText, setConfirmText] = React.useState('');
    const [confirmBtnText, setConfirmBtnText] = React.useState('');
    const [confirmOrderReady, setConfirmOrderReady] = React.useState(false);
    const [poBlobUrl, setPoBlobUrl] = React.useState('');
    const productionOrderDataByOrderNumber = useSelector((state) => state.orderdata.productionOrderDataByOrderNumber);
    const classes = useStyles();
    const dispatch = useDispatch();
    const orderFull = productionOrderDataByOrderNumber[order.orderNumber];
    const { t } = useTranslation();

    const getButtonLabel = () => {
        return t(_.find(nextStateLabel, ['state', order.status]).label);
    };

    const toNextState = () => {
        if (order.status === Order.StatusOrdered()) {
            setConfirmOrderReady(true);
        } else {
            setConfirmText(t('production.confirmMakeNewInvoice'));
            setConfirmBtnText(t('buttons.save'));
            setConfirmOpen(true);
        }
    };

    const getButtonIcon = () => {
        if (order.status === Order.StatusDelivered()) return <AddIcon />;
        else return <CheckIcon />;
    };

    const onConfirm = async () => {
        setConfirmOpen(false);
        if (order.status === Order.StatusDelivered()) {
            const invoice = await invoiceFromOrder(order.orderNumber)(dispatch);
            if (!!invoice) {
                if (!(await saveInvoice(invoice)(dispatch))) {
                    toast.error(t('production.invoiceGenerationFailed'), { autoClose: 5000, hideProgressBar: false });
                    return;
                }
                toast.info(t('production.invoiceGenerated'), { autoClose: 2000, hideProgressBar: true });
                setOpen(false);
            } else {
                toast.error(t('production.invoiceGenerationFailed'), { autoClose: 5000, hideProgressBar: false });
                return;
            }
        }
    };

    const onCancelConfirm = () => {
        setConfirmOpen(false);
    };

    const expandOrder = async () => {
        if (!productionOrderDataByOrderNumber[order.orderNumber]) {
            dispatch(getProductionOrderDataFull(order.orderNumber));
        }
        setOpen(!open);

        var xlsxdata = await getPoForOrder(order.orderNumber);
        const buff = Buffer.from(xlsxdata, 'base64');
        const blob = new Blob([buff.buffer], {
            type: 'application/vnd.openxmlformats',
        });
        const url = URL.createObjectURL(blob);
        setPoBlobUrl(url);
    };

    const isAllowed = () => {
        if (order.status === Order.StatusOrdered()) {
            return ActionAllowed(rbacactions.MarkOrderDelivered, props.userProfile);
        } else if (order.status === Order.StatusDelivered()) {
            return ActionAllowed(rbacactions.CreateInvoiceFromOrder, props.userProfile);
        }
        return false;
    };

    const onSaveConfirmReady = async (boxCount, palletCount, actualDeliveryDate) => {
        if (order.status === Order.StatusOrdered()) {
            await props.updateToNextState(order.orderNumber, Order.StatusDelivered(), {
                boxCount,
                palletCount,
                actualDeliveryDate,
                orderNumber: order.orderNumber,
            });
        } else {
            return Promise.reject(new Error('wrong state, should be ORDERED'));
        }
    };

    return (
        <React.Fragment key={order.orderNumber}>
            <TableRow className={classes.root} onClick={() => expandOrder()}>
                <TableCell>
                    {order.orderNumber}
                    &nbsp;
                    {order.invoiceNumbers.length > 0 && <Chip size="small" color={'primary'} label={'Lasku'} />}
                </TableCell>
                <TableCell>{moment(order.orderSent).format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>{moment(order.deliveryTime).format('DD.MM.YYYY')}</TableCell>
                <TableCell>
                    {order.customer.name} /
                    {order.orderItems.map((item, ind) => {
                        return ind < 2 ? (
                            <span key={item.id}>
                                {item.location ? item.location.name : item.deliveryRecipient}&nbsp;
                            </span>
                        ) : (
                            '.'
                        );
                    })}
                </TableCell>
                <TableCell>
                    {Object.keys(order.capacityReservations).map((resPerWeek) => {
                        return (
                            <div key={resPerWeek}>
                                wk{resPerWeek}:&nbsp;
                                {order.capacityReservations[resPerWeek].reduce(
                                    (acc, curr) => acc + curr.reservedCapacity,
                                    0
                                )}
                            </div>
                        );
                    })}
                    {order.capacityReserved}
                </TableCell>
                {/*<TableCell>N/A</TableCell>*/}
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {!orderFull && <Spinner />}
                        {orderFull && (
                            <Box margin={1}>
                                <Grid item xs={12} className={classes.buttonRow}>
                                    {poBlobUrl.length > 0 && (
                                        <Button
                                            href={poBlobUrl}
                                            variant="contained"
                                            color="primary"
                                            download={`ostotilaus_${order.orderNumber}.xlsx`}>
                                            {t('production.purchaseOrder')}
                                        </Button>
                                    )}
                                    {order.invoiceNumbers.length > 0 && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            name="btn-open-invoices"
                                            onClick={() => {
                                                props.history.push(`/invoices?order=${order.orderNumber}`);
                                            }}>
                                            <i className="fas fa-file-invoice-dollar"></i>
                                            &nbsp;{t('production.openInvoice')}
                                        </Button>
                                    )}
                                    &nbsp;
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        name="btn-next-state"
                                        onClick={toNextState}
                                        disabled={!isAllowed()}
                                        startIcon={getButtonIcon()}>
                                        {getButtonLabel()}
                                    </Button>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} sm={6}>
                                        <strong>{t('general.deliveryDate')}</strong>
                                        <br />
                                        {moment(orderFull.deliveryTime).isValid() && (
                                            <span>{moment(orderFull.deliveryTime).format('DD.MM.YYYY')}</span>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <strong>{t('parameterCategories.DeliveryTerms')}</strong>
                                        <br />
                                        {orderFull.deliveryTerms}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <strong>{t('parameterCategories.DeliveryMethod')}</strong>
                                        <br />
                                        {orderFull.deliveryMethod}
                                    </Grid>
                                    {orderFull.deliveryInfo && (
                                        <Grid item xs={12}>
                                            <strong>{t('production.delivered')}</strong>
                                            <br />
                                            {moment(orderFull.deliveryInfo.actualDeliveryDate).format('DD.MM.YYYY')}
                                            <br />
                                            {t('production.boxes')} {orderFull.deliveryInfo.boxCount}
                                            <br />
                                            {t('production.pallets')} {orderFull.deliveryInfo.palletCount}
                                        </Grid>
                                    )}
                                </Grid>
                                <Typography variant="h6" gutterBottom component="div">
                                    {t('order.orderedProducts')}
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('general.location')}</TableCell>
                                            <TableCell>{t('general.product')}</TableCell>
                                            <TableCell>{t('pcs')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderFull.orderItems.map((orderitem) => (
                                            <React.Fragment key={orderitem.id}>
                                                {orderitem.orderedFilters.map((ordfilt) => (
                                                    <TableRow key={ordfilt.id}>
                                                        <TableCell component="th" scope="row">
                                                            {orderitem.location
                                                                ? orderitem.location.name
                                                                : orderitem.deliveryRecipient}
                                                        </TableCell>
                                                        <TableCell>{ordfilt.product.productName}</TableCell>
                                                        <TableCell>{ordfilt.count}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
            <ConfirmationDialog
                open={confirmOpen}
                confirm={onConfirm}
                cancel={onCancelConfirm}
                confirmText={confirmBtnText}>
                {confirmText}
            </ConfirmationDialog>
            <ProductionComplateDialog
                open={confirmOrderReady}
                cancel={() => setConfirmOrderReady(false)}
                save={onSaveConfirmReady}
            />
        </React.Fragment>
    );
}

export default function ProductionPlan(props) {
    const classes = useStyles();
    const nbrOfWeeksToShow = 5;
    const weeksToShow = [];
    const { t } = useTranslation();

    if (!props.active) return null;
    if (!props.capacityReservations) return null;
    if (!props.productionOrders) return null;

    let runningDate = moment().add(1, 'weeks');
    let count = 0;
    do {
        weeksToShow.push({
            weekLabel: `WK ${runningDate.isoWeek()} / ${runningDate.year()}`,
            orders: props.productionOrders
                .filter((o) => o.deliveryTime && o.deliveryTime.startsWith('20')) // filter out most of the invalid dates in the deliveryTime field
                .filter((o) => moment(o.deliveryTime).isSame(runningDate, 'week'))
                .map((o) => {
                    return {
                        ...o,
                        capacityReservations: _.groupBy(
                            props.capacityReservations.filter((r) => r.orderNumber === o.orderNumber),
                            (r) => {
                                return r.dateOfReservation.isoWeek();
                            }
                        ),
                    };
                }),
        });
        runningDate.add(1, 'weeks');
        count++;
    } while (count < nbrOfWeeksToShow);

    return (
        <TableContainer component={Paper}>
            {weeksToShow.map((w) => (
                <div key={w.weekLabel}>
                    <h5 className={classes.weekheader}>{w.weekLabel}</h5>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>{t(column.label)}</TableCell>
                                ))}
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {w.orders.map((o) => (
                                <OrderRow row={o} key={o.orderNumber} />
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ))}
        </TableContainer>
    );
}
