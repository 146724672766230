import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NumericInputElement, { TypeOnBlur } from '../common/numericinput';
import { formatCurrency } from '../common/common';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { verifyAndReturnDecimal } from '../common/common';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    filterCountEdit: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'table-row',
        },
    },
    sectionMobile: {
        display: 'table-row',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function FilterOrderRowEdit(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const saving = props.saving;

    const getRowClass = () => {
        if (props.selected) return 'highlight';
        return '';
    };

    const filterCountChanged = async (id, value) => {
        await props.filterCountChanged(props.filterOrder.id, value);
    };

    const onChange = (e) => {
        const fo = cloneDeep(props.filterOrder);
        fo[e.target.name] = e.target.value;
        props.filterOrderChanged(fo);
    };

    const closeEdit = () => {
        props.closeRowEdit();
    };

    const handlePriceChange = (e) => {
        const value = verifyAndReturnDecimal(e.target.value);
        if (value && value >= 0) {
            const fo = cloneDeep(props.filterOrder);
            fo.unitPriceWithoutVat = value;
            props.filterOrderChanged(fo);
        }
    };

    return (
        <Fragment>
            <tr className={getRowClass() + ' ' + classes.sectionDesktop}>
                <td></td>
                <td>
                    {!props.priceOnly && (
                        <div>
                            <TextField
                                required
                                error={false}
                                name="machineName"
                                label={t('location.machine')}
                                className={classes.textField}
                                margin="normal"
                                value={props.filterOrder.machineName}
                                onChange={onChange}
                            />
                            <TextField
                                required
                                error={false}
                                name="machineLocation"
                                label={t('location.position')}
                                className={classes.textField}
                                margin="normal"
                                value={props.filterOrder.machineLocation}
                                onChange={onChange}
                            />
                            <TextField
                                required
                                error={false}
                                name="machineArea"
                                label={t('location.serviceArea')}
                                className={classes.textField}
                                margin="normal"
                                value={props.filterOrder.machineArea}
                                onChange={onChange}
                            />
                            <React.Fragment>
                                <br />
                                {`${t('order.palletGroup')}: ${props.filterOrder.palletInformation} `}
                            </React.Fragment>
                        </div>
                    )}
                </td>

                <td>
                    {props.filterOrder.product.productName}
                    {props.filterOrder.filterAdditionalInfo && (
                        <span>
                            <br />
                            <em>{props.filterOrder.filterAdditionalInfo}</em>
                        </span>
                    )}
                    <br />
                    <Button color="primary" variant="contained" onClick={closeEdit}>
                        {t('buttons.close')}
                    </Button>
                </td>
                <td className={'filter-count-' + props.filterOrder.id}>
                    {!props.priceOnly && (
                        <NumericInputElement
                            id={null}
                            value={props.filterCount}
                            onChange={filterCountChanged}
                            type={TypeOnBlur}
                            min={1}
                            saving={saving}
                        />
                    )}
                </td>
                <td>
                    <TextField
                        name={`filterPrice-${props.filterOrder.id}`}
                        defaultValue={props.filterOrder.unitPriceRounded()}
                        onBlur={(e) => handlePriceChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€/kpl</InputAdornment>,
                        }}
                    />
                </td>
                <td>{formatCurrency(props.filterOrder.totalPriceWithoutVat)}</td>
            </tr>

            <tr className={getRowClass() + ' ' + classes.sectionMobile}>
                <td></td>
                <td>
                    <div>
                        <div>{props.filterOrder.product.productName}</div>
                        {!props.priceOnly && (
                            <React.Fragment>
                                <TextField
                                    required
                                    error={false}
                                    name="machineName"
                                    label={t('location.machine')}
                                    className={classes.textField}
                                    margin="normal"
                                    value={props.filterOrder.machineName}
                                    onChange={onChange}
                                />
                                <TextField
                                    required
                                    error={false}
                                    name="machineLocation"
                                    label={t('location.position')}
                                    className={classes.textField}
                                    margin="normal"
                                    value={props.filterOrder.machineLocation}
                                    onChange={onChange}
                                />
                                <TextField
                                    required
                                    error={false}
                                    name="machineArea"
                                    label={t('location.serviceArea')}
                                    className={classes.textField}
                                    margin="normal"
                                    value={props.filterOrder.machineArea}
                                    onChange={onChange}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    {!props.priceOnly && (
                        <NumericInputElement
                            id={null}
                            value={props.filterCount}
                            onChange={filterCountChanged}
                            type={TypeOnBlur}
                            min={1}
                            saving={saving}
                        />
                    )}
                    <TextField
                        name={`filterPrice-${props.filterOrder.id}`}
                        defaultValue={props.filterOrder.unitPriceRounded()}
                        onBlur={(e) => handlePriceChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€/kpl</InputAdornment>,
                        }}
                    />
                    <br />
                    {t('order.totalShort')} {formatCurrency(props.filterOrder.totalPriceWithoutVat)}
                    <br />
                    {!props.priceOnly && (
                        <React.Fragment>
                            {`${t('order.palletGroup')}: ${props.filterOrder.palletInformation} `}
                            <br />
                        </React.Fragment>
                    )}
                    <Button color="primary" variant="contained" onClick={closeEdit}>
                        {t('buttons.close')}
                    </Button>
                </td>
            </tr>
        </Fragment>
    );
}
