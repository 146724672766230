import React from 'react';
import {
    TableRow,
    TableCell,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Button,
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatCurrency } from '../common/common';
import moment from 'moment';
import { some } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    bolded: {
        fontWeight: 'bold',
    },
    table: {
        width: '100%',
    },
    orderRow: {
        cursor: 'pointer',
    },
    receiptIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
}));

function OrderHistoryRowInternal(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const orderInvoiced = () => {
        return some(props.orderGroup, function (ord) {
            return ord.invoiceNumbers.length > 0;
        });
    };

    const orderLocationNames = () => {
        if (props.orderGroup[0].locName && props.orderGroup[0].locName.length > 0) {
            return [props.orderGroup[0].locName, props.orderGroup.length > 1 ? '...' : ''].join('');
        }
        return [props.orderGroup[0].deliveryRecipient, props.orderGroup.length > 1 ? '...' : ''].join('');
    };

    const orderTotalPrice = () => {
        return formatCurrency(props.orderGroup.reduce((prev, cur) => prev + cur.orderPriceWithoutVat, 0));
    };

    if (!props.show) return null;

    if (props.isDesktop) {
        return (
            <TableRow
                className={classes.orderRow}
                key={props.orderNumber}
                onClick={() => props.openOrder(props.orderNumber)}>
                <TableCell>
                    {moment(props.orderGroup[0].orderSendDate).format('DD.MM.YYYY')}&nbsp;
                    {moment(props.orderGroup[0].orderSendDate).format('HH:mm')}
                </TableCell>
                <TableCell>
                    {props.orderNumber}{' '}
                    {!props.customerui && orderInvoiced() && (
                        <ReceiptIcon className={classes.receiptIcon} name="invoiced" />
                    )}
                </TableCell>
                <TableCell>
                    {props.orderGroup[0].customerName}, {orderLocationNames()}
                </TableCell>
                <TableCell>{props.orderGroup[0].orderer}</TableCell>
                {props.allowPricing && <TableCell>{orderTotalPrice()}</TableCell>}
                {!props.customerui && <TableCell>{props.orderGroup[0].salesPortfolio.name}</TableCell>}
            </TableRow>
        );
    } else {
        return (
            <Grid item xs={12} key={props.orderNumber}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid item>
                            {moment(props.orderGroup[0].orderSendDate).format('DD.MM.YYYY HH:mm')}
                            &nbsp;&nbsp;
                            {!props.customerui && orderInvoiced() && (
                                <ReceiptIcon className={classes.receiptIcon} name="invoiced" />
                            )}
                            &nbsp;
                            {props.orderGroup[0].customerName}
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <Grid item xs={6} className={classes.bolded}>
                                {t('general.orderNumber')}
                            </Grid>
                            <Grid item xs={6}>
                                {props.orderNumber}
                            </Grid>

                            <Grid item xs={6} className={classes.bolded}>
                                {t('general.location')}
                            </Grid>
                            <Grid item xs={6}>
                                {orderLocationNames()}
                            </Grid>

                            <Grid item xs={6} className={classes.bolded}>
                                {t('general.orderer')}
                            </Grid>
                            <Grid item xs={6}>
                                {props.orderGroup[0].orderer}
                            </Grid>

                            {props.allowPricing && (
                                <>
                                    <Grid item xs={6} className={classes.bolded}>
                                        {t('invoice.totalPriceVatZero')}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {orderTotalPrice()}
                                    </Grid>
                                </>
                            )}

                            {!props.customerui && (
                                <>
                                    <Grid item xs={6} className={classes.bolded}>
                                        {t('general.portfolio')}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {props.orderGroup[0].salesPortfolio.name}
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    className={classes.openButton}
                                    onClick={() => props.openOrder(props.orderNumber)}
                                    key={props.orderNumber}>
                                    {t('buttons.open')}
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    }
}
const shouldSkipRender = (prevProps, nextProps) => {
    if (prevProps.orderGroup.length !== nextProps.orderGroup.length) return false;
    if (prevProps.orderNumber !== nextProps.orderNumber) return false;
    if (prevProps.isDesktop !== nextProps.isDesktop) return false;
    if (prevProps.show !== nextProps.show) return false;

    return true;
};

const OrderHistoryRow = React.memo(OrderHistoryRowInternal, shouldSkipRender);

export default OrderHistoryRow;

OrderHistoryRow.propTypes = {
    orderNumber: PropTypes.string.isRequired,
    openOrder: PropTypes.func.isRequired,
    orderGroup: PropTypes.array.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    customerui: PropTypes.bool,
    allowPricing: PropTypes.bool,
    show: PropTypes.bool.isRequired,
};
