import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import SaveButton from '../common/savebutton';
import ObjectIdGenerator from '../modules/objectidgenerator';
import FilterProduct from '../domain/filterproduct';
import { saveProductRequest } from '../modules/productdata';
import Alert from '@material-ui/lab/Alert';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    marginTop: {
        marginTop: '1em',
    },
}));

export default function NewProductRequiredView(props) {
    const classes = useStyles();
    const [saving, setSaving] = React.useState(false);
    const [name, setName] = React.useState('');
    const products = useSelector((state) => state.productdata.filterProducts);
    const [duplicate, setDuplicate] = React.useState(false);
    const [newlyCreatedProductId, setNewlyCreatedProductId] = React.useState(null);
    const dispatch = useDispatch();
    const selected = props.selected;
    const { t } = useTranslation();

    useEffect(() => {
        if (newlyCreatedProductId && products.find((p) => p.id === newlyCreatedProductId)) {
            setSaving(false);
            selected(newlyCreatedProductId);
            setNewlyCreatedProductId(null);
        }
    }, [products, newlyCreatedProductId, selected]);

    const onChange = (e) => {
        switch (e.target.name) {
            case 'name':
                setName(e.target.value);
                break;
            default:
                break;
        }
        if (e.target.value && e.target.value.length > 3) {
            checkDuplicate(e.target.value);
        }
    };

    const checkDuplicate = (name) => {
        if (products.filter((p) => p.productName === name).length > 0) {
            setDuplicate(true);
        } else {
            setDuplicate(false);
        }
    };

    const onCancel = () => {
        props.cancel();
    };

    const onSave = async () => {
        setSaving(true);
        const id = await ObjectIdGenerator.newId();
        const prod = new FilterProduct();
        prod.productCode = '0';
        prod.id = id;
        prod.productName = name;
        prod.status = FilterProduct.StatusDraftRequest();
        saveProductRequest(prod)(dispatch);
        setNewlyCreatedProductId(prod.id);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                {t('product.giveNameForProductRequest')}{' '}
                {props.productRequestHint && props.productRequestHint.length > 0 && (
                    <span>
                        {t('product.youCanUseTheOriginalProductName')}:{' '}
                        <Button name="btn-product-hint" onClick={() => setName(props.productRequestHint)}>
                            {props.productRequestHint}
                        </Button>
                    </span>
                )}
            </Grid>
            {duplicate && (
                <Grid item xs={12}>
                    <Alert severity="error">{t('product.duplicateProductNameExists')}</Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    required
                    id="name"
                    name="name"
                    label={t('general.name')}
                    value={name}
                    className={classes.textField}
                    margin="normal"
                    onChange={onChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={1} className={classes.marginTop}>
                    <Grid item>
                        <SaveButton
                            saving={saving}
                            disabled={saving || name.length < 3 || duplicate}
                            onSubmit={onSave}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="default" name="cancel" onClick={onCancel} disabled={saving}>
                            {t('buttons.cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
