import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash';
import { loadCapacityAllocations } from '../modules/prodplanningdata';

const colors = ['#1A9C9F', '#1A5A9F', '#376FAB', '#9B70C3', '#C370C1', '#B75383', '#B7535C', '#B7AF53', '#AF53B7'];
const colorFree = '#FFFFFF';
const colorUrgent = '#FD6E3D';
const colorUrgentFree = '#FFCFBF';

const chartLabelTooltip = (tooltipItem, tooltipData, t) => {
    const tooltip = tooltipData[tooltipItem.datasetIndex];
    return `${tooltip.label}: ${tooltip.reserved[tooltipItem.index]} / ${tooltip.allocated[tooltipItem.index]} ${t(
        'pcs'
    )}`;
};

const chartTitleTooltip = (tooltipItem, tooltipData) => {
    return tooltipData[tooltipItem[0].datasetIndex].label;
};

const chartTooltipFooter = (tooltipItem, tooltipData, t) => {
    const tooltip = tooltipData[tooltipItem[0].datasetIndex];
    return `${t('production.capacity')} ${tooltip.dailyTotalReserved[tooltipItem[0].index]} / ${
        tooltip.dailyTotalAllocated[tooltipItem[0].index]
    } ${t('pcs')} `;
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
    },
}));

const ProductionCapacityReport = (props) => {
    const { t } = useTranslation();
    const active = props.active;
    const plotWidth = window.innerWidth - 100;
    const yAxisTitle = t('production.capacity') + ' ' + t('pcs');
    const xAxisTitle = t('reports.day');
    const classes = useStyles();
    const allocationsByWeek = useSelector((state) => state.prodplanningdata.capacityAllocationsByWeek);
    const loadingAllocations = useSelector((state) => state.prodplanningdata.loadingCapacityData);
    const reservations = useSelector((state) => state.prodplanningdata.capacityReservations);
    const portfolioDescriptions = useSelector((state) => state.portfoliodata.portfolioDescriptions);
    const [chartData, setChartData] = useState(null);
    const [chartTooltipData, setChartTooltipData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        function generatePortfolioUsageData(reservationsPortfolio, color, colorAvail, order, portfolio, start, end) {
            const allocations = flatten(
                Object.keys(allocationsByWeek).map((k) => {
                    return allocationsByWeek[k];
                })
            );
            const runningDate = moment(start);
            const dataset = {
                label: portfolio.name ? portfolio.name : '-',
                data: [],
                backgroundColor: color,
                order,
                borderColor: 'black',
                borderWidth: { bottom: 1, left: 2, right: 2, top: 1 },
            };
            const tooltip = {
                label: dataset.label,
                reserved: [],
                allocated: [],
                dailyTotalReserved: [],
                dailyTotalAllocated: [],
            };
            const datasetAvail = {
                label: portfolio.name ? portfolio.name + ' ' + t('reports.free') : '-',
                data: [],
                backgroundColor: colorAvail,
                order,
                borderColor: 'black',
                borderWidth: { bottom: 1, left: 2, right: 2, top: 1 },
            };
            do {
                const usage = reservationsPortfolio
                    .filter((r) => moment(r.dateOfReservation).isSame(runningDate, 'day'))
                    .reduce((acc, curr) => {
                        return acc + curr.reservedCapacity;
                    }, 0);

                const runningWeekAllocations = allocations.find(
                    (a) => a.portfolioId === portfolio.id && a.week === moment(runningDate).week()
                );
                let allocated = runningWeekAllocations ? runningWeekAllocations.allocatedCapacity / 5 : 0;
                let allocationsAvailable = allocated - usage;
                if (runningDate.day() === 0 || runningDate.day() === 6 || allocationsAvailable < 0) {
                    allocationsAvailable = 0;
                }
                dataset.data.push(usage);
                datasetAvail.data.push(allocationsAvailable);
                tooltip.reserved.push(usage);
                tooltip.allocated.push(allocated);
                runningDate.add(1, 'day');
            } while (runningDate.isSameOrBefore(end, 'day'));

            return {
                datasets: [dataset, datasetAvail],
                tooltips: [tooltip, tooltip], //twice on purpose
            };
        }

        if (
            !loadingAllocations &&
            allocationsByWeek &&
            Object.keys(allocationsByWeek).length > 0 &&
            reservations &&
            active &&
            portfolioDescriptions
        ) {
            const start = moment().subtract(1, 'week');
            const runningDate = moment(start);
            const end = moment().add(2, 'month');
            const labels = [];
            const tooltips = [];
            const datasets = [];
            let ind = 0;
            let portfolioInd = 0;
            let dailyTotalReserved = [];
            let dailyTotalAllocated = [];

            do {
                labels[ind] = runningDate.date();
                dailyTotalReserved[ind] = 0;
                dailyTotalAllocated[ind] = 0;
                ind++;
                runningDate.add(1, 'day');
            } while (runningDate.isSameOrBefore(end, 'day'));

            for (const p of portfolioDescriptions) {
                var dataSetsArr = generatePortfolioUsageData(
                    reservations.filter((r) => r.portfolioId === p.id),
                    colors[portfolioInd],
                    colorFree,
                    portfolioInd + 2,
                    p,
                    start,
                    end
                );
                datasets.push.apply(datasets, dataSetsArr.datasets);
                tooltips.push.apply(tooltips, dataSetsArr.tooltips);
                const tooltipData = dataSetsArr.tooltips[0];
                for (let i = 0; i < tooltipData.reserved.length; i++) {
                    dailyTotalReserved[i] += tooltipData.reserved[i];
                    dailyTotalAllocated[i] += tooltipData.allocated[i];
                }
                portfolioInd++;
            }

            var urgentDataSets = generatePortfolioUsageData(
                reservations.filter((r) => r.portfolioId === 0),
                colorUrgent,
                colorUrgentFree,
                portfolioInd + 2,
                { id: 0, name: t('reports.urgent') },
                start,
                end
            );
            datasets.push.apply(datasets, urgentDataSets.datasets);
            tooltips.push.apply(tooltips, urgentDataSets.tooltips);

            const tooltipDataUrgent = urgentDataSets.tooltips[0];
            for (let i = 0; i < tooltipDataUrgent.reserved.length; i++) {
                dailyTotalReserved[i] += tooltipDataUrgent.reserved[i];
                dailyTotalAllocated[i] += tooltipDataUrgent.allocated[i];
            }

            tooltips.forEach((tip) => {
                tip.dailyTotalReserved = dailyTotalReserved;
                tip.dailyTotalAllocated = dailyTotalAllocated;
            });

            setChartData({ labels, datasets });
            setChartTooltipData(tooltips);
        }
        if (active && !loadingAllocations && (!allocationsByWeek || Object.keys(allocationsByWeek).length === 0)) {
            dispatch(loadCapacityAllocations());
        }
    }, [allocationsByWeek, reservations, active, portfolioDescriptions, t, loadingAllocations, dispatch]);

    if (!chartData) return null;
    if (!active) return null;

    return (
        <Grid container className={classes.root}>
            {chartData != null && (
                <Bar
                    data={chartData}
                    width={plotWidth}
                    height={500}
                    options={{
                        scales: {
                            xAxes: [
                                {
                                    stacked: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: xAxisTitle,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    stacked: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: yAxisTitle,
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: (value, index, values) => {
                                            return value.toLocaleString();
                                        },
                                    },
                                },
                            ],
                        },
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    return chartLabelTooltip(tooltipItem, chartTooltipData, t);
                                },
                                title: (tooltipItem) => {
                                    return chartTitleTooltip(tooltipItem, chartTooltipData);
                                },
                                footer: (tooltipItem) => {
                                    return chartTooltipFooter(tooltipItem, chartTooltipData, t);
                                },
                            },
                        },
                    }}
                />
            )}
        </Grid>
    );
};

export default ProductionCapacityReport;
