import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import FilterProduct from '../domain/filterproduct';
import { IsProductStandard } from '../common/productdataparse';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    warning: {
        color: 'orange',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '0.9em',
    },
    warningLegacy: {
        color: 'orange',
        verticalAlign: 'middle',
        display: 'inline-flex',
        fontSize: '1.25em',
    },
}));

const FilterProductWarning = ({ product }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {product.expired && (
                <Tooltip title={t('product.productRemovedFromCatalogWarning', { productName: product.productName })}>
                    <WarningIcon className={classes.warningLegacy} name="warningIcon" />
                </Tooltip>
            )}
            {!product.expired && product.productCode !== '0' && (
                <Tooltip title={t('product.tooOldProductWarning')}>
                    <WarningIcon className={classes.warningLegacy} name="warningIcon" />
                </Tooltip>
            )}
            {!product.expired && product.status === FilterProduct.StatusDraftRequest() && (
                <Tooltip title={t('product.draftProductWarning')}>
                    <WarningIcon className={classes.warningLegacy} name="warningIcon" />
                </Tooltip>
            )}
            {!product.expired && product.status === FilterProduct.StatusImported() && (
                <Tooltip title={t('product.temporaryProductFromImportWarning')}>
                    <WarningIcon className={classes.warningLegacy} name="warningIcon" />
                </Tooltip>
            )}
            {!product.expired && !IsProductStandard(product.productName) && (
                <Tooltip title={t('product.notStandardWarning')}>
                    <WarningIcon className={classes.warningLegacy} name="warningIcon" />
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default FilterProductWarning;
