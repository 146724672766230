import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ObjectIdGenerator from '../modules/objectidgenerator';
import { CircularProgress, Grid, TextField, makeStyles, Button, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowBackIos, Add, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ProductBundle, ProductBundleProduct } from '../domain/productbundle';
import { useHistory } from 'react-router-dom';
import FilterProductSelectDialog from '../location/filterproductselectdialog';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../common/confirmationdialog';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import DeleteButton from '../common/deletebutton';
import SaveButton from '../common/savebutton';
import { removeProductBundle, saveProductBundle, updateProductBundle } from '../modules/productdata';
import NumericInputElement from '../common/numericinput';
import { filter } from 'lodash';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    backArrow: {
        cursor: 'pointer',
    },
    withPadding: {
        padding: '1em',
    },
    prodListHeader: {
        marginTop: '0.5em',
    },
    addbutton: {
        marginBottom: '1em',
        marginTop: '0.5em',
    },
}));
export default function ProductBundleEditView(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { bundleId } = useParams();
    const allProducts = useSelector((state) => state.productdata.filterProducts);
    const allProductBundles = useSelector((state) => state.productdata.productBundles);
    const [bundleIdInt, setbundleIdInt] = useState(bundleId && bundleId !== 'new' ? parseInt(bundleId) : null);
    const [bundle, setBundle] = useState(null);
    const [productBundleNotFound, setProductBundleNotFound] = useState(false);
    const [showNewProdSelector, setShowNewProdSelector] = useState(false);
    const [productNameToRemove, setProductNameToRemove] = useState(null);
    const [productToRemove, setProductToRemove] = useState(null);
    const [removeProductDialogOpen, setRemoveProductDialogOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [deleting, SetDeleting] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        async function getId() {
            const id = await ObjectIdGenerator.newId();
            setbundleIdInt(id);
            const newBundle = new ProductBundle();
            newBundle.id = id;
            setBundle(newBundle);
        }
        // we're creating a new product bundle
        if (bundleId === 'new' && bundleIdInt === null) {
            getId();
        }
    }, [bundleId, bundleIdInt]);

    useEffect(() => {
        if (bundleId !== 'new' && allProductBundles && allProductBundles.length) {
            const prodBundle = allProductBundles.find((pb) => pb.id === bundleIdInt);
            if (!prodBundle) {
                setProductBundleNotFound(true);
                return;
            }
            setBundle(prodBundle);
        }
    }, [bundleId, bundleIdInt, allProductBundles]);
    //TODO
    //bundle usage
    //duplicate code check?

    const handleChange = (evt) => {
        const value = evt.target.value.replace(/[\t]/g, '');
        const name = evt.target.name;
        const newBundle = new ProductBundle(bundle);
        switch (name) {
            case 'bundleName':
                newBundle.name = value;
                break;
            case 'bundleCode':
                newBundle.code = value;
                break;
            default:
                break;
        }
        setBundle(newBundle);
    };

    const productCountChanged = (id, value) => {
        const newBundle = new ProductBundle(bundle);
        const bundleProduct = newBundle.products.find((p) => p.product.id === id);
        bundleProduct.productCount = value;
        setBundle(newBundle);
    };

    const close = () => {
        history.goBack();
    };

    const addNewProduct = () => {
        setShowNewProdSelector(true);
    };

    const onSelectNewProd = async (productId) => {
        setShowNewProdSelector(false);
        if (productId == null) return;

        if (bundle.products.find((p) => p.product.id === productId)) {
            toast.error(t('product.productIsAlreadyIncludedInProductBundle'), {
                autoClose: 5000,
                hideProgressBar: false,
            });
        } else {
            var newBundle = new ProductBundle(bundle);
            newBundle.products.push(
                new ProductBundleProduct(
                    allProducts.find((p) => p.id === productId),
                    1
                )
            );
            setBundle(newBundle);
            toast.info(t('product.productWasAddedToProductBundle'), {
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
    };

    const removeProduct = (prductId) => {
        const bundleProduct = bundle.products.find((p) => p.product.id === prductId);
        setProductNameToRemove(bundleProduct.product.productName);
        setProductToRemove(prductId);
        setRemoveProductDialogOpen(true);
    };

    const confirmRemoveProduct = () => {
        const newBundle = new ProductBundle(bundle);
        const newProduct = filter(newBundle.products, (p) => p.product.id !== productToRemove);
        newBundle.products = newProduct;
        setBundle(newBundle);
        setRemoveProductDialogOpen(false);
    };

    const cancelRemoveProduct = () => {
        setProductNameToRemove(null);
        setProductToRemove(null);
        setRemoveProductDialogOpen(false);
    };

    const isDataOk = () => {
        return bundle && bundle.name && bundle.name.length > 0 && bundle.code && bundle.code.length > 0;
    };

    const save = async () => {
        setSaving(true);
        if (bundleId === 'new') {
            if (!(await saveProductBundle(bundle)(dispatch))) {
                toast.error(t('product.savingProductBundleFailed'), {
                    autoClose: 5000,
                    hideProgressBar: false,
                });
            } else {
                history.replace(`/product/bundleedit/${bundle.id}`);
            }
        } else {
            if (!(await updateProductBundle(bundle)(dispatch))) {
                toast.error(t('product.savingProductBundleFailed'), {
                    autoClose: 5000,
                    hideProgressBar: false,
                });
            }
        }
        setSaving(false);
    };

    const confirmDelete = async () => {
        setConfirmDeleteOpen(false);
        SetDeleting(true);
        if (!(await removeProductBundle(bundleIdInt)(dispatch))) {
            toast.error(t('product.productBundleRemovalFailed'), {
                autoClose: 5000,
                hideProgressBar: false,
            });
        } else {
            toast.info(t('product.productBundleRemovalSucess'), {
                autoClose: 2000,
                hideProgressBar: true,
            });
            history.replace('/products/');
        }
        SetDeleting(true);
    };

    const cancelDelete = () => {
        setConfirmDeleteOpen(false);
    };
    //bundle not found case?

    return (
        <Grid container>
            <Grid container item>
                <h2>
                    <ArrowBackIos onClick={close} className={classes.backArrow} name="close" />
                    {bundleId === 'new' ? (
                        <span>{t('product.newProductBundle')}</span>
                    ) : (
                        <span>{t('product.productBundleEdit')}</span>
                    )}
                </h2>
            </Grid>
            {(!bundle || !allProductBundles) && (
                <Grid item className={classes.withPadding}>
                    <CircularProgress color={'secondary'} size={'2rem'} />
                </Grid>
            )}
            {productBundleNotFound && (
                <Grid item xs={12} className={classes.withPadding}>
                    <Alert severity="error">{t('general.fetchFailedRetry')}</Alert>
                </Grid>
            )}
            {bundle && (
                <Grid container item>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="name"
                            name="bundleName"
                            label={t('general.name')}
                            value={bundle.name}
                            className={classes.textField}
                            margin="normal"
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            required
                            id="code"
                            name="bundleCode"
                            label={t('product.code')}
                            value={bundle.code}
                            className={classes.textField}
                            margin="normal"
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item container>
                        <Grid item xs={12}>
                            <h5 className={classes.prodListHeader}>{t('general.products')}</h5>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={saving}
                                className={classes.addbutton}
                                startIcon={<Add />}
                                variant="contained"
                                color="primary"
                                name="btn-add-new-product"
                                onClick={addNewProduct}>
                                {t('general.product')}
                            </Button>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6}>
                                <strong>{t('general.product')}</strong>
                            </Grid>
                            <Grid item xs={4}>
                                <strong>{t('pcs')}</strong>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                        {bundle.products.map((p) => (
                            <Grid container key={p.product.id}>
                                <Grid item xs={6}>
                                    {p.product.productName}
                                </Grid>
                                <Grid item xs={4}>
                                    <NumericInputElement
                                        id={p.product.id}
                                        value={p.productCount}
                                        onChange={productCountChanged}
                                        min={1}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        name="removeProduct"
                                        onClick={() => removeProduct(p.product.id)}>
                                        <Delete />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}

            <FilterProductSelectDialog
                allowNewProd={false}
                title={t('product.addProduct')}
                show={showNewProdSelector}
                selected={onSelectNewProd}
            />

            <ConfirmationDialog
                open={removeProductDialogOpen}
                confirm={confirmRemoveProduct}
                cancel={cancelRemoveProduct}
                confirmText={t('buttons.remove')}>
                <span>{t('product.confirmRemoveProductName', { productName: productNameToRemove })}</span>
            </ConfirmationDialog>

            <ConfirmationDialog
                open={confirmDeleteOpen}
                confirm={confirmDelete}
                cancel={cancelDelete}
                confirmText={t('buttons.remove')}>
                <span>{t('product.confirmRemoveProductBundle')}</span>
            </ConfirmationDialog>

            <Footer>
                <Grid item>
                    <DeleteButton
                        disabled={deleting || saving || bundleId === 'new'}
                        onSubmit={() => setConfirmDeleteOpen(true)}
                        deleting={deleting}
                    />
                </Grid>
                <Grid item>
                    <SaveButton
                        disabled={saving || deleting || !isDataOk()}
                        hasChanges={false}
                        onSubmit={save}
                        saving={saving}
                    />
                </Grid>
            </Footer>
            <FooterPadding />
        </Grid>
    );
}
