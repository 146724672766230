import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export default function DeleteButton(props) {
    const disabled = props.disabled;
    const onSubmit = props.onSubmit;
    const deleting = props.deleting;
    const { t } = useTranslation();

    return (
        <Button
            variant="contained"
            color="secondary"
            name={props.name ? props.name : 'delete'}
            onClick={onSubmit}
            disabled={disabled}
            startIcon={deleting ? <CircularProgress size={'1rem'} /> : <DeleteIcon />}>
            {t('buttons.remove')}
        </Button>
    );
}
