import moment from 'moment';
import { monthNumberListToTextList } from '../common/common';
import { Portfolio } from './portfolio';

class LocationDescription {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.name = json.name;
            this.locationNumber = json.locationNumber;
            this.contactPersonName = json.contactPersonName;
            this.contactPersonTel = json.contactPersonTel;
            this.additionalInfo = json.additionalInfo;
            this.customer = json.customer;
            this.deliveryCustomer = json.deliveryCustomer;
            this.latestOrder = moment(json.latestOrder);
            this.usesRemovedProducts = json.usesRemovedProducts;
            this.reminderMonthsArr = monthNumberListToTextList(json.reminderMonths);
            this.updateDate = moment(json.updateDate);
            this.status = json.status;
            this.portfolio = new Portfolio(json.portfolio);
            this.businessId = json.businessId;
        }
    }
}

export default LocationDescription;
