let CONFIG = {
    backendUrl: '',
    frontendUrl: '',
    frontendUrlCustomer: '',
    wsUrl: '',
    VATFinland: 24.0,
    loginUrl: 'api/login',
    logoutUrl: 'api/logout',
    loginRefreshUrl: 'api/login/refresh',
    companyName: 'Vado Oy',
    companyStreetAddr: 'Hitsaajankatu 22 opus 2',
    companyPostNro: '00810',
    companryCity: 'Helsinki',
    companyBIS: '2185741-7',
    companyEmail: 'info@vado.fi',
    customerUi: false,
    axiosWithCred: true,
    defaultSalesAccountNumber: 3000,
    features: {
        debugUi: false,
    },
    postalCodeApiUrl: '',
};

if (process.env.REACT_APP_STAGE === 'test') {
    CONFIG.backendUrl = 'https://apiv3.sleekcrm.net/';
    CONFIG.frontendUrl = 'https://test.sleekcrm.net/';
    CONFIG.frontendUrlCustomer = 'https://tilaukset.test.sleekcrm.net/';
    CONFIG.wsUrl = 'wss://apiv3ws.sleekcrm.net/';
    CONFIG.features.debugUi = true;
    CONFIG.postalCodeApiUrl = 'https://postalsearch.sleekcrm.net/postalcode';
} else if (process.env.REACT_APP_STAGE === 'test-ee') {
    CONFIG.backendUrl = 'https://api-eev3.sleekcrm.net/';
    CONFIG.frontendUrl = 'https://ee.sleekcrm.net/';
    CONFIG.frontendUrlCustomer = 'https://tilaukset.ee.sleekcrm.net/';
    CONFIG.wsUrl = 'wss://api-eev3ws.sleekcrm.net/';
    CONFIG.features.debugUi = true;
    CONFIG.postalCodeApiUrl = 'https://postalsearch.sleekcrm.net/postalcode';
} else if (process.env.REACT_APP_STAGE === 'production') {
    CONFIG.backendUrl = 'https://apiv3.myynnintuki.net/';
    CONFIG.frontendUrl = 'https://www.myynnintuki.net/';
    CONFIG.frontendUrlCustomer = 'https://tilaukset.myynnintuki.net/';
    CONFIG.wsUrl = 'wss://apiv3ws.myynnintuki.net/';
    CONFIG.postalCodeApiUrl = 'https://postalsearch.myynnintuki.net/postalcode';
} else if (process.env.REACT_APP_STAGE === 'production-ee') {
    CONFIG.backendUrl = 'https://api-eev3.myynnintuki.ee/';
    CONFIG.frontendUrl = 'https://www.myynnintuki.ee/';
    CONFIG.frontendUrlCustomer = 'https://tilaukset.myynnintuki.ee/';
    CONFIG.wsUrl = 'wss://api-eev3ws.myynnintuki.ee/';
    CONFIG.postalCodeApiUrl = 'https://postalsearch.myynnintuki.net/postalcode';
} else if (process.env.REACT_APP_STAGE === 'dev') {
    //CONFIG.backendUrl = 'https://apiv3.sleekcrmdev.net/';
    CONFIG.backendUrl = 'http://localhost:5001/';
    CONFIG.frontendUrl = 'http://localhost:3000/';
    CONFIG.frontendUrlCustomer = 'http://localhost:3000/';
    CONFIG.axiosWithCred = false;
    CONFIG.wsUrl = 'wss://apiv3ws.sleekcrmdev.net/';
    CONFIG.features.debugUi = true;
    CONFIG.postalCodeApiUrl = 'https://postalsearch.sleekcrmdev.net/postalcode';
} else if (process.env.REACT_APP_STAGE === 'dev-aws') {
    CONFIG.backendUrl = 'https://apiv3.sleekcrmdev.net/';
    CONFIG.frontendUrl = 'https://sleekcrmdev.net/';
    CONFIG.frontendUrlCustomer = 'http2://tilaukset.sleekcrmdev.net/';
    CONFIG.wsUrl = 'wss://apiv3ws.sleekcrmdev.net/';
    CONFIG.features.debugUi = true;
    CONFIG.postalCodeApiUrl = 'https://postalsearch.sleekcrmdev.net/postalcode';
} else if (process.env.REACT_APP_STAGE === 'dev-aws-ee') {
    CONFIG.backendUrl = 'https://api-eev3.sleekcrmdev.net/';
    CONFIG.frontendUrl = 'https://ee.sleekcrmdev.net/';
    CONFIG.frontendUrlCustomer = 'https://tilaukset.sleekcrmdev.net/';
    CONFIG.wsUrl = 'wss://api-eev3ws.sleekcrmdev.net/';
    CONFIG.features.debugUi = true;
    CONFIG.postalCodeApiUrl = 'https://postalsearch.sleekcrmdev.net/postalcode';
} else {
    CONFIG.backendUrl = '';
    CONFIG.axiosWithCred = false;
    CONFIG.wsUrl = 'ws://localhost:1234/';
}

if (process.env.REACT_APP_TARGET === 'customerui') {
    CONFIG.loginUrl += '/customer';
    CONFIG.loginRefreshUrl += '/customer';
    CONFIG.customerUi = true;
}

export const isCustomerUi = process.env.REACT_APP_TARGET === 'customerui';

export default CONFIG;
