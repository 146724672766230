import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
}));

export default function ChangePortfolioDialog(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [newPortfolioId, setNewPortfolioId] = useState(0);
    const portfolios = props.portfolios;
    const { t } = useTranslation();

    const cancel = () => {
        props.cancel();
    };
    const execute = () => {
        props.changePortfolio(newPortfolioId);
        props.cancel();
    };
    const handleChange = (event) => {
        setNewPortfolioId(parseInt(event.target.value));
    };

    return (
        <Dialog
            onClose={cancel}
            aria-labelledby="simple-dialog-title"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                <FormControl className={classes.select}>
                    <InputLabel id="customership-status-label">{t('customer.moveCustomerToPortfolio')}</InputLabel>
                    <NativeSelect
                        required
                        labelid="portfolio-selector"
                        id="portfolio-select"
                        name="portfolio-select"
                        value={newPortfolioId}
                        onChange={handleChange}>
                        <option value={0}>{t('general.select')}</option>
                        {portfolios &&
                            portfolios.length > 0 &&
                            portfolios.map((p) => (
                                <option key={p.id} value={p.id}>
                                    {p.name}
                                </option>
                            ))}
                    </NativeSelect>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={newPortfolioId === props.currentPortfolioId || !newPortfolioId}
                    name="btn-execute-change"
                    onClick={execute}>
                    {t('buttons.move')}
                </Button>
                <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    name="btn-cancel"
                    onClick={cancel}>
                    {t('buttons.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ChangePortfolioDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    changePortfolio: PropTypes.func.isRequired,
    currentPortfolioId: PropTypes.number.isRequired,
    portfolios: PropTypes.array.isRequired,
};
