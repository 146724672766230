import FilterOrder from './filterorder';
import Location from './location';
import Customer from './customer';
import Address from './address';
import ElectronicInvoiceInfo from './electronicinvoiceinfo';
import i18next from 'i18next';

function translate(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

class ProductionOrder {
    constructor(json) {
        if (json != null) {
            this.orderNumber = json.orderNumber;
            this.status = json.status;
            this.orderItems = [];
            if (json.orderItems != null && json.orderItems.length > 0) {
                for (let orderItem of json.orderItems) {
                    this.orderItems.push(new ProductionOrderItem(orderItem));
                }
            }
            if (json.customer != null) {
                this.customer = new Customer(json.customer);
            }
            this.orderTotalPrice = json.orderTotalPrice;
            this.orderTotalPriceWithoutVat = json.orderTotalPriceWithoutVat;
            this.orderTotalVatAmount = json.orderTotalVatAmount;
            this.deliveryTime = json.deliveryTime;
            this.deliveryTerms = json.deliveryTerms;
            this.ordererName = json.ordererName;
            this.ordererTel = json.ordererTel;
            this.contractNumber = json.contractNumber;
            this.deliveryRecipient = json.deliveryRecipient;
            if (json.deliveryAddress) this.deliveryAddress = new Address(json.deliveryAddress);
            this.deliveryCustomerNumber = json.deliveryCustomerNumber;
            this.billingResponsible = json.billingResponsible;
            if (json.billingAddress) this.billingAddress = new Address(json.billingAddress);
            if (json.electronicInvoiceAddress)
                this.electronicInvoiceAddress = new ElectronicInvoiceInfo(json.electronicInvoiceAddress);
            this.billingResponsibleBusinessId = json.billingResponsibleBusinessId;
            this.billingResponsibleCustomerNumber = json.billingResponsibleCustomerNumber;
            this.billingTerms = json.billingTerms;
            this.orderSent = json.orderSent;
            this.orderForm = json.orderForm;
            this.reference = json.reference;
            this.confirmationEmail = json.confirmationEmail;
            this.orderSender = json.orderSender;
            this.portfolioId = json.portfolioId;
            this.maintenanceArea = json.maintenanceArea;
            this.packingInstructions = json.packingInstructions;
            this.invoiceNumbers = json.invoiceNumbers;
            this.deliveryMethod = json.deliveryMethod;
            this.deliveryInfo = json.deliveryInfo;
            this.emailInvoiceAddress = json.emailInvoiceAddress;
        }
    }
    static OrderStatusOrdered() {
        return 4;
    }
    static OrderStatusInProduction() {
        return 5;
    }
    static OrderStatusPackaging() {
        return 6;
    }
    static OrderStatusInDispatching() {
        return 7;
    }
    static OrderStatusDelivered() {
        return 8;
    }
    orderStatusText() {
        switch (this.status) {
            case ProductionOrder.OrderStatusOrdered():
                return translate('general.ordered');
            case ProductionOrder.OrderStatusInProduction():
                return translate('general.inProduction');
            case ProductionOrder.OrderStatusPackaging():
                return translate('general.inPackaging');
            case ProductionOrder.OrderStatusInDispatching():
                return translate('general.inDispatching');
            case ProductionOrder.OrderStatusDelivered():
                return translate('general.delivered');
            default:
                return '';
        }
    }
    getDeliveryInfo() {
        return this.deliveryAddress != null
            ? `${this.deliveryRecipient}, ${this.deliveryAddress.streetAddress}, ${this.deliveryAddress.zipCode} ${this.deliveryAddress.city}`
            : null;
    }
    getBillingAddressInfo() {
        return this.billingAddress != null
            ? `${this.billingResponsible}, ${this.billingAddress.streetAddress}, ${this.billingAddress.zipCode} ${this.billingAddress.city}`
            : null;
    }
    getElectronicInvoiceAddressInfo() {
        return this.electronicInvoiceAddress != null
            ? `${this.electronicInvoiceAddress.ovt}, ${this.electronicInvoiceAddress.operatorBrokerId}, ${this.electronicInvoiceAddress.operator}`
            : null;
    }
}

class ProductionOrderItem {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.storageId = json.storageId;
            this.location = json.location ? new Location(json.location) : null;
            this.orderTotalPrice = json.orderTotalPrice;
            this.orderTotalPriceWithoutVat = json.orderTotalPriceWithoutVat;
            this.vatAmount = json.vatAmount;
            this.vatPercent = json.vatPercent;
            this.deliveryRecipient = json.deliveryRecipient;
            this.orderedFilters = [];
            if (json.orderedFilters != null && json.orderedFilters.length > 0) {
                for (let f of json.orderedFilters) {
                    this.orderedFilters.push(new FilterOrder(f));
                }
            }
        }
    }
}

export default ProductionOrder;
