import React, { useEffect } from 'react';
import ProductSelectorAutocomplete from '../products/productselectorautocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import NewProductRequiredView from '../products/newproductrequiredview';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const MyDialogTitle = withStyles(styles)((props) => {
    const { classes, onClose, title } = props;

    return (
        <DialogTitle id="simple-dialog-title">
            {title}
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
});

export default function FilterProductSelectDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [title, setTitle] = React.useState(!!props.title ? props.title : 'Valitse uusi suodatin');
    const [showNewProdEdit, setShowNewProdEdit] = React.useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setTitle(props.title);
    }, [props.show, props.title]);

    const handleClose = () => {
        props.selected(null);
    };

    const newProduct = () => {
        setShowNewProdEdit(true);
        setTitle(t('product.newProduct'));
    };

    const cancel = () => {
        setShowNewProdEdit(false);
        setTitle(t('product.selectNewProduct'));
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={props.show}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <MyDialogTitle onClose={handleClose} title={title} />
            <DialogContent>
                {!showNewProdEdit && (
                    <ProductSelectorAutocomplete
                        dismiss={handleClose}
                        selected={props.selected}
                        allowNewProd={props.allowNewProd}
                        newProduct={newProduct}
                    />
                )}
                {showNewProdEdit && (
                    <NewProductRequiredView
                        selected={props.selected}
                        cancel={cancel}
                        productRequestHint={props.productRequestHint}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}
