export const CalculateMaterialSpeed = (airvolume, filters, decimals = 3) => {
    let matSpeed = 0;
    if (airvolume && airvolume > 0 && filters && filters.length > 0) {
        let totalfsa = 0;
        for (let f of filters) {
            if (f.product.fabricSurfaceArea) {
                totalfsa += f.count * f.product.fabricSurfaceArea;
            }
        }
        if (totalfsa > 0) {
            matSpeed = Number(Math.round(airvolume / totalfsa + 'e' + decimals) + 'e-' + decimals);
        }
    }
    return matSpeed;
};
