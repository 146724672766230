import InvoiceDescription from './invoicedescription';
import { PortfolioDescription } from './portfolio';
import ElectronicInvoiceInfo from './electronicinvoiceinfo';
import Address from './address';
import InvoiceRow from './invoicerow';
import moment from 'moment';
import { round } from 'mathjs';
import i18next from 'i18next';

class Invoice extends InvoiceDescription {
    constructor(json) {
        super(json);
        this.storageId = json.storageId;
        this.accountsReceivable = json.accountsReceivable;
        this.portfolio = new PortfolioDescription(json.portfolio);
        this.orderNumber = json.orderNumber;
        this.deliveryDate = moment(json.deliveryDate);
        this.ourReference = json.ourReference;
        this.customerReference = json.customerReference;
        this.invoiceDnet = json.invoiceDnet;
        this.overdueInterest = json.overdueInterest;
        this.remarkTime = json.remarkTime;
        this.shipMode = json.shipMode; // 1=verkkolasku, 2=paperilasku, 4=sähköpostilasku
        this.factoring = json.factoring; // pois(null) jos normilasku, 1 jos rahoitusyhtiölle
        this.originalInvoiceNumber = json.originalInvoiceNumber;
        this.payerNumber = json.payerNumber;
        this.electronicInvoiceAddress = json.electronicInvoiceAddress
            ? new ElectronicInvoiceInfo(json.electronicInvoiceAddress)
            : null;
        this.invoiceLanguage = json.invoiceLanguage;
        this.deliveryAddress = new Address(json.deliveryAddress);
        this.billingAddress = new Address(json.billingAddress);
        this.payerVatId = json.payerVatId;
        this.payerBusinessId = json.payerBusinessId;
        this.payerPhone = json.payerPhone;
        this.payerMobile = json.payerMobile;
        this.payerTelefax = json.payerTelefax;
        this.payerEmail = json.payerEmail;
        this.vatIncluded = json.vatIncluded;
        this.vatExcluded = json.vatExcluded;
        this.vat = json.vat;
        this.emailInvoiceAddress = json.emailInvoiceAddress;
        this.salesInvoiceIntegration = json.salesInvoiceIntegration;
        this.referenceNumber = json.referenceNumber;
        this.rows = [];
        for (let r of json.rows) {
            this.rows.push(new InvoiceRow(r));
        }
    }

    static SalesInvoiceIntegrationTalenom = () => 'talenom';
    static SalesInvoiceIntegrationNetvisor = () => 'netvisor';

    static ShipModeEInv = () => 1;
    static ShipModePaper = () => 2;
    static ShipModeEMail = () => 4;

    shipModeText() {
        if (this.shipMode === 1) return i18next.t('invoice.eInvoice');
        else if (this.shipMode === 2) return i18next.t('invoice.paperInvoice');
        else if (this.shipMode === 4) return i18next.t('invoice.emailInvoice');
        throw new Error('undefined shipmode');
    }

    updateTotalPrices() {
        let totalVatExcl = 0.0;
        let totalVatIncl = 0.0;
        for (let r of this.rows) {
            if (r.rowType === 0) {
                r.priceVatExcluded = round(r.pricePerUnit * r.quantity, 2);
                r.priceVatIncluded = round(r.priceVatExcluded * (1.0 + r.vatRate / 100), 2);
                totalVatExcl += r.priceVatExcluded;
                totalVatIncl += r.priceVatIncluded;
            }
        }
        this.vatExcluded = round(totalVatExcl, 2);
        this.vatIncluded = round(totalVatIncl, 2);
        this.vat = round(this.vatIncluded - this.vatExcluded, 2);
    }

    referenceNumberFormatted() {
        if (this.referenceNumber && this.referenceNumber.length > 5) {
            const parts = [];
            var ind = this.referenceNumber.length - 5;
            do {
                parts.push(this.referenceNumber.slice(ind, ind + 5));
                ind -= 5;
            } while (ind >= 0);
            parts.push(this.referenceNumber.slice(0, 5 + ind));
            return parts.reverse().join(' ');
        }
        return this.referenceNumber;
    }

    static InvoiceStatusDraft() {
        return 1;
    }
    static InvoiceStatusSent() {
        return 2;
    }
}

export default Invoice;
